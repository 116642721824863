var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "templatePreviewDialog" },
    [
      _c(
        "el-dialog",
        {
          directives: [
            {
              name: "dialogDrag",
              rawName: "v-dialogDrag",
              value: true,
              expression: "true",
            },
          ],
          attrs: {
            visible: _vm.openDialog,
            title: "模板预览",
            "before-close": _vm.close,
            modal: false,
            width: _vm.dialogWidth,
          },
        },
        [
          _c("el-scrollbar", [
            _c("div", { staticClass: "content" }, [
              _c("div", { staticClass: "edit-header" }, [
                _vm._v(_vm._s(_vm.repReceiptInfo.formatHeadCaption)),
              ]),
              _c("div", { staticClass: "edit-textarea" }, [
                _c("pre", {
                  staticStyle: { "white-space": "pre-wrap" },
                  domProps: {
                    innerHTML: _vm._s(_vm.repReceiptInfo.formatContent),
                  },
                }),
              ]),
              _c("div", { staticClass: "edit-footer" }, [
                _vm._v(
                  " " + _vm._s(_vm.repReceiptInfo.formatBottomCaption) + " "
                ),
              ]),
            ]),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }