var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "right-decorate" },
    [
      _c("div", { staticClass: "title" }, [_vm._v("小票规格")]),
      _c(
        "div",
        { staticClass: "spec" },
        _vm._l(_vm.receiptSpecList, function (item) {
          return _c(
            "div",
            {
              key: item.length,
              staticClass: "spec-item pointer",
              class: {
                active: _vm.receiptFormat.formatMachineType === item.type,
              },
              on: {
                click: function ($event) {
                  _vm.receiptFormat.formatMachineType = item.type
                },
              },
            },
            [
              _c("div", [_vm._v(_vm._s(item.length))]),
              _c("div", { staticClass: "tag" }, [_vm._v(_vm._s(item.tag))]),
            ]
          )
        }),
        0
      ),
      _vm.setTemplateType == "cashier"
        ? [
            _c("div", { staticClass: "title" }, [_vm._v("文本设置")]),
            _c("div", { staticClass: "desc" }, [_vm._v("头部标题")]),
            _c("textarea", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.receiptFormat.formatHeadCaption,
                  expression: "receiptFormat.formatHeadCaption",
                },
              ],
              staticClass: "el-textarea__inner",
              staticStyle: { resize: "none", "min-height": "33px" },
              style:
                "font-size:" +
                _vm.receiptFormat.headCaptionFontSize +
                "px;font-weight:" +
                (_vm.receiptFormat.isHeadCaptionBold ? "700" : "400"),
              attrs: {
                autocomplete: "off",
                rows: "2",
                placeholder: "请输入头部标题",
              },
              domProps: { value: _vm.receiptFormat.formatHeadCaption },
              on: {
                input: function ($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(
                    _vm.receiptFormat,
                    "formatHeadCaption",
                    $event.target.value
                  )
                },
              },
            }),
            _c("div", { staticClass: "x-f" }, [
              _c(
                "div",
                { staticClass: "x-f desc" },
                [
                  _c("span", [_vm._v("字体大小:")]),
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择字体大小", size: "mini" },
                      model: {
                        value: _vm.receiptFormat.headCaptionFontSize,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.receiptFormat,
                            "headCaptionFontSize",
                            $$v
                          )
                        },
                        expression: "receiptFormat.headCaptionFontSize",
                      },
                    },
                    _vm._l(_vm.typefaceList, function (item) {
                      return _c("el-option", {
                        key: item,
                        attrs: { label: item, value: item },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "x-f desc" },
                [
                  _c("span", [_vm._v("字体加粗:")]),
                  _c("el-switch", {
                    attrs: { size: "mini" },
                    model: {
                      value: _vm.receiptFormat.isHeadCaptionBold,
                      callback: function ($$v) {
                        _vm.$set(_vm.receiptFormat, "isHeadCaptionBold", $$v)
                      },
                      expression: "receiptFormat.isHeadCaptionBold",
                    },
                  }),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "desc" }, [_vm._v("底部标语")]),
            _c("textarea", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.receiptFormat.formatBottomCaption,
                  expression: "receiptFormat.formatBottomCaption",
                },
              ],
              staticClass: "el-textarea__inner",
              staticStyle: { resize: "none", "min-height": "33px" },
              style:
                "font-size:" +
                _vm.receiptFormat.bottomCaptionFontSize +
                "px;font-weight:" +
                (_vm.receiptFormat.isBottomCaptionBold ? "700" : "400"),
              attrs: {
                autocomplete: "off",
                rows: "2",
                placeholder: "请输入底部标题",
              },
              domProps: { value: _vm.receiptFormat.formatBottomCaption },
              on: {
                input: function ($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(
                    _vm.receiptFormat,
                    "formatBottomCaption",
                    $event.target.value
                  )
                },
              },
            }),
            _c("div", { staticClass: "x-f" }, [
              _c(
                "div",
                { staticClass: "x-f desc" },
                [
                  _c("span", [_vm._v("字体大小:")]),
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择字体大小", size: "mini" },
                      model: {
                        value: _vm.receiptFormat.bottomCaptionFontSize,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.receiptFormat,
                            "bottomCaptionFontSize",
                            $$v
                          )
                        },
                        expression: "receiptFormat.bottomCaptionFontSize",
                      },
                    },
                    _vm._l(_vm.typefaceList, function (item) {
                      return _c("el-option", {
                        key: item,
                        attrs: { label: item, value: item },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "x-f desc" },
                [
                  _c("span", [_vm._v("字体加粗:")]),
                  _c("el-switch", {
                    attrs: { size: "mini" },
                    model: {
                      value: _vm.receiptFormat.isBottomCaptionBold,
                      callback: function ($$v) {
                        _vm.$set(_vm.receiptFormat, "isBottomCaptionBold", $$v)
                      },
                      expression: "receiptFormat.isBottomCaptionBold",
                    },
                  }),
                ],
                1
              ),
            ]),
            _vm._m(0),
            _c("div", { staticClass: "x-f upload" }, [
              _c(
                "div",
                { staticClass: "uploadLogo" },
                [
                  _c("SingleImageUpload", {
                    on: {
                      getImageUrl: function ($event) {
                        return _vm.handleImageUrl($event, "LOGO")
                      },
                      delImage: function ($event) {
                        return _vm.handleImageUrl(undefined, "LOGO")
                      },
                    },
                  }),
                  _c("div", { staticClass: "name" }, [_vm._v("LOGO")]),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "uploadQRcode" },
                [
                  _c("SingleImageUpload", {
                    on: {
                      getImageUrl: function ($event) {
                        return _vm.handleImageUrl($event, "QRcode")
                      },
                      delImage: function ($event) {
                        return _vm.handleImageUrl(undefined, "QRcode")
                      },
                    },
                  }),
                  _c("div", { staticClass: "name" }, [_vm._v("二维码")]),
                ],
                1
              ),
            ]),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "title" }, [
      _vm._v(" 上传图片 "),
      _c("span", { staticClass: "tag" }, [
        _vm._v("请使用黑白图片(LOGO 300*200,二维码 300*300)"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }