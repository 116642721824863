<template>
  <div class="wrap" v-loading="loading">
    <el-form
      ref="form"
      :model="commissionForm"
      :rules="rules"
      label-width="90px"
    >
      <!-- 顶部按钮 -->
      <div class="btnTOP">
        <el-button type="primary" size="mini" @click="submitForm"
          >保存
        </el-button>
        <el-button
          @click="$router.push('/shop/saleGuide/commission')"
          size="mini"
          >退出</el-button
        >
      </div>
      <cardTitleCom cardTitle="基本信息">
        <template slot="cardContent">
          <div class="tableContentTop x-x">
            <el-form-item label="方案编号" prop="billNo">
              <el-input
                v-model="commissionForm.billNo"
                class="inputWidthM"
                size="mini"
                maxlength="20"
              ></el-input>
            </el-form-item>
            <el-form-item label="方案名称" prop="billName">
              <el-input
                v-model="commissionForm.billName"
                class="inputWidthM"
                size="mini"
                maxlength="40"
              ></el-input>
            </el-form-item>
            <el-form-item label="生效日期" prop="begTime">
              <el-date-picker
                class="inputWidthM"
                v-model="commissionForm.begTime"
                type="datetime"
                placeholder="选择生效日期"
                value-format="yyyy-MM-dd HH:mm:ss"
                size="mini"
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item label="失效日期" prop="endTime">
              <el-date-picker
                class="inputWidthM"
                v-model="commissionForm.endTime"
                type="datetime"
                placeholder="选择失效日期"
                value-format="yyyy-MM-dd HH:mm:ss"
                size="mini"
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item label="备注">
              <el-input
                class="inputRemark"
                v-model="commissionForm.billRemark"
                type="textarea"
                placeholder="备注长度介于 1 和 80 字符之间"
                maxlength="80"
                :autosize="{ minRows: 1, maxRows: 5 }"
                size="mini"
              />
            </el-form-item>
          </div>
        </template>
      </cardTitleCom>
      <cardTitleCom cardTitle="提成类型">
        <template slot="cardContent">
          <div class="tableContent marL15">
            <!-- 按销售提成 -->
            <div>
              <el-checkbox
                v-model="commissionForm.isUseSaleCommission"
                class="marB10"
                >按销售提成</el-checkbox
              >
              <div v-if="commissionForm.isUseSaleCommission">
                <div class="marB10">
                  <el-form-item label="提成范围" prop="goodsRangeType">
                    <el-select
                      v-model="commissionForm.goodsRangeType"
                      placeholder="请选择提成范围"
                      filterable
                      clearable
                      @change="goodsRangeTypeChange"
                      class="marB10"
                    >
                      <el-option label="全部商品" :value="0"></el-option>
                      <el-option label="指定商品" :value="1"></el-option>
                      <el-option label="指定类别" :value="2"></el-option>
                      <el-option label="指定品牌" :value="3"></el-option>
                    </el-select>
                  </el-form-item>
                </div>
                <el-table
                  class="marB10"
                  v-if="commissionForm.goodsRangeType != undefined"
                  v-loading="loadingTable"
                  ref="multipleTable"
                  tooltip-effect="dark"
                  :data="commissionForm.billGoodsDetailItems"
                  border
                  max-height="500"
                >
                  <el-table-column
                    label="序号"
                    type="index"
                    width="80"
                    class-name="allowDrag"
                    align="center"
                  />
                  <el-table-column
                    align="center"
                    label="操作"
                    width="120"
                    v-if="!disabled && commissionForm.goodsRangeType != 0"
                  >
                    <template v-slot="scope">
                      <i
                        @click="row('push', scope.$index)"
                        class="el-icon-circle-plus operatePush"
                      ></i>
                      <i
                        @click="row('del', scope.$index)"
                        class="el-icon-remove operateDel"
                      ></i>
                    </template>
                  </el-table-column>
                  <el-table-column
                    label="类型"
                    align="center"
                    prop="lineType"
                    width="130"
                    v-if="commissionForm.goodsRangeType != 0"
                  >
                    <template slot-scope="scope">
                      <dict-tag
                        :options="
                          dict.type.vip_applicable_commodities_appoint_type
                        "
                        :value="scope.row.lineType"
                      />
                    </template>
                  </el-table-column>

                  <el-table-column
                    label="编码"
                    width="180"
                    align="center"
                    prop="lineNo"
                    v-if="commissionForm.goodsRangeType != 0"
                  >
                    <template slot-scope="scope">
                      <el-form-item
                        :prop="
                          'billGoodsDetailItems.' + scope.$index + '.lineNo'
                        "
                        :rules="rules[`billGoodsDetailItems.lineNo`]"
                      >
                        <el-input
                          v-model="scope.row.lineNo"
                          :disabled="disabled"
                          @keyup.enter.native="
                            keyup('lineNo', $event, scope.$index)
                          "
                        >
                          <i
                            slot="suffix"
                            class="el-icon-more more"
                            @click="selectLineType"
                          ></i>
                        </el-input>
                      </el-form-item>
                    </template>
                  </el-table-column>
                  <el-table-column
                    label="名称"
                    align="center"
                    prop="lineName"
                    width="130"
                    v-if="commissionForm.goodsRangeType != 0"
                  />

                  <el-table-column
                    label="提成方式"
                    width="350"
                    align="center"
                    prop="commissionCalcType"
                  >
                    <template slot-scope="scope">
                      <div class="x-x">
                        <el-form-item
                          :prop="
                            'billGoodsDetailItems.' +
                            scope.$index +
                            '.commissionCalcType'
                          "
                          :rules="
                            rules[`billGoodsDetailItems.commissionCalcType`]
                          "
                        >
                          <el-select
                            class="marR10"
                            style="width: 146px"
                            v-model="scope.row.commissionCalcType"
                            @change="commissionCalcTypeChange(scope.row)"
                            placeholder="请选择提成方式"
                            clearable
                          >
                            <el-option
                              label="销售提成率(%)"
                              :value="1"
                            ></el-option>
                            <el-option
                              label="按数量提成(个)"
                              :value="2"
                            ></el-option>
                          </el-select>
                        </el-form-item>
                        <el-form-item
                          v-if="scope.row.commissionCalcType == 1"
                          :prop="
                            'billGoodsDetailItems.' +
                            scope.$index +
                            '.commissionSaleRate'
                          "
                          :rules="
                            rules[`billGoodsDetailItems.commissionSaleRate`]
                          "
                        >
                          <el-input
                            class="inputWidth"
                            v-model="scope.row.commissionSaleRate"
                            clearable
                            :disabled="disabled"
                          >
                          </el-input>
                        </el-form-item>
                        <el-form-item
                          v-if="scope.row.commissionCalcType == 2"
                          :prop="
                            'billGoodsDetailItems.' +
                            scope.$index +
                            '.commissionSaleOneMoney'
                          "
                          :rules="
                            rules[`billGoodsDetailItems.commissionSaleOneMoney`]
                          "
                        >
                          <el-input
                            class="inputWidth"
                            v-model="scope.row.commissionSaleOneMoney"
                            clearable
                            :disabled="disabled"
                          >
                          </el-input>
                        </el-form-item>
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column align="center" prop="goodsNo" />
                </el-table>
              </div>
            </div>
            <!-- 按充值提成 -->
            <div>
              <el-checkbox
                v-model="commissionForm.isUseFillCommission"
                class="marB10"
                >按充值提成</el-checkbox
              >

              <el-table
                v-if="commissionForm.isUseFillCommission"
                class="marB10"
                v-loading="loadingTable"
                ref="multipleTable"
                tooltip-effect="dark"
                :data="commissionForm.billVipLevelDetailItems"
                border
                max-height="500"
              >
                <el-table-column
                  label="序号"
                  type="index"
                  width="80"
                  class-name="allowDrag"
                  align="center"
                />
                <el-table-column
                  align="center"
                  label="操作"
                  width="120"
                  v-if="!disabled"
                >
                  <template v-slot="scope">
                    <i
                      @click="levelRow('push', scope.$index)"
                      class="el-icon-circle-plus operatePush"
                    ></i>
                    <i
                      @click="levelRow('del', scope.$index)"
                      class="el-icon-remove operateDel"
                    ></i>
                  </template>
                </el-table-column>
                <el-table-column
                  label="会员卡级别编码"
                  width="180"
                  align="center"
                  prop="vipLevelNo"
                >
                  <template slot-scope="scope">
                    <el-form-item
                      :prop="
                        'billVipLevelDetailItems.' +
                        scope.$index +
                        '.vipLevelNo'
                      "
                      :rules="rules[`billVipLevelDetailItems.vipLevelNo`]"
                    >
                      <el-input
                        v-model="scope.row.vipLevelNo"
                        :disabled="disabled"
                        @keyup.enter.native="
                          keyupVipLevel('vipLevelNo', $event, scope.$index)
                        "
                      >
                        <i
                          slot="suffix"
                          class="el-icon-more more"
                          @click="selectVipLevel"
                        ></i>
                      </el-input>
                    </el-form-item>
                  </template>
                </el-table-column>
                <el-table-column
                  label="会员卡级别名称"
                  align="center"
                  prop="vipLevelName"
                  width="180"
                />

                <el-table-column
                  label="充值提成率(%)"
                  width="180"
                  align="center"
                  prop="commissionFillRate"
                >
                  <template slot-scope="scope">
                    <el-form-item
                      :prop="
                        'billVipLevelDetailItems.' +
                        scope.$index +
                        '.commissionFillRate'
                      "
                      :rules="
                        rules[`billVipLevelDetailItems.commissionFillRate`]
                      "
                    >
                      <el-input
                        v-model="scope.row.commissionFillRate"
                        :disabled="disabled"
                      >
                      </el-input>
                    </el-form-item>
                  </template>
                </el-table-column>
                <el-table-column align="center" />
              </el-table>
            </div>
          </div>
        </template>
      </cardTitleCom>
    </el-form>
    <!-- 选择商品信息框 -->
    <selectGoods
      :OpenGoods.sync="openGoods"
      :queryCode="queryCode"
      @getGoodS="getGoodS"
    ></selectGoods>
    <!-- 选择商品类别信息框 -->
    <selectCategory
      :OpenCategory.sync="openCategory"
      :queryCode="queryCodeCategory"
      @categoryList="categoryList"
    ></selectCategory>
    <!-- 选择商品品牌信息框 -->
    <selectBrand
      :OpenBrand.sync="openBrand"
      :queryCode="queryCodeBrand"
      @brandList="brandList"
    ></selectBrand>
    <!-- 选择会员级别信息框 -->
    <vipLeve
      :OpenVipLeve.sync="openVipLeve"
      :queryCode="queryCodeVipLeve"
      @vipLeveList="vipLeveList"
    ></vipLeve>
  </div>
</template>

<script>
import cardTitleCom from '@/views/components/cardTitleCom' //卡片标题
import selectGoods from '@/views/components/selectGoodsUp' //选择商品
import selectCategory from '@/views/components/selectCategory' //选择商品类别
import selectBrand from '@/views/components/selectBrand' //选择商品品牌
import vipLeve from '@/views/components/vipLeve' //选择会员级别
import { listGoods } from '@/api/goods/goods' //商品接口
import { listCategory } from '@/api/goods/category' //商品类别
import { listBrand } from '@/api/goods/brand' //品牌
import { listlevel } from '@/api/vip/base/level' //会员等级
import { getTimeDate } from '@/utils/newDate' //单据日期
import {
  commissionAddAPI,
  commissionDetailAPI,
  commissionUpdateAPI
} from '@/api/shop/saleGuide/commission' //导购员提成设置
export default {
  name: 'commissionDetail',
  dicts: ['vip_applicable_commodities_appoint_type'],
  components: {
    cardTitleCom,
    selectGoods,
    selectCategory,
    selectBrand,
    vipLeve
  },
  data () {
    return {
      //选择会员级别开关
      openVipLeve: false,
      //传送会员级别参数
      queryCodeVipLeve: undefined,

      //选择商品品牌开关
      openBrand: false,
      //传送商品品牌参数
      queryCodeBrand: undefined,

      //选择商品类别开关
      openCategory: false,
      //传送商品类别参数
      queryCodeCategory: undefined,

      //选择商品信息开关
      openGoods: false,
      //传送商品参数
      queryCode: undefined,
      //禁用
      disabled: false,

      //表格遮罩
      loadingTable: false,
      //整体遮罩
      loading: false,
      //旧的表单
      oldForm: {},
      //表单
      commissionForm: {
        billGoodsDetailItems: [{ commissionCalcType: 1 }], //销售提成表格
        billVipLevelDetailItems: [{}], //充值提成表格
        isUseSaleCommission: false, //销售提成
        isUseFillCommission: false //充值提成
      },
      //旧的表单
      oldForm: {},
      // 表单校验
      rules: {
        billNo: [
          {
            required: true,
            message: '方案编号不能为空',
            trigger: ['blur', 'change']
          },
          {
            pattern: /^[a-zA-Z0-9]+$/,
            message: '只能输入数字和英文字符',
            trigger: ['blur', 'change']
          }
        ],
        billName: [
          {
            required: true,
            message: '方案名称不能为空',
            trigger: ['blur', 'change']
          }
        ],
        begTime: [
          {
            required: true,
            message: '生效日期不能为空',
            trigger: ['blur', 'change']
          }
        ],
        endTime: [
          {
            required: true,
            message: '失效日期不能为空',
            trigger: ['blur', 'change']
          }
        ],
        goodsRangeType: [
          {
            required: true,
            message: '提成范围不能为空',
            trigger: ['blur', 'change']
          }
        ],
        'billGoodsDetailItems.lineNo': [
          {
            required: true,
            message: '编码不能为空',
            trigger: ['blur', 'change']
          }
        ],
        'billGoodsDetailItems.lineName': [
          {
            required: true,
            message: '名称不能为空',
            trigger: ['blur', 'change']
          }
        ],
        'billGoodsDetailItems.commissionCalcType': [
          {
            required: true,
            message: '提成方式不能为空',
            trigger: ['blur', 'change']
          }
        ],
        'billGoodsDetailItems.commissionSaleRate': [
          {
            required: true,
            message: '销售提成率不能为空',
            trigger: ['blur', 'change']
          },
          {
            pattern: /^\d+(\.\d+)?$/,
            message: '只能输入大于等于0的数字',
            trigger: ['blur', 'change']
          }
        ],
        'billGoodsDetailItems.commissionSaleOneMoney': [
          {
            required: true,
            message: '按数量提成不能为空',
            trigger: ['blur', 'change']
          },
          {
            pattern: /^\d+(\.\d+)?$/,
            message: '只能输入大于等于0的数字',
            trigger: ['blur', 'change']
          }
        ],
        'billVipLevelDetailItems.vipLevelNo': [
          {
            required: true,
            message: '会员级别编码不能为空',
            trigger: ['blur', 'change']
          }
        ],
        'billVipLevelDetailItems.commissionFillRate': [
          {
            required: true,
            message: '充值提成率不能为空',
            trigger: ['blur', 'change']
          },
          {
            pattern: /^\d+(\.\d+)?$/,
            message: '只能输入大于等于0的数字',
            trigger: ['blur', 'change']
          }
        ]
      }
    }
  },
  beforeRouteEnter (to, from, next) {
    next(async vm => {
      vm.loading = true
      // 重置数据
      vm.reset()
      // 访问组件实例 vm 中的数据和方法
      if (vm.$route.query.type === 'Update') {
        if (vm.$route.query.billId) {
          //获取方案详情
          const { data } = await commissionDetailAPI(vm.$route.query.billId)
          //方案赋值
          vm.commissionForm = data
          //原始单据赋值
          vm.oldForm = JSON.parse(JSON.stringify(vm.commissionForm))
        }
      } else {
        //初始化数据
        vm.commissionForm.billDate = getTimeDate()
        //判断是否有以前新增时保存的数据
        if (JSON.stringify(vm.oldForm) != '{}') {
          vm.commissionForm = vm.oldForm
        }
        vm.oldForm = JSON.parse(JSON.stringify(vm.commissionForm))
      }
      vm.loading = false
    })
  },
  async beforeRouteLeave (to, from, next) {
    // 在用户离开二级路由前弹出询问框
    if (this.commissionForm.billId) {
      this.oldForm = {}
    } else {
      this.oldForm = JSON.parse(JSON.stringify(this.commissionForm))
    }
    next()
  },
  methods: {
    //选择会员等级
    selectVipLevel () {
      this.openVipLeve = true
    },
    //选择商品方法
    selectLineType () {
      if (this.commissionForm.goodsRangeType == 1) {
        //商品
        this.openGoods = true
      } else if (this.commissionForm.goodsRangeType == 2) {
        //商品类别
        this.openCategory = true
      } else if (this.commissionForm.goodsRangeType == 3) {
        //商品品牌
        this.openBrand = true
      }
    },
    //选中的会员等级
    vipLeveList (value) {
      if (value.length == 0) return
      //去重
      value = value.filter(item => {
        return this.commissionForm.billVipLevelDetailItems.every(
          v => v.vipLevelId != item.vipLevelId
        )
      })
      if (value.length == 0) return
      value = value.map(v => {
        let obj = {
          vipLevelId: v.vipLevelId,
          vipLevelNo: v.vipLevelNo,
          vipLevelName: v.vipLevelName
        }
        return obj
      })
      this.commissionForm.billVipLevelDetailItems.push(...value)
      this.commissionForm.billVipLevelDetailItems =
        this.commissionForm.billVipLevelDetailItems.filter(
          item =>
            (!(JSON.stringify(item) === '{}') &&
              !(item.vipLevelId === undefined)) ||
            !(item.vipLevelId === undefined)
        )
    },
    //选中的商品品牌
    brandList (value) {
      if (value.length == 0) return
      //去重
      value = value.filter(item => {
        return this.commissionForm.billGoodsDetailItems.every(
          v => v.lineId != item.brandId
        )
      })
      if (value.length == 0) return
      value = value.map(v => {
        let obj = {
          lineType: 2,
          lineId: v.brandId,
          lineNo: v.brandNo,
          lineName: v.brandName,
          commissionCalcType: 1
        }
        return obj
      })
      this.commissionForm.billGoodsDetailItems.push(...value)
      this.commissionForm.billGoodsDetailItems =
        this.commissionForm.billGoodsDetailItems.filter(
          item =>
            (!(JSON.stringify(item) === '{}') &&
              !(item.lineId === undefined)) ||
            !(item.lineId === undefined)
        )
    },
    //选中的商品类别
    categoryList (value) {
      if (value.length == 0) return
      //去重
      value = value.filter(item => {
        return this.commissionForm.billGoodsDetailItems.every(
          v => v.lineId != item.categoryId
        )
      })
      if (value.length == 0) return
      value = value.map(v => {
        let obj = {
          lineType: 3,
          lineId: v.categoryId,
          lineNo: v.categoryNo,
          lineName: v.categoryName,
          commissionCalcType: 1
        }
        return obj
      })
      this.commissionForm.billGoodsDetailItems.push(...value)
      this.commissionForm.billGoodsDetailItems =
        this.commissionForm.billGoodsDetailItems.filter(
          item =>
            (!(JSON.stringify(item) === '{}') &&
              !(item.lineId === undefined)) ||
            !(item.lineId === undefined)
        )
    },
    //选中的商品
    getGoodS (value) {
      if (value.length == 0) return
      //去重
      value = value.filter(item => {
        return this.commissionForm.billGoodsDetailItems.every(
          v => v.lineId != item.goodsId
        )
      })
      if (value.length == 0) return
      value = value.map(v => {
        let obj = {
          lineType: 1,
          lineId: v.goodsId,
          lineNo: v.goodsNo,
          lineName: v.goodsName,
          commissionCalcType: 1
        }
        return obj
      })

      this.commissionForm.billGoodsDetailItems.push(...value)
      this.commissionForm.billGoodsDetailItems =
        this.commissionForm.billGoodsDetailItems.filter(
          item =>
            (!(JSON.stringify(item) === '{}') &&
              !(item.lineId === undefined)) ||
            !(item.lineId === undefined)
        )
    },
    //表格单元格回车事件  销售提成
    async keyup (name, event, index) {
      //判断是否输入编码或条码
      if (this.commissionForm.billGoodsDetailItems[`${index}`][`${name}`]) {
        let response = undefined
        //判断是否重复的名称
        let idName = undefined
        //传输对象
        let obj = undefined
        if (this.commissionForm.goodsRangeType == 1) {
          //商品
          response = await listGoods({
            goodsNo:
              this.commissionForm.billGoodsDetailItems[`${index}`][`${name}`]
          })
          idName = 'goodsId'
          //原始赋值
          obj = {
            lineType: 1,
            lineId: response.rows[0].goodsId,
            lineNo: response.rows[0].goodsNo,
            lineName: response.rows[0].goodsName,
            commissionCalcType: 1
          }
        } else if (this.commissionForm.goodsRangeType == 2) {
          //商品类别
          response = await listCategory({
            categoryNo:
              this.commissionForm.billGoodsDetailItems[`${index}`][`${name}`]
          })
          idName = 'categoryId'
          //原始赋值
          obj = {
            lineType: 3,
            lineId: response.rows[0].categoryId,
            vipLevelNo: response.rows[0].categoryNo,
            vipLevelName: response.rows[0].categoryName,
            commissionCalcType: 1
          }
        } else if (this.commissionForm.goodsRangeType == 3) {
          //商品品牌
          response = await listBrand({
            brandNo:
              this.commissionForm.billGoodsDetailItems[`${index}`][`${name}`]
          })
          idName = 'brandId'
          //原始赋值
          obj = {
            lineType: 2,
            lineId: response.rows[0].brandId,
            vipLevelNo: response.rows[0].brandNo,
            vipLevelName: response.rows[0].brandName,
            commissionCalcType: 1
          }
        }
        // 判断是否精准匹配 匹配直接赋值 不匹配弹出商品框
        if (response.total === 1) {
          event.target.blur()
          //去重
          let isRepeat = this.commissionForm.billGoodsDetailItems.every(
            item => item.lineId != response.rows[0][idName]
          )
          if (!isRepeat) {
            this.$message.error('当前表格已有此数据!')
            return
          }
          //使用vue的$set方法更新数组数据,使视图可编辑
          this.$set(this.commissionForm.billGoodsDetailItems, index, obj)
        } else {
          if (this.commissionForm.goodsRangeType == 1) {
            //商品
            this.queryCode =
              this.commissionForm.billGoodsDetailItems[`${index}`][`${name}`]
            this.openGoods = true
          } else if (this.commissionForm.goodsRangeType == 2) {
            //商品类别
            this.queryCodeCategory =
              this.commissionForm.billGoodsDetailItems[`${index}`][`${name}`]
            this.openCategory = true
          } else if (this.commissionForm.goodsRangeType == 3) {
            //商品品牌
            this.queryCodeBrand =
              this.commissionForm.billGoodsDetailItems[`${index}`][`${name}`]
            this.openBrand = true
          }
        }
      } else {
        //没输入直接弹出商品信息框
        event.target.blur()
        if (this.commissionForm.goodsRangeType == 1) {
          //商品
          this.openGoods = true
        } else if (this.commissionForm.goodsRangeType == 2) {
          //商品类别
          this.openCategory = true
        } else if (this.commissionForm.goodsRangeType == 3) {
          //商品品牌
          this.openBrand = true
        }
      }
    },
    //表格单元格回车事件  充值
    keyupVipLevel (name, event, index) {
      //判断是否输入编码或条码
      if (this.commissionForm.billVipLevelDetailItems[`${index}`][`${name}`]) {
        listlevel({
          [name]:
            this.commissionForm.billVipLevelDetailItems[`${index}`][`${name}`]
        }).then(response => {
          // 判断是否精准匹配 匹配直接赋值 不匹配弹出商品框
          if (response.total === 1) {
            event.target.blur()
            //去重
            let isRepeat = this.commissionForm.billVipLevelDetailItems.every(
              item => item.vipLevelId != response.rows[0].vipLevelId
            )
            if (!isRepeat) {
              this.$message.error('当前表格已有此等级!')
              return
            }
            //原始赋值
            let obj = {
              vipLevelId: response.rows[0].vipLevelId,
              vipLevelNo: response.rows[0].vipLevelNo,
              vipLevelName: response.rows[0].vipLevelName
            }
            //使用vue的$set方法更新数组数据,使视图可编辑
            this.$set(this.commissionForm.billVipLevelDetailItems, index, obj)
          } else {
            this.queryCodeVipLeve =
              this.commissionForm.billVipLevelDetailItems[`${index}`][`${name}`]
            this.openVipLeve = true
          }
        })
      } else {
        //没输入直接弹出商品信息框
        event.target.blur()
        this.openVipLeve = true
      }
    },
    //商品类型切换
    goodsRangeTypeChange () {
      this.commissionForm.billGoodsDetailItems = [{ commissionCalcType: 1 }]
      //全部
      if (this.commissionForm.goodsRangeType == 0) {
        this.commissionForm.billGoodsDetailItems[0].lineId = 0
        this.commissionForm.billGoodsDetailItems[0].lineType = 0
      }
    },
    //提成方式切换
    commissionCalcTypeChange (row) {
      this.$set(row, 'commissionSaleOneMoney', undefined)
      this.$set(row, 'commissionSaleRate', undefined)
    },
    //表格增加/减少一行方法
    levelRow (name, index) {
      if (name === 'push') {
        this.commissionForm.billVipLevelDetailItems.splice(index + 1, 0, {})
      } else {
        if (this.commissionForm.billVipLevelDetailItems.length <= 1) return
        this.commissionForm.billVipLevelDetailItems.splice(index, 1)
      }
    },
    //表格增加/减少一行方法
    row (name, index) {
      if (name === 'push') {
        this.commissionForm.billGoodsDetailItems.splice(index + 1, 0, {
          commissionCalcType: 1
        })
      } else {
        if (this.commissionForm.billGoodsDetailItems.length <= 1) return
        this.commissionForm.billGoodsDetailItems.splice(index, 1)
      }
    },
    // 表单重置
    reset () {
      this.commissionForm = {
        billGoodsDetailItems: [{ commissionCalcType: 1 }], //销售提成表格
        billVipLevelDetailItems: [{}], //充值提成表格
        isUseSaleCommission: false, //销售提成
        isUseFillCommission: false //充值提成
      }
      this.resetForm('form')
    },
    /** 提交按钮 */
    async submitForm () {
      this.$refs['form'].validate(async valid => {
        if (valid) {
          //判断是否勾选类型某一项
          if (
            !this.commissionForm.isUseSaleCommission &&
            !this.commissionForm.isUseFillCommission
          ) {
            this.$message.error('提成类型不能为空')
            return
          }
          //判断是生效时间是否小于失效时间
          const begTime = new Date(this.commissionForm.begTime)
          const endTime = new Date(this.commissionForm.endTime)
          if (begTime > endTime) {
            this.$message.error('生效时间不能小于失效时间')
            return
          }
          if (this.commissionForm.billId) {
            await commissionUpdateAPI(this.commissionForm)
            this.$modal.msgSuccess('修改单据成功')
          } else {
            await commissionAddAPI(this.commissionForm)
            this.$modal.msgSuccess('新增方案成功')
          }
          this.$store.state.tagsView.visitedViews.splice(
            this.$store.state.tagsView.visitedViews.findIndex(
              item => item.path === this.$route.path
            ),
            1
          )
          this.$router.push('/shop/saleGuide/commission')
          this.$destroy()
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.wrap {
  padding: 10px 10px 0 10px;
  background-color: #eaeaea;
  height: calc(100vh - 84px);
  // 顶部按钮
  .btnTOP {
    text-align: right;
    padding: 0 10px 6px 0;
  }
  //基本信息内容
  .tableContentTop {
    padding: 6px 10px;
  }
  //表格
  .tableContent {
    padding: 6px 10px;
    ::v-deep .el-form-item__content {
      margin-left: 0 !important;
    }
    ::v-deep .el-form-item__error {
      position: static;
    }
  }
}
//表单原生样式
::v-deep .el-form-item {
  margin: 0;
  padding: 0;
}
</style>
