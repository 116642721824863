<template>
  <!-- 收银小票模板 -->
  <div class="receiptFormat">
    <div class="RF-header x-bc">
      <div class="headerTitle x-f">
        <div class="backBtn pointer el-icon-arrow-left" @click="handleBack"></div>
        <div class="title">{{ title }}</div>
      </div>
      <div class="btn">
        <el-popover
          placement="top-end"
          width="200"
          trigger="hover"
          content="预览效果弹窗可任意拖动"
        >
          <el-button slot="reference" @click="handlePreviewDialog">预览效果</el-button>
        </el-popover>
        <el-button type="primary" @click="submitBtn" style="margin-left: 5px"
          >保存模板</el-button
        >
      </div>
    </div>
    <div class="RF-main x-x" v-loading="loading">
      <!-- 左边小票模板区域 -->
      <LeftTemplate
        :setTemplateType="setTemplateType"
        :receiptFormat="receiptFormat"
        class="LeftTemplate"
        @addParameter="addParameter"
        :receiptFormatList="receiptFormatList"
        @handleTemplate="handleTemplate"
        @addTemplate="addTemplate"
      />
      <!-- 中间预览编辑小票区域 -->
      <PreviewEait
        v-if="!receiptFormat.formatType"
        class="PreviewEait"
        :receiptFormat="receiptFormat"
      />
      <PreviewEaitHighLevel
        v-else
        class="PreviewEaitHighLevel"
        ref="PreviewEaitHighLevel"
        v-model="list"
        :formatMachineType.sync="receiptFormat.formatMachineType"
        :activeIndex.sync="activeIndex"
        @setReceiptFormat="receiptFormat = { ...receiptFormat, ...$event }"
      />
      <!-- 右边装饰小票区域 -->
      <RightDecorate
        v-if="receiptFormat.formatType === 0"
        class="RightDecorate"
        :receiptFormat="receiptFormat"
        :setTemplateType="setTemplateType"
      />
      <RightDecorateHighLevel
        v-else
        class="RightDecorateHighLevel"
        ref="RightDecorateHighLevel"
        :form="list[activeIndex] || { style: {} }"
        :receiptFormat="receiptFormat"
        :setTemplateType="setTemplateType"
        @delParameter="delParameter()"
      />
      <!-- 模板预览弹窗 -->
      <TemplatePreviewDialog
        :receiptFormat="receiptFormat"
        :openDialog.sync="openPreviewDialog"
        :setTemplateType="setTemplateType"
      />
    </div>
  </div>
</template>

<script>
import LeftTemplate from "@/views/shop/setup/receiptFormat/components/left-template.vue"; //左边小票模板区域
import PreviewEait from "@/views/shop/setup/receiptFormat/components/preview-edit.vue"; //中间预览编辑小票区域
import RightDecorate from "@/views/shop/setup/receiptFormat/components/right-decorate.vue"; //右边装饰小票区域
import TemplatePreviewDialog from "@/views/shop/setup/receiptFormat/components/templatePreviewDialog"; //模板预览弹窗

// 高级设置
import PreviewEaitHighLevel from "@/views/shop/setup/receiptFormat/components/preview-edit-highLevel.vue"; //中间预览编辑小票区域
import RightDecorateHighLevel from "@/views/shop/setup/receiptFormat/components/right-decorate-highLevel.vue"; //右边装饰小票区域

import {
  saveCashierReceiptFormat, //新增小票模板
  saveKitchenReceiptFormat, //新增小票模板
  saveOrderMakeReceiptFormat, //新增小票模板
  saveDeliveryReceiptFormat, //新增小票模板
  getCashierReceiptFormat, //获取小票模板列表
  getKitchenReceiptFormat, //获取小票模板列表
  getOrderMakeReceiptFormat, //获取小票模板列表
  getDeliveryReceiptFormat, //获取小票模板列表
  updateCashierReceiptFormat, //修改小票模板
  updateKitchenReceiptFormat, //修改小票模板
  updateOrderMakeReceiptFormat, //修改小票模板
  updateDeliveryReceiptFormat, //修改小票模板
  delCashierReceiptFormat, //删除小票模板
  delKitchenReceiptFormat, //删除小票模板
  delOrderMakeReceiptFormat, //删除小票模板
  delDeliveryReceiptFormat, //删除小票模板
  isStopCashierReceiptFormat, //启用小票模板
  isStopKitchenReceiptFormat, //启用小票模板
  isStopOrderMakeReceiptFormat, //启用小票模板
  isStopDeliveryReceiptFormat, //启用小票模板
  copyCashierReceiptFormat, //复制小票模板
  copyKitchenReceiptFormat, //复制小票模板
  copyOrderMakeReceiptFormat, //复制小票模板
  copyDeliveryReceiptFormat, //复制小票模板
} from "@/api/shop/setup/receiptFormat.js"; //接口api
export default {
  name: "ReceiptFormat",
  components: {
    LeftTemplate,
    PreviewEait,
    RightDecorate,
    TemplatePreviewDialog,
    PreviewEaitHighLevel,
    RightDecorateHighLevel,
  },
  // 渲染该组件前调用这个路由钩子
  beforeRouteEnter(to, from, next) {
    //因为当守卫执行前,组件实例还没创建
    //一定要调用next()方法才能跳转到后续页面,否则不会解析导致无法进入页面
    //通过'vm'才能访问组件this实例
    next((vm) => {
      vm.setTemplateType = to.query.type;
      vm.receiptFormat.formatType = Number(to.query.formatType);
      if (to.query.type == "cashier") vm.title = "收银小票模板设置";
      if (to.query.type == "kitchenPrint") vm.title = "厨打小票模板设置";
      if (to.query.type == "delivery") vm.title = "配送小票模板设置";
      if (to.query.type == "orderMake") vm.title = "订单制作小票模板设置";
      vm.getReceiptFormatList(); //获取小票模板列表
    });
  },
  data() {
    return {
      //高级设置
      list: [], //设置字段列表
      activeIndex: 0, //当前字段列表下标
      loading: false, //遮罩层
      //设置模板类型
      setTemplateType: "",
      //标题
      title: "",
      //小票模板信息
      receiptFormat: {
        formatType: 0, //小票类型(0普通类型1高级类型)
        formatId: undefined, //小票模板ID
        formatHeadCaption: "", //头部标题
        isHeadCaptionBold: true, //头部标题是否加粗(默认加粗)
        headCaptionFontSize: 12, //头部标题字体大小(默认12)
        formatContent: "", //小票格式内容
        formatBottomCaption: "", //底部标语
        isBottomCaptionBold: true, //底部标语是否加粗(默认加粗)
        bottomCaptionFontSize: 12, //底部标语字体大小(默认12)
        formatMachineType: 58, //小票规格类型
        formatLogoUrl: undefined, //Logo图片
        formatQrCodeUrl: undefined, //二维码图片
        isCurrentUseFormat: false, //是否当前使用模板
        isSysDefaultFormat: undefined, //是否系统默认模板
      },
      //小票模板列表
      receiptFormatList: [],
      openPreviewDialog: false,
    };
  },
  methods: {
    //新增模板
    addTemplate() {
      //小票模板信息
      this.receiptFormat = {
        formatType: this.receiptFormat.formatType,
        formatId: undefined, //小票模板ID
        formatHeadCaption: "", //头部标题
        isHeadCaptionBold: true, //头部标题是否加粗(默认加粗)
        headCaptionFontSize: 12, //头部标题字体大小(默认12)
        formatContent: "", //小票格式内容
        formatBottomCaption: "", //底部标语
        isBottomCaptionBold: true, //底部标语是否加粗(默认加粗)
        bottomCaptionFontSize: 12, //底部标语字体大小(默认12)
        formatMachineType: 58, //小票规格类型
        formatLogoUrl: undefined, //Logo图片
        formatQrCodeUrl: undefined, //二维码图片
        isCurrentUseFormat: false, //是否当前使用模板
        isSysDefaultFormat: undefined, //是否系统默认模板
      };
      this.list = [];
      if (this.setTemplateType == "orderMake") {
        this.receiptFormat.formatMachineType = 80;
      }
    },
    //删除参数
    delParameter() {
      this.list.splice(this.activeIndex, 1);
      this.activeIndex = -1;
    },
    //退出当前页面
    handleBack() {
      this.$router.go(-1);
    },
    //添加参数
    addParameter(parameter) {
      if (this.receiptFormat.formatType === 1) {
        this.list.push(parameter);
      } else {
        this.receiptFormat.formatContent += parameter.text;
      }
    },
    //操作预览弹窗
    handlePreviewDialog() {
      if (this.receiptFormat.formatType === 1) {
        this.$refs.PreviewEaitHighLevel?.getCurTep();
      }
      this.openPreviewDialog = true;
    },
    //操作小票模板
    async handleTemplate({ type, formatId }) {
      if (type === "use") {
        //使用此模板
        try {
          let request;
          switch (this.setTemplateType) {
            case "cashier":
              request = isStopCashierReceiptFormat;
              break;
            case "kitchenPrint":
              request = isStopKitchenReceiptFormat;
              break;
            case "orderMake":
              request = isStopOrderMakeReceiptFormat;
              break;
            case "delivery":
              request = isStopDeliveryReceiptFormat;
              break;
            default:
              break;
          }
          await request(formatId);
          this.$message.success("收银小票模板使用成功");
          this.getReceiptFormatList();
        } catch (err) {
          this.$message.error(err.msg || err);
        }
      } else if (type === "edit") {
        //编辑模板
        let receiptFormatItem = this.receiptFormatList.filter(
          (item) => item.formatId === formatId
        );
        this.receiptFormat = { ...receiptFormatItem[0] };
        this.list = receiptFormatItem[0].list || [];
        console.log("编辑模板", this.receiptFormat);
      } else if (type === "copy") {
        //复制模板
        try {
          let request;
          switch (this.setTemplateType) {
            case "cashier":
              request = copyCashierReceiptFormat;
              break;
            case "kitchenPrint":
              request = copyKitchenReceiptFormat;
              break;
            case "orderMake":
              request = copyOrderMakeReceiptFormat;
              break;
            case "delivery":
              request = copyDeliveryReceiptFormat;
              break;
            default:
              break;
          }
          await request(formatId);
          this.$message.success("复制成功");
          this.getReceiptFormatList();
        } catch (err) {
          this.$message.error(err.msg || err);
        }
      } else if (type === "del") {
        //删除模板
        this.$confirm("确定删除该小票模板?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(async () => {
            try {
              let request;
              switch (this.setTemplateType) {
                case "cashier":
                  request = delCashierReceiptFormat;
                  break;
                case "kitchenPrint":
                  request = delKitchenReceiptFormat;
                  break;
                case "orderMake":
                  request = delOrderMakeReceiptFormat;
                  break;
                case "delivery":
                  request = delDeliveryReceiptFormat;
                  break;
                default:
                  break;
              }
              await request([formatId]);
              this.$message.success("删除成功");
              this.getReceiptFormatList();
            } catch (err) {
              this.$message.error(err.msg || err);
            }
          })
          .catch(() => {});
      }
    },
    //获取小票模板
    async getReceiptFormatList() {
      this.loading = true;
      let request;
      switch (this.setTemplateType) {
        case "cashier":
          request = getCashierReceiptFormat;
          break;
        case "kitchenPrint":
          request = getKitchenReceiptFormat;
          break;
        case "orderMake":
          request = getOrderMakeReceiptFormat;
          break;
        case "delivery":
          request = getDeliveryReceiptFormat;
          this.receiptFormat.formatMachineType = 80;
          break;
        default:
          break;
      }
      try {
        const res = await request();
        //小票内容区域json格式转为字符串
        this.receiptFormatList = res.rows
          .filter((item) =>
            this.receiptFormat.formatType === 1
              ? item.formatType === 1
              : item.formatType !== 1
          )
          .map((item) => {
            item.list = JSON.parse(item.formatContent).list;
            item.formatContent = JSON.parse(item.formatContent).ticketPrinterTemplate;
            return item;
          });
        this.loading = false;
      } catch (err) {
        this.$message.error(err);
        this.loading = false;
      }
    },
    //提交按钮
    async submitBtn() {
      if (this.receiptFormat.formatType === 1) {
        this.$refs.PreviewEaitHighLevel?.getCurTep();
      }
      //把小票内容转json格式
      let jsontxt = {
        ticketPrinterTemplate: this.receiptFormat.formatContent,
        list: this.list,
      };
      //提交的小票模板内容
      let receiptFormat = {
        formatType: this.receiptFormat.formatType || 0, //小票类型( 0 普通类型 1 高级类型 )
        formatId: this.receiptFormat.formatId,
        formatHeadCaption: this.receiptFormat.formatHeadCaption,
        isHeadCaptionBold: this.receiptFormat.isHeadCaptionBold, //头部标题是否加粗(默认加粗)
        headCaptionFontSize: this.receiptFormat.headCaptionFontSize, //头部标题字体大小(默认12)
        formatContent: JSON.stringify(jsontxt),
        formatBottomCaption: this.receiptFormat.formatBottomCaption, //底部标语
        isBottomCaptionBold: this.receiptFormat.isBottomCaptionBold, //底部标语是否加粗(默认加粗)
        bottomCaptionFontSize: this.receiptFormat.bottomCaptionFontSize, //底部标语字体大小(默认12)
        formatMachineType: this.receiptFormat.formatMachineType, //小票规格类型
        formatLogoUrl: this.receiptFormat.formatLogoUrl, //Logo图片
        formatQrCodeUrl: this.receiptFormat.formatQrCodeUrl, //二维码图片
        isCurrentUseFormat: this.receiptFormat.isCurrentUseFormat, //是否当前使用模板
        isSysDefaultFormat: this.receiptFormat.isSysDefaultFormat, //是否系统默认模板
      };
      if (this.receiptFormat.formatId === undefined) {
        try {
          let request;
          switch (this.setTemplateType) {
            case "cashier":
              request = saveCashierReceiptFormat;
              break;
            case "kitchenPrint":
              request = saveKitchenReceiptFormat;
              break;
            case "orderMake":
              request = saveOrderMakeReceiptFormat;
              break;
            case "delivery":
              request = saveDeliveryReceiptFormat;
              break;
            default:
              break;
          }
          const { data } = await request(receiptFormat);
          this.receiptFormat.formatId = data?.formatId || undefined;
          this.$message.success("新增成功");
          this.getReceiptFormatList();
        } catch (err) {
          this.$message.error(err);
        }
      } else {
        try {
          let request;
          switch (this.setTemplateType) {
            case "cashier":
              request = updateCashierReceiptFormat;
              break;
            case "kitchenPrint":
              request = updateKitchenReceiptFormat;
              break;
            case "orderMake":
              request = updateOrderMakeReceiptFormat;
              break;
            case "delivery":
              request = updateDeliveryReceiptFormat;
              break;
            default:
              break;
          }
          await request(receiptFormat);
          this.$message.success("修改成功");
          this.getReceiptFormatList();
        } catch (err) {
          this.$message.error(err);
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.receiptFormat {
  height: 100%;
  .RF-header {
    padding: 0 24px;
    border-bottom: 1px solid #eee;
    height: 50px;
    .backBtn {
      padding: 0 10px;
    }
  }
  .RF-main {
    height: calc(100% - 50px);
    .LeftTemplate {
      width: 415px;
    }
    .RightDecorate,
    .RightDecorateHighLevel {
      width: 468px;
    }
    .PreviewEait,
    .PreviewEaitHighLevel {
      flex-grow: 1;
      background-color: #eaeaea;
    }
  }
}
</style>
