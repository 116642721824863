var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "preview-edit" },
    [
      _c("el-scrollbar", [
        _c(
          "div",
          {
            staticClass: "content",
            class: {
              w58: _vm.receiptFormat.formatMachineType == "58",
              w80: _vm.receiptFormat.formatMachineType == "80",
              w110: _vm.receiptFormat.formatMachineType == "110",
            },
          },
          [
            _c("textarea", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.receiptFormat.formatHeadCaption,
                  expression: "receiptFormat.formatHeadCaption",
                },
              ],
              staticClass: "el-textarea__inner",
              staticStyle: { resize: "none" },
              style:
                "font-size:" +
                _vm.receiptFormat.headCaptionFontSize +
                "px;font-weight:" +
                (_vm.receiptFormat.isHeadCaptionBold ? "700" : "400"),
              attrs: {
                autocomplete: "off",
                rows: "2",
                placeholder: "请输入头部标题",
              },
              domProps: { value: _vm.receiptFormat.formatHeadCaption },
              on: {
                input: function ($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(
                    _vm.receiptFormat,
                    "formatHeadCaption",
                    $event.target.value
                  )
                },
              },
            }),
            _c(
              "div",
              { staticClass: "edit-textarea" },
              [
                _c("el-input", {
                  attrs: {
                    type: "textarea",
                    autosize: "",
                    placeholder: "请输入内容",
                  },
                  model: {
                    value: _vm.receiptFormat.formatContent,
                    callback: function ($$v) {
                      _vm.$set(_vm.receiptFormat, "formatContent", $$v)
                    },
                    expression: "receiptFormat.formatContent",
                  },
                }),
              ],
              1
            ),
            _c("textarea", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.receiptFormat.formatBottomCaption,
                  expression: "receiptFormat.formatBottomCaption",
                },
              ],
              staticClass: "el-textarea__inner",
              staticStyle: { resize: "none" },
              style:
                "font-size:" +
                _vm.receiptFormat.bottomCaptionFontSize +
                "px;font-weight:" +
                (_vm.receiptFormat.isBottomCaptionBold ? "700" : "400"),
              attrs: {
                autocomplete: "off",
                rows: "2",
                placeholder: "请输入底部标题",
              },
              domProps: { value: _vm.receiptFormat.formatBottomCaption },
              on: {
                input: function ($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(
                    _vm.receiptFormat,
                    "formatBottomCaption",
                    $event.target.value
                  )
                },
              },
            }),
          ]
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }