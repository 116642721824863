<template>
  <div class="wrap" id="wrap">
    <cardTitleCom cardTitle="查询" id="search-card">
      <template slot="cardContent">
        <div class="navSearch">
          <!-- 高级搜索按钮 -->
          <seniorSearch
            v-model="queryParams"
            :getList="getList"
            :dropDownList="dropDownList"
            :isAdvancedSearch="false"
            :isBillDate="false"
            :isSearchInput="true"
            isSearchInputTitle="输入方案编号、名称"
          />
        </div>
      </template>
    </cardTitleCom>
    <cardTitleCom cardTitle="方案信息" class="orderInfo">
      <template slot="cardContent">
        <div class="tableContent">
          <!-- 操作按钮 -->
          <operatingButton
            :getList="getList"
            :multiple="multiple"
            @handleAdd="handleAdd"
            @handleDelete="handleDelete('删除')"
          />
          <el-table
            v-loading="loadingTable"
            ref="multipleTable"
            @row-click="handleRowClick"
            tooltip-effect="dark"
            :data="tableData"
            @selection-change="handleSelectionChange"
            border
            :max-height="tableHeight"
            :height="tableHeight"
          >
            <el-table-column type="selection" width="50" align="center" />
            <el-table-column
              label="序号"
              align="center"
              prop="userId"
              width="80"
              type="index"
            />
            <el-table-column
              show-overflow-tooltip
              label="方案编号"
              align="center"
              prop="billNo"
            >
              <template slot-scope="scope">
                <el-link
                  type="primary"
                  @click="handleDetail(scope.row)"
                  target="_blank"
                >
                  {{ scope.row.billNo }}
                </el-link>
              </template>
            </el-table-column>
            <el-table-column label="方案名称" align="center" prop="billName" />
            <el-table-column label="生效日期" align="center" prop="begTime" />
            <el-table-column label="失效日期" align="center" prop="endTime" />
            <el-table-column label="状态" align="center" prop="billStatus">
              <template slot-scope="scope">
                <span>{{
                  scope.row.billStatus == '0' ? '正常' : '已删除'
                }}</span>
              </template>
            </el-table-column>
          </el-table>
          <bottomPage
            v-model="queryParams"
            :getList="getList"
            :idsCom="ids"
            :totalCom="total"
            @selectAllCom="selectAll"
          />
        </div>
      </template>
    </cardTitleCom>
  </div>
</template>

<script>
import cardTitleCom from '@/views/components/cardTitleCom' //卡片标题
import seniorSearch from '@/views/components/seniorSearch' //高级搜索
import bottomPage from '@/views/components/bottomPage' //底部分页
import {
  commissionListAPI,
  commissionUpdateStatusAPI
} from '@/api/shop/saleGuide/commission' //导购员提成设置
import operatingButton from '@/views/components/operatingButton' //操作按钮
export default {
  name: 'commission',
  dicts: ['bill_status'],
  components: { bottomPage, cardTitleCom, seniorSearch, operatingButton },
  data () {
    return {
      //搜索框搜索的条件
      dropDownList: [
        { parameter: 'query', name: '全部' },
        { parameter: 'billNos', name: '方案编号' },
        { parameter: 'billNames', name: '方案名称' }
      ],
      storeData: [], //仓库数据
      loading: false,
      loadingTable: false,
      multiple: true, // 非单个禁用
      showHigh: false, //是否显示高级搜索
      billStatusThreeId: [], //选中数组的已删除的状态3
      ids: [], // 选中数组
      total: 0, // 总条数
      tableData: [], //表格数据
      //查询
      queryParams: {
        pageNum: 1,
        pageSize: 15
      },
      vivwH: 0, //页面高度
      searchH: 0, //搜索框高度
      paginationH: 40
    }
  },

  async created () {
    //初始化表格数据
    await this.getList()
    // this.getInitializeDataAll()
  },
  computed: {
    tableHeight () {
      return this.vivwH - this.paginationH - this.searchH - 140
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.vivwH = document.getElementById('wrap').clientHeight
      this.searchH = document.getElementById('search-card').clientHeight
    })
    window.addEventListener('resize', this.handleResize)
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.handleResize)
  },
  methods: {
    handleResize () {
      this.vivwH = document.getElementById('wrap').clientHeight
      this.searchH = document.getElementById('search-card').clientHeight
    },
    //删除单据事件
    async handleDelete (name) {
      try {
        await this.$confirm(`确定${name}该方案, 是否继续?`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'success'
        })
        if (name === '删除') {
          if (this.billStatusThreeId.length === 0) {
            this.$message.error('勾选项都是已删除的状态哦~')
            return
          }
          const obj = {
            billIds: this.billStatusThreeId,
            billStatus: '3'
          }
          //发送删除api
          await commissionUpdateStatusAPI(obj)
          // 弹出提示
          this.$message.success('删除成功')
        }
        if (this.isCheckAll) {
          this.isCheckAll = false
        }
        await this.getList()
      } catch {}
    },
    /** 查询表格列表 */
    async getList () {
      this.loadingTable = true
      const { rows, total } = await commissionListAPI(this.queryParams)
      this.tableData = rows
      this.total = total
      this.loadingTable = false
    },
    //新增单据事件
    handleAdd () {
      this.$router.push({
        name: 'commissionDetail'
      })
    },
    //跳转详情
    handleDetail (row) {
      const billId = row.billId
      this.$router.push({
        name: 'commissionDetail',
        query: {
          billId: billId,
          type: 'Update'
        }
      })
    },
    //点击行选中
    handleRowClick (row) {
      // 获取表格对象
      // 切换行选中状态
      this.$refs.multipleTable.toggleRowSelection(row)
    },
    // 多选框选中数据
    handleSelectionChange (selection) {
      this.ids = selection.map(item => item.billId) //单据id
      //筛选出来未删除的单据状态id3
      this.billStatusThreeId = selection
        .map(item => {
          if (item.billStatus == '0') {
            return item.billId
          }
        })
        .filter(ite => typeof ite !== 'undefined')

      this.multiple = !selection.length
    },
    //点击全选所有数据
    async selectAll (value) {
      if (value) {
        this.loadingTable = true
        const res = await commissionListAPI({
          ...this.queryParams,
          pageNum: 1,
          pageSize: this.total
        })
        //重新渲染表格
        this.tableData = res.rows
        //表格多选
        this.tableData.map(row => {
          this.$nextTick(() => {
            this.$refs.multipleTable.toggleRowSelection(row, value)
          })
        })
        this.loadingTable = false
      } else {
        //取消全选
        this.$nextTick(() => {
          this.$refs.multipleTable.clearSelection()
        })
        this.getList()
      }
    },
    //是否显示高级搜索
    getIsShowHigh (value) {
      this.showHigh = value
    }
  }
}
</script>

<style lang="scss" scoped>
//外面盒子
.wrap {
  padding: 10px 10px 0 10px;
  background-color: #eaeaea;
  height: calc(100vh - 84px);
  // 搜索
  .navSearch {
    padding: 10px 10px 10px 20px;
  }
  .orderInfo {
    height: calc(100% - 94px);
    //源单信息表格
    .tableContent {
      margin: 6px 10px 10px 10px;
    }
  }
}
</style>
