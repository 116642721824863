var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "wrap",
    },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: {
            model: _vm.commissionForm,
            rules: _vm.rules,
            "label-width": "90px",
          },
        },
        [
          _c(
            "div",
            { staticClass: "btnTOP" },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "mini" },
                  on: { click: _vm.submitForm },
                },
                [_vm._v("保存 ")]
              ),
              _c(
                "el-button",
                {
                  attrs: { size: "mini" },
                  on: {
                    click: function ($event) {
                      return _vm.$router.push("/shop/saleGuide/commission")
                    },
                  },
                },
                [_vm._v("退出")]
              ),
            ],
            1
          ),
          _c(
            "cardTitleCom",
            { attrs: { cardTitle: "基本信息" } },
            [
              _c("template", { slot: "cardContent" }, [
                _c(
                  "div",
                  { staticClass: "tableContentTop x-x" },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "方案编号", prop: "billNo" } },
                      [
                        _c("el-input", {
                          staticClass: "inputWidthM",
                          attrs: { size: "mini", maxlength: "20" },
                          model: {
                            value: _vm.commissionForm.billNo,
                            callback: function ($$v) {
                              _vm.$set(_vm.commissionForm, "billNo", $$v)
                            },
                            expression: "commissionForm.billNo",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "方案名称", prop: "billName" } },
                      [
                        _c("el-input", {
                          staticClass: "inputWidthM",
                          attrs: { size: "mini", maxlength: "40" },
                          model: {
                            value: _vm.commissionForm.billName,
                            callback: function ($$v) {
                              _vm.$set(_vm.commissionForm, "billName", $$v)
                            },
                            expression: "commissionForm.billName",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "生效日期", prop: "begTime" } },
                      [
                        _c("el-date-picker", {
                          staticClass: "inputWidthM",
                          attrs: {
                            type: "datetime",
                            placeholder: "选择生效日期",
                            "value-format": "yyyy-MM-dd HH:mm:ss",
                            size: "mini",
                          },
                          model: {
                            value: _vm.commissionForm.begTime,
                            callback: function ($$v) {
                              _vm.$set(_vm.commissionForm, "begTime", $$v)
                            },
                            expression: "commissionForm.begTime",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "失效日期", prop: "endTime" } },
                      [
                        _c("el-date-picker", {
                          staticClass: "inputWidthM",
                          attrs: {
                            type: "datetime",
                            placeholder: "选择失效日期",
                            "value-format": "yyyy-MM-dd HH:mm:ss",
                            size: "mini",
                          },
                          model: {
                            value: _vm.commissionForm.endTime,
                            callback: function ($$v) {
                              _vm.$set(_vm.commissionForm, "endTime", $$v)
                            },
                            expression: "commissionForm.endTime",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "备注" } },
                      [
                        _c("el-input", {
                          staticClass: "inputRemark",
                          attrs: {
                            type: "textarea",
                            placeholder: "备注长度介于 1 和 80 字符之间",
                            maxlength: "80",
                            autosize: { minRows: 1, maxRows: 5 },
                            size: "mini",
                          },
                          model: {
                            value: _vm.commissionForm.billRemark,
                            callback: function ($$v) {
                              _vm.$set(_vm.commissionForm, "billRemark", $$v)
                            },
                            expression: "commissionForm.billRemark",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ],
            2
          ),
          _c(
            "cardTitleCom",
            { attrs: { cardTitle: "提成类型" } },
            [
              _c("template", { slot: "cardContent" }, [
                _c("div", { staticClass: "tableContent marL15" }, [
                  _c(
                    "div",
                    [
                      _c(
                        "el-checkbox",
                        {
                          staticClass: "marB10",
                          model: {
                            value: _vm.commissionForm.isUseSaleCommission,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.commissionForm,
                                "isUseSaleCommission",
                                $$v
                              )
                            },
                            expression: "commissionForm.isUseSaleCommission",
                          },
                        },
                        [_vm._v("按销售提成")]
                      ),
                      _vm.commissionForm.isUseSaleCommission
                        ? _c(
                            "div",
                            [
                              _c(
                                "div",
                                { staticClass: "marB10" },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "提成范围",
                                        prop: "goodsRangeType",
                                      },
                                    },
                                    [
                                      _c(
                                        "el-select",
                                        {
                                          staticClass: "marB10",
                                          attrs: {
                                            placeholder: "请选择提成范围",
                                            filterable: "",
                                            clearable: "",
                                          },
                                          on: {
                                            change: _vm.goodsRangeTypeChange,
                                          },
                                          model: {
                                            value:
                                              _vm.commissionForm.goodsRangeType,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.commissionForm,
                                                "goodsRangeType",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "commissionForm.goodsRangeType",
                                          },
                                        },
                                        [
                                          _c("el-option", {
                                            attrs: {
                                              label: "全部商品",
                                              value: 0,
                                            },
                                          }),
                                          _c("el-option", {
                                            attrs: {
                                              label: "指定商品",
                                              value: 1,
                                            },
                                          }),
                                          _c("el-option", {
                                            attrs: {
                                              label: "指定类别",
                                              value: 2,
                                            },
                                          }),
                                          _c("el-option", {
                                            attrs: {
                                              label: "指定品牌",
                                              value: 3,
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm.commissionForm.goodsRangeType != undefined
                                ? _c(
                                    "el-table",
                                    {
                                      directives: [
                                        {
                                          name: "loading",
                                          rawName: "v-loading",
                                          value: _vm.loadingTable,
                                          expression: "loadingTable",
                                        },
                                      ],
                                      ref: "multipleTable",
                                      staticClass: "marB10",
                                      attrs: {
                                        "tooltip-effect": "dark",
                                        data: _vm.commissionForm
                                          .billGoodsDetailItems,
                                        border: "",
                                        "max-height": "500",
                                      },
                                    },
                                    [
                                      _c("el-table-column", {
                                        attrs: {
                                          label: "序号",
                                          type: "index",
                                          width: "80",
                                          "class-name": "allowDrag",
                                          align: "center",
                                        },
                                      }),
                                      !_vm.disabled &&
                                      _vm.commissionForm.goodsRangeType != 0
                                        ? _c("el-table-column", {
                                            attrs: {
                                              align: "center",
                                              label: "操作",
                                              width: "120",
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function (scope) {
                                                    return [
                                                      _c("i", {
                                                        staticClass:
                                                          "el-icon-circle-plus operatePush",
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.row(
                                                              "push",
                                                              scope.$index
                                                            )
                                                          },
                                                        },
                                                      }),
                                                      _c("i", {
                                                        staticClass:
                                                          "el-icon-remove operateDel",
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.row(
                                                              "del",
                                                              scope.$index
                                                            )
                                                          },
                                                        },
                                                      }),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              false,
                                              901523703
                                            ),
                                          })
                                        : _vm._e(),
                                      _vm.commissionForm.goodsRangeType != 0
                                        ? _c("el-table-column", {
                                            attrs: {
                                              label: "类型",
                                              align: "center",
                                              prop: "lineType",
                                              width: "130",
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function (scope) {
                                                    return [
                                                      _c("dict-tag", {
                                                        attrs: {
                                                          options:
                                                            _vm.dict.type
                                                              .vip_applicable_commodities_appoint_type,
                                                          value:
                                                            scope.row.lineType,
                                                        },
                                                      }),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              false,
                                              1794384631
                                            ),
                                          })
                                        : _vm._e(),
                                      _vm.commissionForm.goodsRangeType != 0
                                        ? _c("el-table-column", {
                                            attrs: {
                                              label: "编码",
                                              width: "180",
                                              align: "center",
                                              prop: "lineNo",
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function (scope) {
                                                    return [
                                                      _c(
                                                        "el-form-item",
                                                        {
                                                          attrs: {
                                                            prop:
                                                              "billGoodsDetailItems." +
                                                              scope.$index +
                                                              ".lineNo",
                                                            rules:
                                                              _vm.rules[
                                                                "billGoodsDetailItems.lineNo"
                                                              ],
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "el-input",
                                                            {
                                                              attrs: {
                                                                disabled:
                                                                  _vm.disabled,
                                                              },
                                                              nativeOn: {
                                                                keyup:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    if (
                                                                      !$event.type.indexOf(
                                                                        "key"
                                                                      ) &&
                                                                      _vm._k(
                                                                        $event.keyCode,
                                                                        "enter",
                                                                        13,
                                                                        $event.key,
                                                                        "Enter"
                                                                      )
                                                                    ) {
                                                                      return null
                                                                    }
                                                                    return _vm.keyup(
                                                                      "lineNo",
                                                                      $event,
                                                                      scope.$index
                                                                    )
                                                                  },
                                                              },
                                                              model: {
                                                                value:
                                                                  scope.row
                                                                    .lineNo,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      scope.row,
                                                                      "lineNo",
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "scope.row.lineNo",
                                                              },
                                                            },
                                                            [
                                                              _c("i", {
                                                                staticClass:
                                                                  "el-icon-more more",
                                                                attrs: {
                                                                  slot: "suffix",
                                                                },
                                                                on: {
                                                                  click:
                                                                    _vm.selectLineType,
                                                                },
                                                                slot: "suffix",
                                                              }),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              false,
                                              881522395
                                            ),
                                          })
                                        : _vm._e(),
                                      _vm.commissionForm.goodsRangeType != 0
                                        ? _c("el-table-column", {
                                            attrs: {
                                              label: "名称",
                                              align: "center",
                                              prop: "lineName",
                                              width: "130",
                                            },
                                          })
                                        : _vm._e(),
                                      _c("el-table-column", {
                                        attrs: {
                                          label: "提成方式",
                                          width: "350",
                                          align: "center",
                                          prop: "commissionCalcType",
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function (scope) {
                                                return [
                                                  _c(
                                                    "div",
                                                    { staticClass: "x-x" },
                                                    [
                                                      _c(
                                                        "el-form-item",
                                                        {
                                                          attrs: {
                                                            prop:
                                                              "billGoodsDetailItems." +
                                                              scope.$index +
                                                              ".commissionCalcType",
                                                            rules:
                                                              _vm.rules[
                                                                "billGoodsDetailItems.commissionCalcType"
                                                              ],
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "el-select",
                                                            {
                                                              staticClass:
                                                                "marR10",
                                                              staticStyle: {
                                                                width: "146px",
                                                              },
                                                              attrs: {
                                                                placeholder:
                                                                  "请选择提成方式",
                                                                clearable: "",
                                                              },
                                                              on: {
                                                                change:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.commissionCalcTypeChange(
                                                                      scope.row
                                                                    )
                                                                  },
                                                              },
                                                              model: {
                                                                value:
                                                                  scope.row
                                                                    .commissionCalcType,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      scope.row,
                                                                      "commissionCalcType",
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "scope.row.commissionCalcType",
                                                              },
                                                            },
                                                            [
                                                              _c("el-option", {
                                                                attrs: {
                                                                  label:
                                                                    "销售提成率(%)",
                                                                  value: 1,
                                                                },
                                                              }),
                                                              _c("el-option", {
                                                                attrs: {
                                                                  label:
                                                                    "按数量提成(个)",
                                                                  value: 2,
                                                                },
                                                              }),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                      scope.row
                                                        .commissionCalcType == 1
                                                        ? _c(
                                                            "el-form-item",
                                                            {
                                                              attrs: {
                                                                prop:
                                                                  "billGoodsDetailItems." +
                                                                  scope.$index +
                                                                  ".commissionSaleRate",
                                                                rules:
                                                                  _vm.rules[
                                                                    "billGoodsDetailItems.commissionSaleRate"
                                                                  ],
                                                              },
                                                            },
                                                            [
                                                              _c("el-input", {
                                                                staticClass:
                                                                  "inputWidth",
                                                                attrs: {
                                                                  clearable: "",
                                                                  disabled:
                                                                    _vm.disabled,
                                                                },
                                                                model: {
                                                                  value:
                                                                    scope.row
                                                                      .commissionSaleRate,
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        scope.row,
                                                                        "commissionSaleRate",
                                                                        $$v
                                                                      )
                                                                    },
                                                                  expression:
                                                                    "scope.row.commissionSaleRate",
                                                                },
                                                              }),
                                                            ],
                                                            1
                                                          )
                                                        : _vm._e(),
                                                      scope.row
                                                        .commissionCalcType == 2
                                                        ? _c(
                                                            "el-form-item",
                                                            {
                                                              attrs: {
                                                                prop:
                                                                  "billGoodsDetailItems." +
                                                                  scope.$index +
                                                                  ".commissionSaleOneMoney",
                                                                rules:
                                                                  _vm.rules[
                                                                    "billGoodsDetailItems.commissionSaleOneMoney"
                                                                  ],
                                                              },
                                                            },
                                                            [
                                                              _c("el-input", {
                                                                staticClass:
                                                                  "inputWidth",
                                                                attrs: {
                                                                  clearable: "",
                                                                  disabled:
                                                                    _vm.disabled,
                                                                },
                                                                model: {
                                                                  value:
                                                                    scope.row
                                                                      .commissionSaleOneMoney,
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        scope.row,
                                                                        "commissionSaleOneMoney",
                                                                        $$v
                                                                      )
                                                                    },
                                                                  expression:
                                                                    "scope.row.commissionSaleOneMoney",
                                                                },
                                                              }),
                                                            ],
                                                            1
                                                          )
                                                        : _vm._e(),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          false,
                                          898549946
                                        ),
                                      }),
                                      _c("el-table-column", {
                                        attrs: {
                                          align: "center",
                                          prop: "goodsNo",
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    [
                      _c(
                        "el-checkbox",
                        {
                          staticClass: "marB10",
                          model: {
                            value: _vm.commissionForm.isUseFillCommission,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.commissionForm,
                                "isUseFillCommission",
                                $$v
                              )
                            },
                            expression: "commissionForm.isUseFillCommission",
                          },
                        },
                        [_vm._v("按充值提成")]
                      ),
                      _vm.commissionForm.isUseFillCommission
                        ? _c(
                            "el-table",
                            {
                              directives: [
                                {
                                  name: "loading",
                                  rawName: "v-loading",
                                  value: _vm.loadingTable,
                                  expression: "loadingTable",
                                },
                              ],
                              ref: "multipleTable",
                              staticClass: "marB10",
                              attrs: {
                                "tooltip-effect": "dark",
                                data: _vm.commissionForm
                                  .billVipLevelDetailItems,
                                border: "",
                                "max-height": "500",
                              },
                            },
                            [
                              _c("el-table-column", {
                                attrs: {
                                  label: "序号",
                                  type: "index",
                                  width: "80",
                                  "class-name": "allowDrag",
                                  align: "center",
                                },
                              }),
                              !_vm.disabled
                                ? _c("el-table-column", {
                                    attrs: {
                                      align: "center",
                                      label: "操作",
                                      width: "120",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (scope) {
                                            return [
                                              _c("i", {
                                                staticClass:
                                                  "el-icon-circle-plus operatePush",
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.levelRow(
                                                      "push",
                                                      scope.$index
                                                    )
                                                  },
                                                },
                                              }),
                                              _c("i", {
                                                staticClass:
                                                  "el-icon-remove operateDel",
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.levelRow(
                                                      "del",
                                                      scope.$index
                                                    )
                                                  },
                                                },
                                              }),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      2375655031
                                    ),
                                  })
                                : _vm._e(),
                              _c("el-table-column", {
                                attrs: {
                                  label: "会员卡级别编码",
                                  width: "180",
                                  align: "center",
                                  prop: "vipLevelNo",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          _c(
                                            "el-form-item",
                                            {
                                              attrs: {
                                                prop:
                                                  "billVipLevelDetailItems." +
                                                  scope.$index +
                                                  ".vipLevelNo",
                                                rules:
                                                  _vm.rules[
                                                    "billVipLevelDetailItems.vipLevelNo"
                                                  ],
                                              },
                                            },
                                            [
                                              _c(
                                                "el-input",
                                                {
                                                  attrs: {
                                                    disabled: _vm.disabled,
                                                  },
                                                  nativeOn: {
                                                    keyup: function ($event) {
                                                      if (
                                                        !$event.type.indexOf(
                                                          "key"
                                                        ) &&
                                                        _vm._k(
                                                          $event.keyCode,
                                                          "enter",
                                                          13,
                                                          $event.key,
                                                          "Enter"
                                                        )
                                                      ) {
                                                        return null
                                                      }
                                                      return _vm.keyupVipLevel(
                                                        "vipLevelNo",
                                                        $event,
                                                        scope.$index
                                                      )
                                                    },
                                                  },
                                                  model: {
                                                    value: scope.row.vipLevelNo,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        scope.row,
                                                        "vipLevelNo",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "scope.row.vipLevelNo",
                                                  },
                                                },
                                                [
                                                  _c("i", {
                                                    staticClass:
                                                      "el-icon-more more",
                                                    attrs: { slot: "suffix" },
                                                    on: {
                                                      click: _vm.selectVipLevel,
                                                    },
                                                    slot: "suffix",
                                                  }),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  1643689133
                                ),
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  label: "会员卡级别名称",
                                  align: "center",
                                  prop: "vipLevelName",
                                  width: "180",
                                },
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  label: "充值提成率(%)",
                                  width: "180",
                                  align: "center",
                                  prop: "commissionFillRate",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          _c(
                                            "el-form-item",
                                            {
                                              attrs: {
                                                prop:
                                                  "billVipLevelDetailItems." +
                                                  scope.$index +
                                                  ".commissionFillRate",
                                                rules:
                                                  _vm.rules[
                                                    "billVipLevelDetailItems.commissionFillRate"
                                                  ],
                                              },
                                            },
                                            [
                                              _c("el-input", {
                                                attrs: {
                                                  disabled: _vm.disabled,
                                                },
                                                model: {
                                                  value:
                                                    scope.row
                                                      .commissionFillRate,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      scope.row,
                                                      "commissionFillRate",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "scope.row.commissionFillRate",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  1798498066
                                ),
                              }),
                              _c("el-table-column", {
                                attrs: { align: "center" },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ]),
              ]),
            ],
            2
          ),
        ],
        1
      ),
      _c("selectGoods", {
        attrs: { OpenGoods: _vm.openGoods, queryCode: _vm.queryCode },
        on: {
          "update:OpenGoods": function ($event) {
            _vm.openGoods = $event
          },
          "update:open-goods": function ($event) {
            _vm.openGoods = $event
          },
          getGoodS: _vm.getGoodS,
        },
      }),
      _c("selectCategory", {
        attrs: {
          OpenCategory: _vm.openCategory,
          queryCode: _vm.queryCodeCategory,
        },
        on: {
          "update:OpenCategory": function ($event) {
            _vm.openCategory = $event
          },
          "update:open-category": function ($event) {
            _vm.openCategory = $event
          },
          categoryList: _vm.categoryList,
        },
      }),
      _c("selectBrand", {
        attrs: { OpenBrand: _vm.openBrand, queryCode: _vm.queryCodeBrand },
        on: {
          "update:OpenBrand": function ($event) {
            _vm.openBrand = $event
          },
          "update:open-brand": function ($event) {
            _vm.openBrand = $event
          },
          brandList: _vm.brandList,
        },
      }),
      _c("vipLeve", {
        attrs: {
          OpenVipLeve: _vm.openVipLeve,
          queryCode: _vm.queryCodeVipLeve,
        },
        on: {
          "update:OpenVipLeve": function ($event) {
            _vm.openVipLeve = $event
          },
          "update:open-vip-leve": function ($event) {
            _vm.openVipLeve = $event
          },
          vipLeveList: _vm.vipLeveList,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }