<template>
  <div class="x-fsa wrap">
    <div
      v-for="(item, index) in urlList"
      :key="index"
      class="y-c"
      style="height: 100%"
    >
      <el-image :src="item.imgUrl" class="marB10"></el-image>
      <span class="fontS14">{{ item.name }}</span>
      <span class="fontS14 marB20">{{ item.equipment }}</span>
      <el-button
        :disabled="item.downloadUrl == undefined || !item.downloadUrl"
        @click="downloadFile(item.downloadUrl)"
        class="downloadBtn"
      >
        立即下载
      </el-button>
    </div>
  </div>
</template>

<script>
import {
  packageConfigList,
  shopBillPosList
} from '@/api/tenant/resource/setupPackage'

export default {
  name: 'downloadPackage',
  data () {
    return {
      urlList: [
        {
          imgUrl:
            'https://balingsoft-oss.oss-cn-shenzhen.aliyuncs.com/file/2023-05-16/4fed860273934c838bb8d9b41911e513.png',
          name: 'PC端',
          equipment: '支持WIN7/WIN10,不支持XP'
        },
        {
          imgUrl:
            'https://balingsoft-oss.oss-cn-shenzhen.aliyuncs.com/file/2023-05-16/1bb3a875db0447b9be09500f0ad2f1ee.png',
          name: 'Android版',
          equipment: '支持Android 5.0以上'
        },
        {
          imgUrl:
            'https://balingsoft-oss.oss-cn-shenzhen.aliyuncs.com/file/2023-05-16/78dd512b739a4a3d8b75dac7666fd1c5.png',
          name: 'iOS版',
          equipment: '支持 iOS 11.0以上'
        }
      ]
    }
  },
  created () {
    this.getList()
    console.log('===', this.urlList)
  },
  methods: {
    downloadFile (url) {
      console.log('下载', url)
      let link = document.createElement('a')
      link.style.display = 'none'
      link.href = url
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    },
    /**列表请求**/
    getList () {
      shopBillPosList(this.queryParams).then(res => {
        res.data.forEach(item => {
          if (item.setupPackageType == 1) {
            // android
            this.$set(
              this.urlList[0],
              'downloadUrl',
              item.packageFileResp.attachFileUrl
            )
          } else if (item.setupPackageType == 2) {
            // pc
            this.$set(
              this.urlList[1],
              'downloadUrl',
              item.packageFileResp.attachFileUrl
            )
          } else if (item.setupPackageType == 3) {
            // ios
            this.$set(
              this.urlList[2],
              'downloadUrl',
              item.packageFileResp.attachFileUrl
            )
          }
        })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.wrap {
  height: calc(100vh - 84px);
}

.downloadBtn {
  padding: 7px 15px;
  background-color: #3f9bfa;
  color: #ffffff;
  border-radius: 5px;
  font-size: 12px;
}
</style>
