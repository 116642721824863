<template>
  <div class="container x-start">
    <div class="leftBox padd15">
      <div
        class="item x-c cursorP"
        :class="{
          clickStyle: leftItem == index,
          active: index == leftItem
        }"
        v-for="(item, index) in leftBtnList"
        :key="index"
        @click="itemClick(index)"
      >
        {{ item }}
      </div>
    </div>
    <div class="rightBox marL10" style="overflow-y: scroll">
      <shopParam v-show="leftItem == 0" />
      <payParam v-show="leftItem == 1" />
      <vipParam v-show="leftItem == 2" />
    </div>
  </div>
</template>

<script>
import cardTitleCom from '@/views/components/cardTitleCom' //卡片标题
import shopParam from '@/views/shop/setup/posParam/components/shopParam.vue'
import payParam from '@/views/shop/setup/posParam/components/payParam.vue'
import vipParam from '@/views/shop/setup/posParam/components/vipParam.vue'

export default {
  name: 'index',
  components: {
    cardTitleCom,
    shopParam,
    payParam,
    vipParam
  },
  data () {
    return {
      leftBtnList: ['门店要货参数', '收银参数设置', '会员参数设置'],
      leftItem: 0, // 左边菜单点击项

      value: false,
      radio: '',
      tableData: [{}],
      isRadio: '2',
      isradio: '',
      checkList: [],
      form: {
        selectValue: ''
      },
      cities: [
        {
          label: '不舍入',
          labelTOW: '1.55-1.55',
          value: '1'
        },
        {
          label: '舍去分',
          labelTOW: '1.55-1.50',
          value: '2'
        },
        {
          label: '舍去角',
          labelTOW: '1.55-1.00',
          value: '3'
        },
        {
          label: '四舍五入到分',
          labelTOW: '1.55-1.55',
          value: '4'
        },
        {
          label: '四舍五入到角',
          labelTOW: '1.55-1.60',
          value: '5'
        },
        {
          label: '四舍五入到元',
          labelTOW: '1.55-2.00',
          value: '6'
        }
      ]
    }
  },
  watch: {
    isRadio (nV) {
      if (nV == 2) {
        this.isradio = '6'
      }
    }
  },
  methods: {
    itemClick (index) {
      console.log('---', index)
      this.leftItem = index
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  padding: 10px 10px 0 10px;
  background-color: #eaeaea;
  font-size: 13px;
  height: calc(100vh - 84px);
  .leftBox {
    padding-top: 40px;
    width: 210px;
    z-index: 10;
    // height: calc(100vh - 84px);
    background: #ffffff;
    .item {
      height: 50px;
      font-size: 16px;
      font-weight: 550;
    }
    .active {
      color: #1890ff;
    }
  }
  .rightBox {
    width: calc(100% - 220px);
    // height: calc(100vh - 84px);
  }
  .fontColor {
    color: #949494;
  }
}

.clickStyle {
  background: #e8f1fc;
}

::-webkit-scrollbar {
  display: none;
}
/* 背景色 */
::-webkit-scrollbar-track {
  background-color: #ffffff;
}
/* 滑块颜色 */
::-webkit-scrollbar-thumb {
  background-color: rgba(144, 147, 153, 0.5);
}
</style>
