var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container x-start" }, [
    _c(
      "div",
      { staticClass: "leftBox padd15" },
      _vm._l(_vm.leftBtnList, function (item, index) {
        return _c(
          "div",
          {
            key: index,
            staticClass: "item x-c cursorP",
            class: {
              clickStyle: _vm.leftItem == index,
              active: index == _vm.leftItem,
            },
            on: {
              click: function ($event) {
                return _vm.itemClick(index)
              },
            },
          },
          [_vm._v(" " + _vm._s(item) + " ")]
        )
      }),
      0
    ),
    _c(
      "div",
      {
        staticClass: "rightBox marL10",
        staticStyle: { "overflow-y": "scroll" },
      },
      [
        _c("shopParam", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.leftItem == 0,
              expression: "leftItem == 0",
            },
          ],
        }),
        _c("payParam", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.leftItem == 1,
              expression: "leftItem == 1",
            },
          ],
        }),
        _c("vipParam", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.leftItem == 2,
              expression: "leftItem == 2",
            },
          ],
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }