
const defaultConfig = (name) => {
    return {
        name,
        label: `${name}: `,
        value: `#{${name}}`,
        splitLine: false,
        type: "text",
        style: {
            width: "100",
            fontFamily: "宋体",
            fontSize: 12,
            lineHeight: 17,
            fontWeight: 400,
            fontStyle: "normal",
            textAlign: "left",
            paddingLeft: 0,
            paddingRight: 0,
            paddingTop: 0,
            paddingBottom: 0,
            marginLeft: 0,
            marginRight: 0,
            marginTop: 0,
            marginBottom: 0,
        },
        labelStyle: {
            fontSize: 12,
        },
    }
}

//高级设置
//小票参数数组
const cashierParameterListHighLevel = [
    defaultConfig('门店名称'),
    defaultConfig('门店地址'),
    defaultConfig('联系电话'),
    defaultConfig('收银员姓名'),
    defaultConfig('收银员编号'),
    defaultConfig('导购员'),
    defaultConfig('导购员编号'),
    defaultConfig('小票号'),
    {
        ...defaultConfig('商品'),
        type: 'goods',
        children: [
            {
                name: "商品名称",
                label: "商品名称",
                value: "#{商品名称}",
                show: true,
                style: {
                    width: "30",
                },
            },
            {
                name: "商品编码",
                label: "商品编码",
                value: "#{商品编码}",
                show: false,
                style: {
                    width: "15",
                },
            },
            {
                name: "条码",
                label: "条码",
                value: "#{条码}",
                show: true,
                style: {
                    width: "15",
                },
            },
            {
                name: "单位",
                label: "单位",
                value: "#{单位}",
                show: true,
                style: {
                    width: "15",
                },
            },
            {
                name: "规格",
                label: "规格",
                value: "#{规格}",
                show: false,
                style: {
                    width: "15",
                },
            },
            {
                name: "加值属性",
                label: "加值属性",
                value: "#{加值属性}",
                show: false,
                style: {
                    width: "30",
                },
            },
            {
                name: "数量",
                label: "数量",
                value: "#{数量}",
                show: true,
                style: {
                    width: "15",
                },
            },
            {
                name: "单价",
                label: "单价",
                value: "#{单价}",
                show: false,
                style: {
                    width: "15",
                },
            },
            {
                name: "售价",
                label: "售价",
                value: "#{售价}",
                show: false,
                style: {
                    width: "15",
                },
            },
            {
                name: "金额",
                label: "金额",
                value: "#{金额}",
                show: true,
                style: {
                    width: "15",
                },
            },
        ],
    },
    defaultConfig('支付方式'),
    defaultConfig('总计'),
    defaultConfig('件数'),
    defaultConfig('应收'),
    defaultConfig('原价'),
    defaultConfig('整单折扣'),
    defaultConfig('实收'),
    defaultConfig('收款'),
    defaultConfig('找零'),
    defaultConfig('优惠'),
    defaultConfig('日期时间'),
    defaultConfig('会员姓名'),
    defaultConfig('会员号'),
    defaultConfig('手机号'),
    defaultConfig('会员等级'),
    defaultConfig('会员卡本次消费金额'),
    defaultConfig('消费后余额'),
    defaultConfig('消费前余额'),
    defaultConfig('积分'),
    defaultConfig('本次积分'),
    defaultConfig('消耗积分'),
    defaultConfig('打印时间'),
    defaultConfig('取餐码'),
    {
        ...defaultConfig('实分割线'),
        type: "solidSplitLine",
    },
    {
        ...defaultConfig('虚分割线'),
        type: "dashedSplitLine",
    },
    {
        ...defaultConfig('自定义文字'),
        value: "",
    },
    {
        ...defaultConfig('自定义LOGO'),
        value: "",
        type: "img",
        style:{
            ...defaultConfig('自定义LOGO').style,
            textAlign:'center'
        }
    },
    {
        ...defaultConfig('自定义二维码'),
        value: "",
        type: "img",
        style:{
            ...defaultConfig('自定义二维码').style,
            textAlign:'center'
        }
    }
]

//厨打参数数组
const kitchenPrintParameterListHighLevel = [
    defaultConfig('打印机名称'),
    defaultConfig('手工单据号'),
    defaultConfig('磅数'),
    defaultConfig('尺寸'),
    defaultConfig('样式'),
    {
        ...defaultConfig('商品'),
        type: 'goods',
        children: [
            {
                name: "商品名称",
                label: "商品名称",
                value: "#{商品名称}",
                show: true,
                style: {
                    width: "30",
                },
            },
            {
                name: "商品编码",
                label: "商品编码",
                value: "#{商品编码}",
                show: false,
                style: {
                    width: "15",
                },
            },
            {
                name: "条码",
                label: "条码",
                value: "#{条码}",
                show: true,
                style: {
                    width: "15",
                },
            },
            {
                name: "单位",
                label: "单位",
                value: "#{单位}",
                show: true,
                style: {
                    width: "15",
                },
            },
            {
                name: "规格",
                label: "规格",
                value: "#{规格}",
                show: false,
                style: {
                    width: "15",
                },
            },
            {
                name: "加值属性",
                label: "加值属性",
                value: "#{加值属性}",
                show: false,
                style: {
                    width: "30",
                },
            },
            {
                name: "数量",
                label: "数量",
                value: "#{数量}",
                show: true,
                style: {
                    width: "15",
                },
            },
            {
                name: "单价",
                label: "单价",
                value: "#{单价}",
                show: false,
                style: {
                    width: "15",
                },
            },
            {
                name: "售价",
                label: "售价",
                value: "#{售价}",
                show: false,
                style: {
                    width: "15",
                },
            },
            {
                name: "金额",
                label: "金额",
                value: "#{金额}",
                show: true,
                style: {
                    width: "15",
                },
            },
        ],
    },
    {
        ...defaultConfig('商品2'),
        type: 'goods2',
        children: [
            {
                name: "商品名称",
                label: "商品名称",
                value: "#{商品名称}",
                show: true,
                style: {
                    width: "100",
                },
            },
            {
                name: "商品编码",
                label: "商品编码",
                value: "#{商品编码}",
                show: false,
                style: {
                    width: "100",
                },
            },
            {
                name: "条码",
                label: "条码",
                value: "#{条码}",
                show: true,
                style: {
                    width: "100",
                },
            },
            {
                name: "单位",
                label: "单位",
                value: "#{单位}",
                show: true,
                style: {
                    width: "100",
                },
            },
            {
                name: "规格",
                label: "规格",
                value: "#{规格}",
                show: false,
                style: {
                    width: "100",
                },
            },
            {
                name: "加值属性",
                label: "加值属性",
                value: "#{加值属性}",
                show: false,
                style: {
                    width: "100",
                },
            },
            {
                name: "数量",
                label: "数量",
                value: "#{数量}",
                show: true,
                style: {
                    width: "100",
                },
            },
            {
                name: "单价",
                label: "单价",
                value: "#{单价}",
                show: false,
                style: {
                    width: "100",
                },
            },
            {
                name: "售价",
                label: "售价",
                value: "#{售价}",
                show: false,
                style: {
                    width: "100",
                },
            },
            {
                name: "金额",
                label: "金额",
                value: "#{金额}",
                show: true,
                style: {
                    width: "100",
                },
            },
        ],
    },
    defaultConfig('下单时间'),
    defaultConfig('订单来源'),
    defaultConfig('打印时间'),
    defaultConfig('配送地址'),
    defaultConfig('收货人'),
    defaultConfig('电话'),
    defaultConfig('流水号'),
    defaultConfig('备注'),
    defaultConfig('取货时间'),
    defaultConfig('配送时间'),
    defaultConfig('订单号'),
    defaultConfig('取货门店'),
    defaultConfig('配送门店'),
    defaultConfig('配送方式'),
    defaultConfig('预订门店'),
    defaultConfig('桌台号'),
    defaultConfig('桌台名称'),
    defaultConfig('就餐方式'),
    defaultConfig('取餐码'),
    {
        ...defaultConfig('核销码'),
        value: require('@/assets/fixedImages/QRCode.png'),
        type: "img",
        disable:true,
        style:{
            ...defaultConfig('核销码').style,
            textAlign:'center'
        }
    },
    {
        ...defaultConfig('实分割线'),
        type: "solidSplitLine",
    },
    {
        ...defaultConfig('虚分割线'),
        type: "dashedSplitLine",
    },
    {
        ...defaultConfig('自定义文字'),
        value: "",
    },
    {
        ...defaultConfig('自定义LOGO'),
        value: "",
        type: "img",
        style:{
            ...defaultConfig('自定义LOGO').style,
            textAlign:'center'
        }
    },
    {
        ...defaultConfig('自定义二维码'),
        value: "",
        type: "img",
        style:{
            ...defaultConfig('自定义二维码').style,
            textAlign:'center'
        }
    }
]

//订单参数数组
const orderMakeParameterListHighLevel = [
    defaultConfig('打印机名称'),
    defaultConfig('手工单据号'),
    defaultConfig('磅数'),
    defaultConfig('尺寸'),
    defaultConfig('样式'),
    defaultConfig('订单号'),
    defaultConfig('餐台号'),
    defaultConfig('流水号'),
    defaultConfig('订单来源'),
    defaultConfig('打印时间'),
    {
        ...defaultConfig('商品'),
        type: 'goods',
        children: [
            {
                name: "商品名称",
                label: "商品名称",
                value: "#{商品名称}",
                show: true,
                style: {
                    width: "30",
                },
            },
            {
                name: "商品编码",
                label: "商品编码",
                value: "#{商品编码}",
                show: false,
                style: {
                    width: "15",
                },
            },
            {
                name: "条码",
                label: "条码",
                value: "#{条码}",
                show: true,
                style: {
                    width: "15",
                },
            },
            {
                name: "单位",
                label: "单位",
                value: "#{单位}",
                show: true,
                style: {
                    width: "15",
                },
            },
            {
                name: "规格",
                label: "规格",
                value: "#{规格}",
                show: false,
                style: {
                    width: "15",
                },
            },
            {
                name: "加值属性",
                label: "加值属性",
                value: "#{加值属性}",
                show: false,
                style: {
                    width: "30",
                },
            },
            {
                name: "数量",
                label: "数量",
                value: "#{数量}",
                show: true,
                style: {
                    width: "15",
                },
            },
            {
                name: "单价",
                label: "单价",
                value: "#{单价}",
                show: false,
                style: {
                    width: "15",
                },
            },
            {
                name: "售价",
                label: "售价",
                value: "#{售价}",
                show: false,
                style: {
                    width: "15",
                },
            },
            {
                name: "金额",
                label: "金额",
                value: "#{金额}",
                show: true,
                style: {
                    width: "15",
                },
            },
        ],
    },
    {
        ...defaultConfig('商品2'),
        type: 'goods2',
        children: [
            {
                name: "商品名称",
                label: "商品名称",
                value: "#{商品名称}",
                show: true,
                style: {
                    width: "100",
                },
            },
            {
                name: "商品编码",
                label: "商品编码",
                value: "#{商品编码}",
                show: false,
                style: {
                    width: "100",
                },
            },
            {
                name: "条码",
                label: "条码",
                value: "#{条码}",
                show: true,
                style: {
                    width: "100",
                },
            },
            {
                name: "单位",
                label: "单位",
                value: "#{单位}",
                show: true,
                style: {
                    width: "100",
                },
            },
            {
                name: "规格",
                label: "规格",
                value: "#{规格}",
                show: false,
                style: {
                    width: "100",
                },
            },
            {
                name: "加值属性",
                label: "加值属性",
                value: "#{加值属性}",
                show: false,
                style: {
                    width: "100",
                },
            },
            {
                name: "数量",
                label: "数量",
                value: "#{数量}",
                show: true,
                style: {
                    width: "100",
                },
            },
            {
                name: "单价",
                label: "单价",
                value: "#{单价}",
                show: false,
                style: {
                    width: "100",
                },
            },
            {
                name: "售价",
                label: "售价",
                value: "#{售价}",
                show: false,
                style: {
                    width: "100",
                },
            },
            {
                name: "金额",
                label: "金额",
                value: "#{金额}",
                show: true,
                style: {
                    width: "100",
                },
            },
        ],
    },
    defaultConfig('支付方式'),
    defaultConfig('收货人'),
    defaultConfig('门店电话'),
    defaultConfig('预定门店'),
    defaultConfig('取货门店'),
    defaultConfig('取货时间'),
    defaultConfig('配送门店'),
    defaultConfig('配送方式'),
    defaultConfig('取货方式'),
    defaultConfig('配送时间'),
    defaultConfig('联系电话'),
    defaultConfig('送货地址'),
    defaultConfig('预付款'),
    defaultConfig('余款'),
    defaultConfig('备注'),
    defaultConfig('取餐码'),
    {
        ...defaultConfig('核销码'),
        value: require('@/assets/fixedImages/QRCode.png'),
        type: "img",
        disable:true,
        style:{
            ...defaultConfig('核销码').style,
            textAlign:'center'
        }
    },
    {
        ...defaultConfig('实分割线'),
        type: "solidSplitLine",
    },
    {
        ...defaultConfig('虚分割线'),
        type: "dashedSplitLine",
    },
    {
        ...defaultConfig('自定义文字'),
        value: "",
    },
    {
        ...defaultConfig('自定义LOGO'),
        value: "",
        type: "img",
        style:{
            ...defaultConfig('自定义LOGO').style,
            textAlign:'center'
        }
    },
    {
        ...defaultConfig('自定义二维码'),
        value: "",
        type: "img",
        style:{
            ...defaultConfig('自定义二维码').style,
            textAlign:'center'
        }
    }
]

//配送参数数组
const deliveryParameterListHighLevel = [
    defaultConfig('订单号'),
    defaultConfig('订单来源'),
    defaultConfig('配送时间'),
    // defaultConfig('流水号'),
    // defaultConfig('取货时间'),
    // defaultConfig('取货门店'),
    defaultConfig('配送方式'),
    defaultConfig('配送门店'),
    defaultConfig('下单时间'),
    defaultConfig('打印时间'),
    {
        ...defaultConfig('商品'),
        type: 'goods',
        children: [
            {
                name: "商品名称",
                label: "商品名称",
                value: "#{商品名称}",
                show: true,
                style: {
                    width: "30",
                },
            },
            {
                name: "商品编码",
                label: "商品编码",
                value: "#{商品编码}",
                show: false,
                style: {
                    width: "15",
                },
            },
            {
                name: "条码",
                label: "条码",
                value: "#{条码}",
                show: true,
                style: {
                    width: "15",
                },
            },
            {
                name: "单位",
                label: "单位",
                value: "#{单位}",
                show: true,
                style: {
                    width: "15",
                },
            },
            {
                name: "规格",
                label: "规格",
                value: "#{规格}",
                show: false,
                style: {
                    width: "15",
                },
            },
            {
                name: "加值属性",
                label: "加值属性",
                value: "#{加值属性}",
                show: false,
                style: {
                    width: "30",
                },
            },
            {
                name: "数量",
                label: "数量",
                value: "#{数量}",
                show: true,
                style: {
                    width: "15",
                },
            },
            {
                name: "单价",
                label: "单价",
                value: "#{单价}",
                show: false,
                style: {
                    width: "15",
                },
            },
            {
                name: "售价",
                label: "售价",
                value: "#{售价}",
                show: false,
                style: {
                    width: "15",
                },
            },
            {
                name: "金额",
                label: "金额",
                value: "#{金额}",
                show: true,
                style: {
                    width: "15",
                },
            },
        ],
    },
    defaultConfig('备注'),
    defaultConfig('配送费'),
    defaultConfig('打包费'),
    defaultConfig('合计金额'),
    defaultConfig('支付方式'),
    defaultConfig('收货人'),
    defaultConfig('电话'),
    defaultConfig('收货地址'),
    defaultConfig('取餐码'),
    {
        ...defaultConfig('实分割线'),
        type: "solidSplitLine",
    },
    {
        ...defaultConfig('虚分割线'),
        type: "dashedSplitLine",
    },
    {
        ...defaultConfig('自定义文字'),
        value: "",
    },
    {
        ...defaultConfig('自定义LOGO'),
        value: "",
        type: "img",
        style:{
            ...defaultConfig('自定义LOGO').style,
            textAlign:'center'
        }
    },
    {
        ...defaultConfig('自定义二维码'),
        value: "",
        type: "img",
        style:{
            ...defaultConfig('自定义二维码').style,
            textAlign:'center'
        }
    }
]


//小票参数数组
const cashierParameterList = [
    { name: "门店名称", text: "#{门店名称}" },
    { name: "门店地址", text: "#{门店地址}" },
    { name: "联系电话", text: "#{联系电话}" },
    { name: "收银员姓名", text: "#{收银员姓名}" },
    { name: "收银员编号", text: "#{收银员编号}" },
    { name: "导购员", text: "#{导购员}" },
    { name: "导购员编号", text: "#{导购员编号}" },
    { name: "小票号", text: "#{小票号}" },
    { name: "支付方式", text: "#{支付方式}" },
    { name: "商品编码(需在#item内)", text: "#{商品编码}" },
    { name: "条码(需在#item内)", text: "#{条码}" },
    { name: "商品名称(需在#item内)", text: "#{商品名称}" },
    { name: "单位(需在#item内)", text: "#{单位}" },
    { name: "规格(需在#item内)", text: "#{规格}" },
    { name: "加值属性(需在#item内)", text: "#{加值属性}" },
    { name: "数量(需在#item内)", text: "#{数量}" },
    { name: "单价(需在#item内)", text: "#{单价}" },
    { name: "售价(需在#item内)", text: "#{售价}" },
    { name: "金额(需在#item内)", text: "#{金额}" },
    { name: "总计", text: "#{总计}" },
    { name: "件数", text: "#{件数}" },
    { name: "应收", text: "#{应收}" },
    { name: "原价", text: "#{原价}" },
    { name: "整单折扣", text: "#{整单折扣}" },
    { name: "实收", text: "#{实收}" },
    { name: "收款", text: "#{收款}" },
    { name: "找零", text: "#{找零}" },
    { name: "优惠", text: "#{优惠}" },
    { name: "日期时间", text: "#{日期时间}" },
    { name: "会员姓名", text: "#{会员姓名}" },
    { name: "会员号", text: "#{会员号}" },
    { name: "手机号", text: "#{手机号}" },
    { name: "会员等级", text: "#{会员等级}" },
    { name: "会员卡本次消费金额", text: "#{会员卡本次消费金额}" },
    { name: "消费后余额", text: "#{消费后余额}" },
    { name: "消费前余额", text: "#{消费前余额}" },
    { name: "积分", text: "#{积分}" },
    { name: "本次积分", text: "#{本次积分}" },
    { name: "消耗积分", text: "#{消耗积分}" },
    { name: "打印时间", text: "#{打印时间}" },
    { name: "取餐码", text: "#{取餐码}" },

]
//厨打参数数组
const kitchenPrintParameterList = [
    { name: "打印机名称", text: "#{打印机名称}" },
    { name: "手工单据号", text: "#{手工单据号}" },
    { name: "磅数", text: "#{磅数}" },
    { name: "尺寸", text: "#{尺寸}" },
    { name: "样式", text: "#{样式}" },
    { name: "商品名称(需在#item内)", text: "#{商品名称}" },
    { name: "规格(需在#item内)", text: "#{规格}" },
    { name: "加值属性(需在#item内)", text: "#{加值属性}" },
    { name: "数量(需在#item内)", text: "#{数量}" },
    { name: "备注", text: "#{备注}" },
    { name: "下单时间", text: "#{下单时间}" },
    { name: "订单来源", text: "#{订单来源}" },
    { name: "打印时间", text: "#{打印时间}" },
    { name: "配送地址", text: "#{配送地址}" },
    { name: "收货人", text: "#{收货人}" },
    { name: "电话", text: "#{电话}" },
    { name: "流水号", text: "#{流水号}" },
    { name: "取货时间", text: "#{取货时间}" },
    { name: "配送时间", text: "#{配送时间}" },
    { name: "订单号", text: "#{订单号}" },
    { name: "取货门店", text: "#{取货门店}" },
    { name: "配送门店", text: "#{配送门店}" },
    { name: "配送方式", text: "#{配送方式}" },
    { name: "预订门店", text: "#{预订门店}" },
    { name: "桌台号", text: "#{桌台号}" },
    { name: "桌台名称", text: "#{桌台名称}" },
    { name: "就餐方式", text: "#{就餐方式}" },
    { name: "打印时间", text: "#{打印时间}" },
    { name: "取餐码", text: "#{取餐码}" },
    { name: "核销码", text: "#{核销码}" }
]
//订单参数数组
const orderMakeParameterList = [
    { name: "打印机名称", text: "#{打印机名称}" },
    { name: "手工单据号", text: "#{手工单据号}" },
    { name: "磅数", text: "#{磅数}" },
    { name: "尺寸", text: "#{尺寸}" },
    { name: "样式", text: "#{样式}" },
    { name: "订单号", text: "#{订单号}" },
    { name: "餐台号", text: "#{餐台号}" },
    { name: "流水号", text: "#{流水号}" },
    { name: "订单来源", text: "#{订单来源}" },
    { name: "打印时间", text: "#{打印时间}" },
    { name: "商品名称(需在#item内)", text: "#{商品名称}" },
    { name: "加值属性(需在#item内)", text: "#{加值属性}" },
    { name: "单位(需在#item内)", text: "#{单位}" },
    { name: "规格(需在#item内)", text: "#{规格}" },
    { name: "售价(需在#item内)", text: "#{售价}" },
    { name: "数量(需在#item内)", text: "#{数量}" },
    { name: "金额(需在#item内)", text: "#{金额}" },
    { name: "支付方式", text: "#{支付方式}" },
    { name: "收货人", text: "#{收货人}" },
    { name: "门店电话", text: "#{门店电话}" },
    { name: "预定门店", text: "#{预定门店}" },
    { name: "取货门店", text: "#{取货门店}" },
    { name: "取货时间", text: "#{取货时间}" },
    { name: "配送门店", text: "#{配送门店}" },
    { name: "配送方式", text: "#{配送方式}" },
    { name: "取货方式", text: "#{取货方式}" },
    { name: "配送时间", text: "#{配送时间}" },
    { name: "联系电话", text: "#{联系电话}" },
    { name: "送货地址", text: "#{送货地址}" },
    { name: "预付款", text: "#{预付款}" },
    { name: "余款", text: "#{余款}" },
    { name: "备注", text: "#{备注}" },
    { name: "取餐码", text: "#{取餐码}" },
    { name: "核销码", text: "#{核销码}" }
]
//配送参数数组
const deliveryParameterList = [
    { name: "订单号", text: "#{订单号}" },
    { name: "订单来源", text: "#{订单来源}" },
    { name: "配送时间", text: "#{配送时间}" },
    // { name: "流水号", text: "#{流水号}" },
    // { name: "取货时间", text: "#{取货时间}" },
    // { name: "取货门店", text: "#{取货门店}" },
    { name: "配送方式", text: "#{配送方式}" },
    { name: "配送门店", text: "#{配送门店}" },
    { name: "下单时间", text: "#{下单时间}" },
    { name: "打印时间", text: "#{打印时间}" },
    { name: "商品名称(需在#item内)", text: "#{商品名称}" },
    { name: "加值属性(需在#item内)", text: "#{加值属性}" },
    { name: "数量(需在#item内)", text: "#{数量}" },
    { name: "备注", text: "#{备注}" },
    { name: "配送费", text: "#{配送费}" },
    { name: "打包费", text: "#{打包费}" },
    { name: "合计金额", text: "#{合计金额}" },
    { name: "支付方式", text: "#{支付方式}" },
    { name: "收货人", text: "#{收货人}" },
    { name: "电话", text: "#{电话}" },
    { name: "收货地址", text: "#{收货地址}" },
    { name: "打印时间", text: "#{打印时间}" },
    { name: "取餐码", text: "#{取餐码}" },
]


export default {
    cashierParameterList,
    kitchenPrintParameterList,
    orderMakeParameterList,
    deliveryParameterList,
    cashierParameterListHighLevel,
    kitchenPrintParameterListHighLevel,
    orderMakeParameterListHighLevel,
    deliveryParameterListHighLevel
}