var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "right-decorate" },
    [
      _c("div", { staticClass: "title" }, [
        _vm.newForm.type
          ? _c("span", [
              _vm._v(" " + _vm._s("[" + _vm.newForm.name + "]") + " "),
            ])
          : _vm._e(),
        _vm._v(" 内容编辑 "),
      ]),
      _vm.newForm.type
        ? _c(
            "el-scrollbar",
            [
              _vm.newForm.type === "text"
                ? [
                    _c(
                      "div",
                      { staticClass: "row" },
                      [
                        _c("div", { staticClass: "label" }, [
                          _vm._v("文字内容:"),
                        ]),
                        _c("el-input", {
                          model: {
                            value: _vm.newForm.label,
                            callback: function ($$v) {
                              _vm.$set(_vm.newForm, "label", $$v)
                            },
                            expression: "newForm.label",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "row" },
                      [
                        _c("div", { staticClass: "label" }, [_vm._v("整行:")]),
                        _c("el-switch", {
                          attrs: {
                            "active-value": "100",
                            "inactive-value": "50",
                          },
                          model: {
                            value: _vm.newForm.style.width,
                            callback: function ($$v) {
                              _vm.$set(_vm.newForm.style, "width", $$v)
                            },
                            expression: "newForm.style.width",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "row" },
                      [
                        _c("div", { staticClass: "label" }, [_vm._v("字体:")]),
                        _c(
                          "el-select",
                          {
                            attrs: { placeholder: "请选择" },
                            model: {
                              value: _vm.newForm.style.fontFamily,
                              callback: function ($$v) {
                                _vm.$set(_vm.newForm.style, "fontFamily", $$v)
                              },
                              expression: "newForm.style.fontFamily",
                            },
                          },
                          _vm._l(_vm.options.fontFamily, function (item) {
                            return _c("el-option", {
                              key: item,
                              attrs: { label: item, value: item },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "row" },
                      [
                        _c("div", { staticClass: "label" }, [
                          _vm._v("参数名字号:"),
                        ]),
                        _c("el-input", {
                          attrs: { type: "number" },
                          model: {
                            value: _vm.newForm.labelStyle.fontSize,
                            callback: function ($$v) {
                              _vm.$set(_vm.newForm.labelStyle, "fontSize", $$v)
                            },
                            expression: "newForm.labelStyle.fontSize",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "row" },
                      [
                        _c("div", { staticClass: "label" }, [
                          _vm._v("参数值字号:"),
                        ]),
                        _c("el-input", {
                          attrs: { type: "number" },
                          model: {
                            value: _vm.newForm.style.fontSize,
                            callback: function ($$v) {
                              _vm.$set(_vm.newForm.style, "fontSize", $$v)
                            },
                            expression: "newForm.style.fontSize",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "row" },
                      [
                        _c("div", { staticClass: "label" }, [_vm._v("行高:")]),
                        _c("el-input", {
                          attrs: { type: "number" },
                          model: {
                            value: _vm.newForm.style.lineHeight,
                            callback: function ($$v) {
                              _vm.$set(_vm.newForm.style, "lineHeight", $$v)
                            },
                            expression: "newForm.style.lineHeight",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "row" },
                      [
                        _c("div", { staticClass: "label" }, [
                          _vm._v("字体粗细:"),
                        ]),
                        _c(
                          "el-select",
                          {
                            attrs: { placeholder: "请选择" },
                            model: {
                              value: _vm.newForm.style.fontWeight,
                              callback: function ($$v) {
                                _vm.$set(_vm.newForm.style, "fontWeight", $$v)
                              },
                              expression: "newForm.style.fontWeight",
                            },
                          },
                          _vm._l(_vm.options.fontWeight, function (item) {
                            return _c("el-option", {
                              key: item.label,
                              attrs: { label: item.label, value: item.value },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "row" },
                      [
                        _c("div", { staticClass: "label" }, [
                          _vm._v("字体风格:"),
                        ]),
                        _c(
                          "el-select",
                          {
                            attrs: { placeholder: "请选择" },
                            model: {
                              value: _vm.newForm.style.fontStyle,
                              callback: function ($$v) {
                                _vm.$set(_vm.newForm.style, "fontStyle", $$v)
                              },
                              expression: "newForm.style.fontStyle",
                            },
                          },
                          _vm._l(_vm.options.fontStyle, function (item) {
                            return _c("el-option", {
                              key: item.value,
                              attrs: { label: item.label, value: item.value },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                    _c("div", { staticClass: "row pad" }, [
                      _c("div", { staticClass: "label" }, [_vm._v("内边距:")]),
                      _c("div", { staticClass: "pads" }, [
                        _c(
                          "div",
                          { staticClass: "line" },
                          [
                            _vm._v(" 左内边距"),
                            _c("el-input", {
                              attrs: { type: "number" },
                              model: {
                                value: _vm.newForm.style.paddingLeft,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.newForm.style,
                                    "paddingLeft",
                                    $$v
                                  )
                                },
                                expression: "newForm.style.paddingLeft",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "line" },
                          [
                            _vm._v(" 上内边距"),
                            _c("el-input", {
                              attrs: { type: "number" },
                              model: {
                                value: _vm.newForm.style.paddingTop,
                                callback: function ($$v) {
                                  _vm.$set(_vm.newForm.style, "paddingTop", $$v)
                                },
                                expression: "newForm.style.paddingTop",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "line" },
                          [
                            _vm._v(" 右内边距"),
                            _c("el-input", {
                              attrs: { type: "number" },
                              model: {
                                value: _vm.newForm.style.paddingRight,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.newForm.style,
                                    "paddingRight",
                                    $$v
                                  )
                                },
                                expression: "newForm.style.paddingRight",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "line" },
                          [
                            _vm._v(" 下内边距"),
                            _c("el-input", {
                              attrs: { type: "number" },
                              model: {
                                value: _vm.newForm.style.paddingBottom,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.newForm.style,
                                    "paddingBottom",
                                    $$v
                                  )
                                },
                                expression: "newForm.style.paddingBottom",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ]),
                    _c("div", { staticClass: "row mar" }, [
                      _c("div", { staticClass: "label" }, [_vm._v("外边距:")]),
                      _c("div", { staticClass: "mars" }, [
                        _c(
                          "div",
                          { staticClass: "line" },
                          [
                            _vm._v(" 左外边距"),
                            _c("el-input", {
                              attrs: { type: "number" },
                              model: {
                                value: _vm.newForm.style.marginLeft,
                                callback: function ($$v) {
                                  _vm.$set(_vm.newForm.style, "marginLeft", $$v)
                                },
                                expression: "newForm.style.marginLeft",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "line" },
                          [
                            _vm._v(" 上外边距"),
                            _c("el-input", {
                              attrs: { type: "number" },
                              model: {
                                value: _vm.newForm.style.marginTop,
                                callback: function ($$v) {
                                  _vm.$set(_vm.newForm.style, "marginTop", $$v)
                                },
                                expression: "newForm.style.marginTop",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "line" },
                          [
                            _vm._v(" 右外边距"),
                            _c("el-input", {
                              attrs: { type: "number" },
                              model: {
                                value: _vm.newForm.style.marginRight,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.newForm.style,
                                    "marginRight",
                                    $$v
                                  )
                                },
                                expression: "newForm.style.marginRight",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "line" },
                          [
                            _vm._v(" 下外边距"),
                            _c("el-input", {
                              attrs: { type: "number" },
                              model: {
                                value: _vm.newForm.style.marginBottom,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.newForm.style,
                                    "marginBottom",
                                    $$v
                                  )
                                },
                                expression: "newForm.style.marginBottom",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ]),
                    _c(
                      "div",
                      { staticClass: "row" },
                      [
                        _c("div", { staticClass: "label" }, [_vm._v("位置:")]),
                        _c(
                          "el-radio-group",
                          {
                            model: {
                              value: _vm.newForm.style.textAlign,
                              callback: function ($$v) {
                                _vm.$set(_vm.newForm.style, "textAlign", $$v)
                              },
                              expression: "newForm.style.textAlign",
                            },
                          },
                          _vm._l(_vm.options.textAlign, function (item) {
                            return _c(
                              "el-radio",
                              { key: item.value, attrs: { label: item.value } },
                              [_vm._v(_vm._s(item.label))]
                            )
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                : ["goods", "goods2"].includes(_vm.newForm.type) &&
                  _vm.newForm.children
                ? [
                    _vm._l(_vm.newForm.children, function (item, index) {
                      return _c(
                        "div",
                        { key: item.name, staticClass: "row" },
                        [
                          _c("div", { staticClass: "label" }, [
                            _vm._v(_vm._s(item.label) + ":"),
                          ]),
                          _c("el-switch", {
                            model: {
                              value: item.show,
                              callback: function ($$v) {
                                _vm.$set(item, "show", $$v)
                              },
                              expression: "item.show",
                            },
                          }),
                          _vm.newForm.type === "goods"
                            ? _c("el-input", {
                                staticStyle: { width: "100px" },
                                attrs: { type: "number", placeholder: "占比" },
                                model: {
                                  value: item.style.width,
                                  callback: function ($$v) {
                                    _vm.$set(item.style, "width", $$v)
                                  },
                                  expression: "item.style.width",
                                },
                              })
                            : _vm._e(),
                          _c("el-input", {
                            staticStyle: { width: "150px" },
                            model: {
                              value: item.label,
                              callback: function ($$v) {
                                _vm.$set(item, "label", $$v)
                              },
                              expression: "item.label",
                            },
                          }),
                        ],
                        1
                      )
                    }),
                    _c(
                      "div",
                      { staticClass: "row" },
                      [
                        _c("div", { staticClass: "label" }, [_vm._v("字体:")]),
                        _c(
                          "el-select",
                          {
                            attrs: { placeholder: "请选择" },
                            model: {
                              value: _vm.newForm.style.fontFamily,
                              callback: function ($$v) {
                                _vm.$set(_vm.newForm.style, "fontFamily", $$v)
                              },
                              expression: "newForm.style.fontFamily",
                            },
                          },
                          _vm._l(_vm.options.fontFamily, function (item) {
                            return _c("el-option", {
                              key: item,
                              attrs: { label: item, value: item },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "row" },
                      [
                        _c("div", { staticClass: "label" }, [
                          _vm._v("参数名字号:"),
                        ]),
                        _c("el-input", {
                          attrs: { type: "number" },
                          model: {
                            value: _vm.newForm.labelStyle.fontSize,
                            callback: function ($$v) {
                              _vm.$set(_vm.newForm.labelStyle, "fontSize", $$v)
                            },
                            expression: "newForm.labelStyle.fontSize",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "row" },
                      [
                        _c("div", { staticClass: "label" }, [
                          _vm._v("参数值字号:"),
                        ]),
                        _c("el-input", {
                          attrs: { type: "number" },
                          model: {
                            value: _vm.newForm.style.fontSize,
                            callback: function ($$v) {
                              _vm.$set(_vm.newForm.style, "fontSize", $$v)
                            },
                            expression: "newForm.style.fontSize",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "row" },
                      [
                        _c("div", { staticClass: "label" }, [_vm._v("行高:")]),
                        _c("el-input", {
                          attrs: { type: "number" },
                          model: {
                            value: _vm.newForm.style.lineHeight,
                            callback: function ($$v) {
                              _vm.$set(_vm.newForm.style, "lineHeight", $$v)
                            },
                            expression: "newForm.style.lineHeight",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "row" },
                      [
                        _c("div", { staticClass: "label" }, [
                          _vm._v("字体粗细:"),
                        ]),
                        _c(
                          "el-select",
                          {
                            attrs: { placeholder: "请选择" },
                            model: {
                              value: _vm.newForm.style.fontWeight,
                              callback: function ($$v) {
                                _vm.$set(_vm.newForm.style, "fontWeight", $$v)
                              },
                              expression: "newForm.style.fontWeight",
                            },
                          },
                          _vm._l(_vm.options.fontWeight, function (item) {
                            return _c("el-option", {
                              key: item.label,
                              attrs: { label: item.label, value: item.value },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "row" },
                      [
                        _c("div", { staticClass: "label" }, [
                          _vm._v("字体风格:"),
                        ]),
                        _c(
                          "el-select",
                          {
                            attrs: { placeholder: "请选择" },
                            model: {
                              value: _vm.newForm.style.fontStyle,
                              callback: function ($$v) {
                                _vm.$set(_vm.newForm.style, "fontStyle", $$v)
                              },
                              expression: "newForm.style.fontStyle",
                            },
                          },
                          _vm._l(_vm.options.fontStyle, function (item) {
                            return _c("el-option", {
                              key: item.value,
                              attrs: { label: item.label, value: item.value },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "row" },
                      [
                        _c("div", { staticClass: "label" }, [_vm._v("位置:")]),
                        _c(
                          "el-radio-group",
                          {
                            model: {
                              value: _vm.newForm.style.textAlign,
                              callback: function ($$v) {
                                _vm.$set(_vm.newForm.style, "textAlign", $$v)
                              },
                              expression: "newForm.style.textAlign",
                            },
                          },
                          _vm._l(_vm.options.textAlign, function (item) {
                            return _c(
                              "el-radio",
                              { key: item.value, attrs: { label: item.value } },
                              [_vm._v(_vm._s(item.label))]
                            )
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                : _vm.newForm.type === "img"
                ? [
                    !_vm.newForm.disable
                      ? _c(
                          "div",
                          {
                            staticClass: "row",
                            staticStyle: { height: "180px" },
                          },
                          [
                            _c("div", { staticClass: "label" }, [
                              _vm._v(_vm._s(_vm.newForm.label)),
                            ]),
                            _c("SingleImageUpload", {
                              attrs: {
                                fileList: _vm.newForm.value
                                  ? [{ url: _vm.newForm.value }]
                                  : [],
                              },
                              on: {
                                getImageUrl: function ($event) {
                                  return _vm.handleImageUrl($event)
                                },
                                delImage: function ($event) {
                                  return _vm.handleImageUrl()
                                },
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]
                : _vm._e(),
              _vm.newForm.type
                ? _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "label" }, [_vm._v("操作:")]),
                    _c(
                      "span",
                      {
                        staticStyle: { color: "#1890ff", cursor: "pointer" },
                        on: {
                          click: function ($event) {
                            return _vm.$emit("delParameter")
                          },
                        },
                      },
                      [_vm._v("删除参数")]
                    ),
                  ])
                : _vm._e(),
            ],
            2
          )
        : _c("div", { staticClass: "tip" }, [_vm._v("无编辑的内容")]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }