<template>
  <div class="wrap">
    <TablePage ref="tablePage" v-model="options" @handleEvent="handleEvent" />
  </div>
</template>
<script>
import TablePage from '@/components/tablePage'
import { commissionListAPI, commissionListAPISummary } from '@/api/shop/saleGuide/query' //导购员查询
export default {
  name: 'CommissionQuery',
  components: { TablePage },
  data() {
    return {
      options: {
        listNo: true,
        loading: true,
        pagination: {
          total: 0,
          page: 1,
          size: 15
        },
        body: {},
        getListApi: commissionListAPI,
        getSummaryApi: commissionListAPISummary,
        exportOption: {
          fastExportUrl: '/api/system/shop/guideMan/query/commissionQueryExport',
          exportName: '导购员提成查询'
        },
        title: '导购员提成查询',
        search: [
          {
            label: '单据日期',
            type: 'datetimerange',
            model: '',
            filterFnc: ([a, b] = []) => ({ beginBillDate: a, endBillDate: b })
          },
          {
            keyIndex: 0,
            format: 'datetimerange',
            type: 'timeRange',
            defaultValue: 'day',
            data: ['day', 'yesterday', 'week', 'lastWeek', 'month', 'lastMonth']
          },
          {
            type: 'filters',
            tip: '全部/商品编码/商品名称',
            isRestore: true,
            model: '',
            filters: [
              { filter: 'query', label: '全部' },
              { filter: 'shopNos', label: '门店编号' },
              { filter: 'shopNames', label: '门店名称' },
              { filter: 'guideManNos', label: '导购员编号' },
              { filter: 'guideManNames', label: '导购员名称' }
            ]
          },
          this.$select({
            key: 'listShop',
            option: {
              filter: 'shopIds',
              seniorSearch: true,
              option: {
                multiple: true,
                buttons: [
                  {
                    type: 'more',
                    option: {
                      title: '选择门店',
                      width: 1250,
                      type: 'TreeAndTable',
                      formData: this.$dialog({ key: 'shop' })
                    }
                  }
                ]
              }
            }
          }),
          this.$select({
            key: 'listGuideMan',
            option: {
              filter: 'guideManIds',
              seniorSearch: true,
              option: {
                multiple: true,
                buttons: [
                  {
                    type: 'more',
                    option: {
                      title: '选择导购员',
                      width: 1250,
                      type: 'TreeAndTable',
                      formData: this.$dialog({ key: 'guideMan' })
                    }
                  }
                ]
              }
            }
          }),
          { type: 'button', tip: '查询', btnType: 'primary', click: 'search' },
          {
            type: 'button',
            tip: '高级查询',
            btnType: 'primary',
            click: 'seniorSearch'
          },
          { type: 'button', tip: '重置', click: 'reset' }
        ],
        columns: [
          { label: '门店编号', prop: 'shopNo', minWidth: 180 },
          { label: '门店名称', prop: 'shopName', minWidth: 160 },
          { label: '导购员编号', prop: 'guideManNo', minWidth: 160 },
          { label: '导购员名称', prop: 'guideManName', minWidth: 100 },
          { label: '充值单数', prop: 'fillCount', minWidth: 100 },
          { label: '充值金额', prop: 'fillMoney', minWidth: 100 },
          { label: '充值提成金额', prop: 'fillCommissionMoney', minWidth: 100 },
          { label: '销售数量', prop: 'saleQty', minWidth: 100 },
          { label: '销售金额', prop: 'saleMoney', minWidth: 100 },
          { label: '销售提成金额', prop: 'saleCommissionMoney', minWidth: 100 },
        ],
        summary: [
          'fillCount',
          'fillMoney',
          'fillCommissionMoney',
          'saleQty',
          'saleMoney',
          'saleCommissionMoney',
        ]
      }
    }
  },
  methods: {
    async handleEvent(type, row) {
      switch (type) {
        case 'oepnDetail': //获取点击商品信息

          break
        default:
          break
      }
    }
  }
}
</script>
<style lang="scss" scoped>
</style>
