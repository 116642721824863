<template>
  <!-- 模板预览弹窗 -->
  <div class="templatePreviewDialog">
    <el-dialog
      :visible="openDialog"
      title="模板预览"
      :before-close="close"
      v-dialogDrag="true"
      :modal="false"
      :width="dialogWidth"
    >
      <el-scrollbar>
        <div class="content">
          <div class="edit-header">{{ repReceiptInfo.formatHeadCaption }}</div>
          <div class="edit-textarea">
            <pre
              v-html="repReceiptInfo.formatContent"
              style="white-space: pre-wrap"
            ></pre>
          </div>
          <div class="edit-footer">
            {{ repReceiptInfo.formatBottomCaption }}
          </div>
        </div>
      </el-scrollbar>
    </el-dialog>
  </div>
</template>

<script>
import { itmes } from "@/utils/index.js"; //实时时间
export default {
  name: "TemplatePreviewDialog",
  props: {
    openDialog: {
      type: Boolean,
      default: false,
    },
    //小票模板
    receiptFormat: {
      type: Object,
    },
    //设置模板类型
    setTemplateType: {
      type: String,
    },
  },
  watch: {
    // 侦听传过来的小票信息
    receiptFormat: {
      handler(val) {
        this.repReceiptInfo.formatHeadCaption = val.formatHeadCaption;
        this.repReceiptInfo.formatBottomCaption = val.formatBottomCaption;
        this.repReceiptInfo.formatMachineType = val.formatMachineType;
        this.repReceiptInfo.formatLogoUrl = val.formatLogoUrl;
        this.repReceiptInfo.formatQrCodeUrl = val.formatQrCodeUrl;
        this.repReceiptInfo.isCurrentUseFormat = val.isCurrentUseFormat;
        this.repReceiptInfo.isCurrentUseFormat = val.isCurrentUseFormat;
        if (val.formatMachineType === 58) this.dialogWidth = "260px";
        if (val.formatMachineType === 80) this.dialogWidth = "424px";
        if (val.formatMachineType === 110) this.dialogWidth = "536px";
        if (val.formatContent) {
          let dateTime = itmes(new Date());
          dateTime = dateTime.timeTextFormatOne;
          //判断不同模板类型 替换数据
          if (this.setTemplateType == "cashier") {
            //收银小票
            //替换真实数据展示
            this.repReceiptInfo.formatContent = val.formatContent
              .replace(new RegExp("#item", "g"), "")
              .replace(new RegExp("#{门店名称}", "g"), "隼云科技")
              .replace(new RegExp("#{门店地址}", "g"), "金宝大厦8408")
              .replace(new RegExp("#{联系电话}", "g"), "86612345")
              .replace(new RegExp("#{收银员姓名}", "g"), "陈陈陈")
              .replace(new RegExp("#{收银员编号}", "g"), "085")
              .replace(new RegExp("#{导购员}", "g"), "林林林")
              .replace(new RegExp("#{导购员编号}", "g"), "095")
              .replace(new RegExp("#{小票号}", "g"), "No123456")
              .replace(new RegExp("#{支付方式}", "g"), "<div>隼云付:128</div>")
              .replace(new RegExp("#{总计}", "g"), "100")
              .replace(new RegExp("#{件数}", "g"), "5")
              .replace(new RegExp("#{应收}", "g"), "88")
              .replace(new RegExp("#{原价}", "g"), "200")
              .replace(new RegExp("#{整单折扣}", "g"), "0.8折")
              .replace(new RegExp("#{实收}", "g"), "100")
              .replace(new RegExp("#{收款}", "g"), "100")
              .replace(new RegExp("#{找零}", "g"), "30")
              .replace(new RegExp("#{优惠}", "g"), "18")
              .replace(new RegExp("#{商品编码}", "g"), "866")
              .replace(new RegExp("#{条码}", "g"), "066")
              .replace(new RegExp("#{商品名称}", "g"), "西瓜")
              .replace(new RegExp("#{单位}", "g"), "个")
              .replace(new RegExp("#{规格}", "g"), "500g")
              .replace(new RegExp("#{加值属性}", "g"), "加糖")
              .replace(new RegExp("#{数量}", "g"), "5")
              .replace(new RegExp("#{单价}", "g"), "18")
              .replace(new RegExp("#{售价}", "g"), "16")
              .replace(new RegExp("#{金额}", "g"), "88")
              .replace(new RegExp("#{日期时间}", "g"), dateTime)
              .replace(new RegExp("#{会员姓名}", "g"), "陈陈陈")
              .replace(new RegExp("#{会员号}", "g"), "123456")
              .replace(new RegExp("#{会员等级}", "g"), "普通会员")
              .replace(new RegExp("#{手机号}", "g"), "15815056789")
              .replace(new RegExp("#{会员卡本次消费金额}", "g"), "100")
              .replace(new RegExp("#{消费后余额}", "g"), "100")
              .replace(new RegExp("#{消费前余额}", "g"), "200")
              .replace(new RegExp("#{积分}", "g"), "100")
              .replace(new RegExp("#{本次积分}", "g"), "100")
              .replace(new RegExp("#{消耗积分}", "g"), "0")
              .replace(new RegExp("#{打印时间}", "g"), "2024-05-03 19:48:00")
              .replace(new RegExp("#{取餐码}", "g"), "00001")
              .replace(
                "#IMG{图片1}",
                `<img style="width:120px;height:120px" src="${val.formatLogoUrl}"/>`
              )
              .replace(
                "#IMG{图片2}",
                `<img style="width:200px;height:200px" src="${val.formatQrCodeUrl}"/>`
              );
          }
          if (this.setTemplateType == "kitchenPrint") {
            //厨打小票
            //替换真实数据展示
            this.repReceiptInfo.formatContent = val.formatContent
              .replace(new RegExp("#{打印机名称}", "g"), "厨房打印机")
              .replace(new RegExp("#{手工单据号}", "g"), "NO001")
              .replace(new RegExp("#{磅数}", "g"), "1磅")
              .replace(new RegExp("#{尺寸}", "g"), "8寸")
              .replace(new RegExp("#{样式}", "g"), "样式1")
              .replace(new RegExp("#{商品编码}", "g"), "866")
              .replace(new RegExp("#{条码}", "g"), "066")
              .replace(new RegExp("#{商品名称}", "g"), "西瓜")
              .replace(new RegExp("#{单位}", "g"), "个")
              .replace(new RegExp("#{规格}", "g"), "500g")
              .replace(new RegExp("#{加值属性}", "g"), "加糖")
              .replace(new RegExp("#{数量}", "g"), "5")
              .replace(new RegExp("#{单价}", "g"), "18")
              .replace(new RegExp("#{售价}", "g"), "16")
              .replace(new RegExp("#{金额}", "g"), "88")
              .replace(new RegExp("#{备注}", "g"), "多加叉子")
              .replace(new RegExp("#{下单时间}", "g"), dateTime)
              .replace(new RegExp("#{订单来源}", "g"), "喜洋洋门店")
              .replace(new RegExp("#{配送地址}", "g"), "配送地址")
              .replace(new RegExp("#{收货人}", "g"), "陈陈陈")
              .replace(new RegExp("#{电话}", "g"), "13888888888")
              .replace(new RegExp("#{打印时间}", "g"), dateTime)
              .replace(new RegExp("#{流水号}", "g"), "00001")
              .replace(new RegExp("#{预约时间}", "g"), dateTime)
              .replace(new RegExp("#{取货时间}", "g"), "2024-07-02 08:30-09:00")
              .replace(new RegExp("#{配送时间}", "g"), "2024-07-02 08:30-09:00")
              .replace(new RegExp("#{订单号}", "g"), "NO0022023080300001")
              .replace(new RegExp("#{取货门店}", "g"), "喜洋洋门店")
              .replace(new RegExp("#{配送门店}", "g"), "喜洋洋门店")
              .replace(new RegExp("#{配送方式}", "g"), "同城配送")
              .replace(new RegExp("#{门店}", "g"), "喜洋洋门店")
              .replace(new RegExp("#{预订门店}", "g"), "喜洋洋门店")
              .replace(new RegExp("#{桌台号}", "g"), "001")
              .replace(new RegExp("#{桌台名称}", "g"), "1桌1号")
              .replace(new RegExp("#{就餐方式}", "g"), "堂食")
              .replace(new RegExp("#{打印时间}", "g"), "2024-05-03 19:48:00")
              .replace(new RegExp("#{取餐码}", "g"), "00001")
              .replace(new RegExp("#{核销码}", "g"), "核销码")
              .replace(new RegExp("#item", "g"), "");
          }
          if (this.setTemplateType == "orderMake") {
            //订单打印
            //替换真实数据展示
            this.repReceiptInfo.formatContent = val.formatContent
              .replace(new RegExp("#{打印机名称}", "g"), "厨房打印机")
              .replace(new RegExp("#{手工单据号}", "g"), "NO001")
              .replace(new RegExp("#{磅数}", "g"), "1磅")
              .replace(new RegExp("#{尺寸}", "g"), "8寸")
              .replace(new RegExp("#{样式}", "g"), "样式1")
              .replace(new RegExp("#{订单号}", "g"), "NO0022023080300001")
              .replace(new RegExp("#{餐台号}", "g"), "A01")
              .replace(new RegExp("#{流水号}", "g"), "00001")
              .replace(new RegExp("#{订单来源}", "g"), "喜洋洋门店")
              .replace(new RegExp("#{打印时间}", "g"), dateTime)
              .replace(new RegExp("#{商品编码}", "g"), "866")
              .replace(new RegExp("#{条码}", "g"), "066")
              .replace(new RegExp("#{商品名称}", "g"), "西瓜")
              .replace(new RegExp("#{单位}", "g"), "个")
              .replace(new RegExp("#{规格}", "g"), "500g")
              .replace(new RegExp("#{加值属性}", "g"), "加糖")
              .replace(new RegExp("#{数量}", "g"), "5")
              .replace(new RegExp("#{单价}", "g"), "18")
              .replace(new RegExp("#{售价}", "g"), "16")
              .replace(new RegExp("#{金额}", "g"), "88")
              .replace(new RegExp("#{支付方式}", "g"), "<div>隼云付:88</div>")
              .replace(new RegExp("#{收货人}", "g"), "陈**")
              .replace(new RegExp("#{门店电话}", "g"), "86686688")
              .replace(new RegExp("#{预定门店}", "g"), "喜洋洋门店")
              .replace(new RegExp("#{预约时间}", "g"), dateTime)
              .replace(new RegExp("#{取货门店}", "g"), "喜洋洋门店")
              .replace(new RegExp("#{取货时间}", "g"), dateTime)
              .replace(new RegExp("#{配送门店}", "g"), "喜洋洋门店")
              .replace(new RegExp("#{配送方式}", "g"), "同城配送")
              .replace(new RegExp("#{取货方式}", "g"), "门店自提")
              .replace(new RegExp("#{配送时间}", "g"), dateTime)
              .replace(new RegExp("#{联系电话}", "g"), "136123456789")
              .replace(new RegExp("#{送货地址}", "g"), "宝安区灵芝地铁口")
              .replace(new RegExp("#{预付款}", "g"), "28")
              .replace(new RegExp("#{余款}", "g"), "100")
              .replace(new RegExp("#{备注}", "g"), "多放草莓")
              .replace(new RegExp("#{打印时间}", "g"), "2024-05-03 19:48:00")
              .replace(new RegExp("#{取餐码}", "g"), "00001")
              .replace(new RegExp("#{核销码}", "g"), "核销码")
              .replace(new RegExp("#item", "g"), "");
          }
          if (this.setTemplateType == "delivery") {
            //配送小票
            //替换真实数据展示
            this.repReceiptInfo.formatContent = val.formatContent
              .replace(new RegExp("#{订单号}", "g"), "NO0022023080300001")
              .replace(new RegExp("#{订单来源}", "g"), "美团")
              .replace(new RegExp("#{配送时间}", "g"), dateTime)
              .replace(new RegExp("#{流水号}", "g"), "888")
              .replace(new RegExp("#{取货时间}", "g"), dateTime)
              .replace(new RegExp("#{取货门店}", "g"), "喜洋洋门店")
              .replace(new RegExp("#{配送方式}", "g"), "商家配送")
              .replace(new RegExp("#{配送门店}", "g"), "喜洋洋门店")
              .replace(new RegExp("#{下单时间}", "g"), dateTime)
              .replace(new RegExp("#{打印时间}", "g"), dateTime)
              .replace(new RegExp("#{商品编码}", "g"), "866")
              .replace(new RegExp("#{条码}", "g"), "066")
              .replace(new RegExp("#{商品名称}", "g"), "西瓜")
              .replace(new RegExp("#{单位}", "g"), "个")
              .replace(new RegExp("#{规格}", "g"), "500g")
              .replace(new RegExp("#{加值属性}", "g"), "加糖")
              .replace(new RegExp("#{数量}", "g"), "5")
              .replace(new RegExp("#{单价}", "g"), "18")
              .replace(new RegExp("#{售价}", "g"), "16")
              .replace(new RegExp("#{金额}", "g"), "88")
              .replace(new RegExp("#{备注}", "g"), "多放草莓")
              .replace(new RegExp("#{配送费}", "g"), "12")
              .replace(new RegExp("#{打包费}", "g"), "1")
              .replace(new RegExp("#{合计金额}", "g"), "128")
              .replace(new RegExp("#{支付方式}", "g"), "<div>隼云付:128</div>")
              .replace(new RegExp("#{收货人}", "g"), "陈**")
              .replace(new RegExp("#{电话}", "g"), "15812366077")
              .replace(new RegExp("#{收货地址}", "g"), "宝安区灵芝地铁口")
              .replace(new RegExp("#{打印时间}", "g"), "2024-05-03 19:48:00")
              .replace(new RegExp("#{取餐码}", "g"), "00001")
              .replace(new RegExp("#item", "g"), "");
          }
        }
      },
      immediate: true,
      deep: true,
    },
  },
  data() {
    return {
      //弹窗宽度
      dialogWidth: 0,
      // 小票信息替换真实数据
      repReceiptInfo: {
        formatHeadCaption: "隼云烘焙", //头部标题
        formatContent: "", //小票格式内容
        formatBottomCaption: "欢迎光临", //底部标语
        formatMachineType: 58, //小票规格类型
        formatLogoUrl: undefined, //Logo图片
        formatQrCodeUrl: undefined, //二维码图片
        isCurrentUseFormat: false, //是否当前使用模板
        isCurrentUseFormat: false, //是否当前使用模板
      },
    };
  },
  methods: {
    //关闭对话框
    close() {
      this.$emit("update:openDialog", false);
    },
  },
};
</script>

<style lang="scss" scoped>
.templatePreviewDialog {
  ::v-deep .el-dialog__wrapper {
    pointer-events: none;
  }
  ::v-deep .el-dialog {
    pointer-events: auto;
    max-height: 680px;
  }
  ::v-deep .el-dialog__header {
    background-color: #fff;
    border-bottom: 1px solid #eeeeee;
  }
  ::v-deep .el-dialog__body {
    background-color: #fcfcfc;
    padding: 20px 0;
    height: 680px;
  }
  //滚动条
  .el-scrollbar {
    height: 100%;
    width: 100%;
    overflow: hidden;
    ::v-deep .el-scrollbar__wrap {
      overflow-x: hidden;
    }
  }
  .content {
    padding: 24px;
    margin: 0 20px;
    background-color: #fff;
    font-size: 13px;
    .edit-textarea {
      background-color: #fff;
      ::v-deep .el-textarea__inner {
        overflow: hidden;
        border: none;
        resize: none;
        color: #000 !important;
        font-size: 14px;
        padding: 0;
      }
    }
  }
}
</style>
