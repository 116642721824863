<template>
  <div class="wrap">
    <TablePage ref="tablePage" v-model="options" @handleEvent="handleEvent" />
  </div>
</template>
<script>
import TablePage from '@/components/tablePage'
import { rechargeListAPI, rechargeListAPISummary } from '@/api/shop/saleGuide/query' //导购员查询
export default {
  name: 'FillQuery',
  components: { TablePage },
  data() {
    return {
      options: {
        listNo: true,
        loading: true,
        pagination: {
          total: 0,
          page: 1,
          size: 15
        },
        body: {},
        getListApi: rechargeListAPI,
        getSummaryApi: rechargeListAPISummary,
        exportOption: {
          fastExportUrl: '/api/system/shop/guideMan/query/fillQueryExport',
          exportName: '导购员充值查询'
        },
        title: '导购员充值查询',
        search: [
          {
            label: '单据日期',
            type: 'datetimerange',
            model: '',
            filterFnc: ([a, b] = []) => ({ beginBillDate: a, endBillDate: b })
          },
          {
            keyIndex: 0,
            format: 'datetimerange',
            type: 'timeRange',
            defaultValue: 'day',
            data: ['day', 'yesterday', 'week', 'lastWeek', 'month', 'lastMonth']
          },
          {
            type: 'filters',
            tip: '全部/商品编码/商品名称',
            isRestore: true,
            model: '',
            filters: [
              { filter: 'query', label: '全部' },
              { filter: 'shopNos', label: '门店编号' },
              { filter: 'shopNames', label: '门店名称' },
              { filter: 'guideManNos', label: '导购员编号' },
              { filter: 'guideManNames', label: '导购员名称' }
            ]
          },
          this.$select({
            key: 'listShop',
            option: {
              filter: 'shopIds',
              seniorSearch: true,
              option: {
                multiple: true,
                buttons: [
                  {
                    type: 'more',
                    option: {
                      title: '选择门店',
                      width: 1250,
                      type: 'TreeAndTable',
                      formData: this.$dialog({ key: 'shop' })
                    }
                  }
                ]
              }
            }
          }),
          this.$select({
            key: 'listGuideMan',
            option: {
              filter: 'guideManIds',
              seniorSearch: true,
              option: {
                multiple: true,
                buttons: [
                  {
                    type: 'more',
                    option: {
                      title: '选择导购员',
                      width: 1250,
                      type: 'TreeAndTable',
                      formData: this.$dialog({ key: 'guideMan' })
                    }
                  }
                ]
              }
            }
          }),
          { type: 'button', tip: '查询', btnType: 'primary', click: 'search' },
          {
            type: 'button',
            tip: '高级查询',
            btnType: 'primary',
            click: 'seniorSearch'
          },
          { type: 'button', tip: '重置', click: 'reset' }
        ],
        columns: [
          { label: '充值小票号', prop: 'fillBillNo', minWidth: 120 },
          { label: '日期', prop: 'fillTime', minWidth: 120 },
          { label: '门店编号', prop: 'shopNo', minWidth: 120 },
          { label: '门店名称', prop: 'shopName', minWidth: 160 },
          { label: '导购员编号', prop: 'guideManNo', minWidth: 120 },
          { label: '导购员名称', prop: 'guideManName', minWidth: 160 },
          { label: '级别编码', prop: 'vipLevelNo', minWidth: 120 },
          { label: '级别名称', prop: 'vipLevelName', minWidth: 120 },
          { label: '会员卡号', prop: 'vipNo', minWidth: 180 },
          { label: '会员名称', prop: 'vipName', minWidth: 120 },
          { label: '手机号', prop: 'tel', minWidth: 120 },
          { label: '充值金额', prop: 'fillMoney', minWidth: 100 },
          { label: '赠送金额', prop: 'largessMoney', minWidth: 100 },
          {
            prop: 'payTypes',
            align: 'center',
            label: '收款方式',
            children: () =>
              this.options.list?.[0]?.payTypes?.map?.(x => ({
                ...x,
                label: x.value,
                prop: `payDetailList.${x.payMode}.payModeId.payMoney`,
                minWidth: 110,
                summary: true,
                getValue: (row, prop) =>
                  row.payDetailList?.find(y => y.payModeId === x.payMode)
                    ?.payMoney || 0
              })) || []
          },
          { label: '提成比例 (%) ', prop: 'guideManCommissionRate', minWidth: 100 },
          { label: '提成金额', prop: 'guideManCommissionMoney', minWidth: 100 },
          { label: '充值类型', prop: 'fillTypeName', minWidth: 100 },
        ],
        summary: [
          'fillMoney',
          'largessMoney',
          'guideManCommissionMoney',
        ]
      }
    }
  },
  methods: {
    async handleEvent(type, row) {
      switch (type) {
        case 'oepnDetail': //获取点击商品信息

          break
        default:
          break
      }
    }
  }
}
</script>
<style lang="scss" scoped>
</style>
