var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wrap" },
    [
      _c("topOperatingButton", {
        attrs: {
          isSubmitAddBtn: false,
          isAuditBillBtn: false,
          isQuitBtn: false,
        },
        on: { submitForm: _vm.submitForm },
      }),
      _c("cardTitleCom", {
        staticClass: "bg-Color marT10",
        attrs: { cardTitle: "会员密码设置" },
        scopedSlots: _vm._u([
          {
            key: "cardContent",
            fn: function () {
              return [
                _c("div", { staticClass: "marT15" }, [
                  _c("span", { staticClass: "padd15" }, [
                    _vm._v(
                      "当会员没有设置密码时,若您希望启用系统默认的缺省密码,请勾选"
                    ),
                  ]),
                  _c(
                    "div",
                    { staticClass: "x-f" },
                    [
                      _c(
                        "el-checkbox",
                        {
                          staticClass: "padd15",
                          attrs: { "true-label": "Y", "false-label": "N" },
                          model: {
                            value: _vm.form.shop_VIP_CONFIG_PASSWORD_11,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.form,
                                "shop_VIP_CONFIG_PASSWORD_11",
                                $$v
                              )
                            },
                            expression: "form.shop_VIP_CONFIG_PASSWORD_11",
                          },
                        },
                        [_vm._v("启用缺省密码")]
                      ),
                      _vm.form.shop_VIP_CONFIG_PASSWORD_11 == "Y"
                        ? _c(
                            "div",
                            { staticClass: "marL20" },
                            [
                              _c("span", { staticClass: "marR5" }, [
                                _vm._v("缺省密码"),
                              ]),
                              _c("el-input", {
                                staticClass: "inputWidth underline-input",
                                attrs: { size: "mini", type: "number" },
                                model: {
                                  value: _vm.form.shop_VIP_CONFIG_PASSWORD_14,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.form,
                                      "shop_VIP_CONFIG_PASSWORD_14",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "form.shop_VIP_CONFIG_PASSWORD_14",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ]),
                _c("div", { staticClass: "marT5 marB15 x-f" }, [
                  _c(
                    "div",
                    [
                      _c("span", { staticClass: "padd15" }, [
                        _vm._v("会员启用消费密码"),
                      ]),
                      _c("el-switch", {
                        staticClass: "marL20",
                        attrs: { "active-value": "Y", "inactive-value": "N" },
                        model: {
                          value: _vm.form.shop_VIP_CONFIG_PASSWORD_12,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.form,
                              "shop_VIP_CONFIG_PASSWORD_12",
                              $$v
                            )
                          },
                          expression: "form.shop_VIP_CONFIG_PASSWORD_12",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm.form.shop_VIP_CONFIG_PASSWORD_12 == "Y"
                    ? _c(
                        "div",
                        { staticClass: "marL20" },
                        [
                          _c("span", { staticClass: "marR5" }, [
                            _vm._v("初始密码"),
                          ]),
                          _c("el-input", {
                            staticClass: "inputWidth underline-input",
                            attrs: { size: "mini", type: "number" },
                            model: {
                              value: _vm.form.shop_VIP_CONFIG_PASSWORD_15,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.form,
                                  "shop_VIP_CONFIG_PASSWORD_15",
                                  $$v
                                )
                              },
                              expression: "form.shop_VIP_CONFIG_PASSWORD_15",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ]),
                _c(
                  "div",
                  { staticClass: "marL15 marB15" },
                  [
                    _c("span", [_vm._v("开启储值卡免密支付，免密额度")]),
                    _c("el-input", {
                      staticClass: "inputWidth underline-input padd5",
                      attrs: { size: "mini", type: "number" },
                      model: {
                        value: _vm.form.shop_VIP_CONFIG_PASSWORD_13,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "shop_VIP_CONFIG_PASSWORD_13", $$v)
                        },
                        expression: "form.shop_VIP_CONFIG_PASSWORD_13",
                      },
                    }),
                    _c("span", [_vm._v("元")]),
                    _c("div", { staticClass: "padd15 red-font" }, [
                      _vm._v(
                        " 说明:开启免密支付，储值卡单笔付款金额超过免密额度需要输入支付密码，会员未设置密码时不受免密开关限制，支付无需输入密码 "
                      ),
                    ]),
                    _c(
                      "div",
                      { staticClass: "marT15 marL10" },
                      [
                        _c(
                          "el-checkbox",
                          {
                            staticClass: "marL10 marB10",
                            attrs: { "true-label": "Y", "false-label": "N" },
                            model: {
                              value: _vm.form.shop_VIP_CONFIG_STORED_31,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.form,
                                  "shop_VIP_CONFIG_STORED_31",
                                  $$v
                                )
                              },
                              expression: "form.shop_VIP_CONFIG_STORED_31",
                            },
                          },
                          [_vm._v("允许前台不打印会员明码信息")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c("cardTitleCom", {
        staticClass: "bg-Color marT10",
        attrs: { cardTitle: "IC卡设置" },
        scopedSlots: _vm._u([
          {
            key: "cardContent",
            fn: function () {
              return [
                _c(
                  "div",
                  { staticClass: "marT15 marL10" },
                  [
                    _c(
                      "el-checkbox",
                      {
                        staticClass: "marL10 marB10",
                        model: {
                          value: _vm.form.shop_VIP_CONFIG_IC_CARD_71,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.form,
                              "shop_VIP_CONFIG_IC_CARD_71",
                              $$v
                            )
                          },
                          expression: "form.shop_VIP_CONFIG_IC_CARD_71",
                        },
                      },
                      [_vm._v("设置IC卡")]
                    ),
                    _c(
                      "div",
                      { staticClass: "x-f marB10" },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "marL10",
                            staticStyle: { width: "100px" },
                          },
                          [_vm._v("IC卡类型")]
                        ),
                        _c(
                          "el-select",
                          {
                            staticClass: "inputWidth",
                            attrs: {
                              disabled: !_vm.form.shop_VIP_CONFIG_IC_CARD_71,
                              size: "mini",
                              placeholder: "请选择IC卡类型",
                            },
                            on: { change: _vm.cardTypeChange },
                            model: {
                              value: _vm.form.shop_VIP_CONFIG_IC_CARD_72,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.form,
                                  "shop_VIP_CONFIG_IC_CARD_72",
                                  $$v
                                )
                              },
                              expression: "form.shop_VIP_CONFIG_IC_CARD_72",
                            },
                          },
                          [
                            _c("el-option", {
                              attrs: { label: "默认版", value: "1" },
                            }),
                            _c("el-option", {
                              attrs: { label: "定制版", value: "2" },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "x-f marB10" },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "marL10",
                            staticStyle: { width: "100px" },
                          },
                          [_vm._v("IC卡读写密码")]
                        ),
                        _c("el-input", {
                          ref: "myInput",
                          staticClass: "inputWidth",
                          attrs: {
                            disabled: !_vm.form.shop_VIP_CONFIG_IC_CARD_71,
                            size: "mini",
                            maxlength: "12",
                            "show-password": "",
                          },
                          model: {
                            value: _vm.form.shop_VIP_CONFIG_IC_CARD_73,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.form,
                                "shop_VIP_CONFIG_IC_CARD_73",
                                $$v
                              )
                            },
                            expression: "form.shop_VIP_CONFIG_IC_CARD_73",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "x-f marB10 blockNumber" },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "marL10",
                            staticStyle: { width: "100px" },
                          },
                          [_vm._v("扇区")]
                        ),
                        _c("el-input", {
                          ref: "myInput",
                          staticClass: "inputWidth",
                          attrs: {
                            disabled:
                              _vm.form.shop_VIP_CONFIG_IC_CARD_72 == "1" ||
                              !_vm.form.shop_VIP_CONFIG_IC_CARD_71,
                            size: "mini",
                            maxlength: "12",
                            "show-password": "",
                          },
                          model: {
                            value: _vm.form.shop_VIP_CONFIG_IC_CARD_74,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.form,
                                "shop_VIP_CONFIG_IC_CARD_74",
                                $$v
                              )
                            },
                            expression: "form.shop_VIP_CONFIG_IC_CARD_74",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "x-f marB10 blockNumber" },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "marL10",
                            staticStyle: { width: "100px" },
                          },
                          [_vm._v("块号")]
                        ),
                        _c("el-input", {
                          staticClass: "inputWidth",
                          attrs: {
                            disabled:
                              _vm.form.shop_VIP_CONFIG_IC_CARD_72 == "1" ||
                              !_vm.form.shop_VIP_CONFIG_IC_CARD_71,
                            size: "mini",
                            maxlength: "12",
                            "show-password": "",
                          },
                          model: {
                            value: _vm.form.shop_VIP_CONFIG_IC_CARD_75,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.form,
                                "shop_VIP_CONFIG_IC_CARD_75",
                                $$v
                              )
                            },
                            expression: "form.shop_VIP_CONFIG_IC_CARD_75",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c("cardTitleCom", {
        staticClass: "bg-Color marT10",
        attrs: { cardTitle: "储值设置" },
        scopedSlots: _vm._u([
          {
            key: "cardContent",
            fn: function () {
              return [
                _c(
                  "div",
                  { staticClass: "marT15 marL10" },
                  [
                    _c(
                      "div",
                      { staticClass: "y-start" },
                      [
                        _c(
                          "el-checkbox",
                          {
                            staticClass: "padd10",
                            attrs: { "true-label": "Y", "false-label": "" },
                            model: {
                              value: _vm.form.shop_VIP_CONFIG_STORED_21,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.form,
                                  "shop_VIP_CONFIG_STORED_21",
                                  $$v
                                )
                              },
                              expression: "form.shop_VIP_CONFIG_STORED_21",
                            },
                          },
                          [_vm._v("支持储值卡多卡付款")]
                        ),
                        _c(
                          "el-checkbox",
                          {
                            staticClass: "padd10",
                            attrs: { "true-label": "Y", "false-label": "" },
                            model: {
                              value: _vm.form.shop_VIP_CONFIG_STORED_22,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.form,
                                  "shop_VIP_CONFIG_STORED_22",
                                  $$v
                                )
                              },
                              expression: "form.shop_VIP_CONFIG_STORED_22",
                            },
                          },
                          [_vm._v("充值卡充值时允许为负数")]
                        ),
                        _c(
                          "el-checkbox",
                          {
                            staticClass: "padd10",
                            attrs: { "true-label": "Y", "false-label": "" },
                            model: {
                              value: _vm.form.shop_VIP_CONFIG_STORED_23,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.form,
                                  "shop_VIP_CONFIG_STORED_23",
                                  $$v
                                )
                              },
                              expression: "form.shop_VIP_CONFIG_STORED_23",
                            },
                          },
                          [_vm._v("是否允许手工赠送(非储值赠送规则外)")]
                        ),
                      ],
                      1
                    ),
                    _c("span", { staticClass: "marL10" }, [
                      _vm._v("会员的储值卡余额低于"),
                    ]),
                    _c("el-input", {
                      staticClass: "inputWidth underline-input padd5",
                      attrs: { size: "mini", type: "number" },
                      model: {
                        value: _vm.form.shop_VIP_CONFIG_STORED_24,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "shop_VIP_CONFIG_STORED_24", $$v)
                        },
                        expression: "form.shop_VIP_CONFIG_STORED_24",
                      },
                    }),
                    _c("span", [_vm._v("元，不享受会员优惠")]),
                    _c("span", { staticClass: "red-font" }, [
                      _vm._v("(注: 金额为空或未输入金额时不控制)"),
                    ]),
                  ],
                  1
                ),
                _c("div", { staticClass: "marB10 marL10" }, [
                  _c(
                    "div",
                    { staticClass: "y-start" },
                    [
                      _c(
                        "el-checkbox",
                        {
                          staticClass: "padd10",
                          attrs: { "true-label": "Y", "false-label": "" },
                          model: {
                            value: _vm.form.shop_VIP_CONFIG_STORED_25,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.form,
                                "shop_VIP_CONFIG_STORED_25",
                                $$v
                              )
                            },
                            expression: "form.shop_VIP_CONFIG_STORED_25",
                          },
                        },
                        [_vm._v("非会员余额支付不享受优惠")]
                      ),
                      _c(
                        "el-checkbox",
                        {
                          staticClass: "marL10 marT5",
                          attrs: { "true-label": "Y", "false-label": "" },
                          model: {
                            value: _vm.form.shop_VIP_CONFIG_STORED_26,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.form,
                                "shop_VIP_CONFIG_STORED_26",
                                $$v
                              )
                            },
                            expression: "form.shop_VIP_CONFIG_STORED_26",
                          },
                        },
                        [_vm._v("是否允许前台充值")]
                      ),
                    ],
                    1
                  ),
                ]),
                _c("div", { staticClass: "marB15" }, [
                  _c(
                    "div",
                    { staticClass: "marL20" },
                    [
                      _c("span", [_vm._v("最大金额")]),
                      _c("el-input", {
                        staticClass: "inputWidth underline-input padd5",
                        attrs: {
                          size: "mini",
                          type: "number",
                          disabled: !_vm.checked,
                        },
                        model: {
                          value: _vm.form.shop_VIP_CONFIG_STORED_27,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "shop_VIP_CONFIG_STORED_27", $$v)
                          },
                          expression: "form.shop_VIP_CONFIG_STORED_27",
                        },
                      }),
                      _c("span", [_vm._v("充值最小倍数金额")]),
                      _c("el-input", {
                        staticClass: "inputWidth underline-input padd5",
                        attrs: {
                          size: "mini",
                          type: "number",
                          disabled: !_vm.checked,
                        },
                        model: {
                          value: _vm.form.shop_VIP_CONFIG_STORED_28,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "shop_VIP_CONFIG_STORED_28", $$v)
                          },
                          expression: "form.shop_VIP_CONFIG_STORED_28",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _c(
                  "div",
                  { staticClass: "marL20 marB10" },
                  [
                    _c("span", [_vm._v("启用优惠券消费的金额积分")]),
                    _c("el-switch", {
                      staticClass: "marL20",
                      attrs: { "active-value": "Y", "inactive-value": "N" },
                      model: {
                        value: _vm.form.shop_VIP_CONFIG_STORED_29,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "shop_VIP_CONFIG_STORED_29", $$v)
                        },
                        expression: "form.shop_VIP_CONFIG_STORED_29",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "marL20 marB10" },
                  [
                    _c("span", [_vm._v("会员消费限制")]),
                    _c("el-switch", {
                      staticClass: "marL20",
                      attrs: { "active-value": "Y", "inactive-value": "N" },
                      model: {
                        value: _vm.form.shop_VIP_CONFIG_STORED_30,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "shop_VIP_CONFIG_STORED_30", $$v)
                        },
                        expression: "form.shop_VIP_CONFIG_STORED_30",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "marL20" },
                  [
                    _c("span", [_vm._v("启用会员卡储零功能")]),
                    _c("el-switch", {
                      staticClass: "marL20",
                      attrs: { "active-value": "Y", "inactive-value": "N" },
                      model: {
                        value: _vm.form.shop_VIP_CONFIG_STORED_32,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "shop_VIP_CONFIG_STORED_32", $$v)
                        },
                        expression: "form.shop_VIP_CONFIG_STORED_32",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  [
                    _c("div", { staticClass: "marT15 marL20" }, [
                      _vm._v("会员卡充值赠送金额控制:"),
                    ]),
                    _c("span", { staticClass: "marL20 marT5" }, [
                      _vm._v("赠送金额不得高于当次充值金额的"),
                    ]),
                    _c("el-input", {
                      staticClass: "inputWidth underline-input padd5",
                      attrs: { size: "mini", type: "number" },
                      model: {
                        value: _vm.form.shop_VIP_CONFIG_STORED_210,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "shop_VIP_CONFIG_STORED_210", $$v)
                        },
                        expression: "form.shop_VIP_CONFIG_STORED_210",
                      },
                    }),
                    _c("span", [_vm._v("%")]),
                  ],
                  1
                ),
                _c("div", [
                  _c("div", { staticClass: "marT10 marL10 marB5" }, [
                    _c("span", { staticClass: "marL10" }, [
                      _vm._v("若您需要启用赊账业务,请勾选"),
                    ]),
                    _c(
                      "div",
                      { staticClass: "x-f" },
                      [
                        _c(
                          "el-checkbox",
                          {
                            staticClass: "marL10",
                            attrs: { "true-label": "1", "false-label": "" },
                            model: {
                              value: _vm.form.shop_VIP_CONFIG_STORED_211,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.form,
                                  "shop_VIP_CONFIG_STORED_211",
                                  $$v
                                )
                              },
                              expression: "form.shop_VIP_CONFIG_STORED_211",
                            },
                          },
                          [_vm._v("启用赊账业务")]
                        ),
                        _c(
                          "div",
                          { staticClass: "marL30" },
                          [
                            _c("span", [_vm._v("单个用户最高授信额度")]),
                            _c("el-input", {
                              staticClass: "inputWidth underline-input padd5",
                              attrs: { size: "mini", type: "number" },
                              model: {
                                value: _vm.form.shop_VIP_CONFIG_STORED_212,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.form,
                                    "shop_VIP_CONFIG_STORED_212",
                                    $$v
                                  )
                                },
                                expression: "form.shop_VIP_CONFIG_STORED_212",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                  _c(
                    "div",
                    { staticClass: "marL20 marB10" },
                    [
                      _c("span", [
                        _vm._v("会员价/会员折扣支持临时改价/折扣折上折"),
                      ]),
                      _c("el-switch", {
                        staticClass: "marL20",
                        attrs: { "active-value": "Y", "inactive-value": "N" },
                        model: {
                          value: _vm.form.shop_VIP_CONFIG_STORED_33,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "shop_VIP_CONFIG_STORED_33", $$v)
                          },
                          expression: "form.shop_VIP_CONFIG_STORED_33",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "marL20 marB10" },
                    [
                      _c("span", [_vm._v("充值小票打印付款留底单")]),
                      _c("el-switch", {
                        staticClass: "marL20",
                        attrs: { "active-value": "Y", "inactive-value": "N" },
                        model: {
                          value: _vm.form.shop_VIP_CONFIG_STORED_35,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "shop_VIP_CONFIG_STORED_35", $$v)
                          },
                          expression: "form.shop_VIP_CONFIG_STORED_35",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c("cardTitleCom", {
        staticClass: "bg-Color marT10 marB10",
        attrs: { cardTitle: "清除会员卡积分" },
        scopedSlots: _vm._u([
          {
            key: "cardContent",
            fn: function () {
              return [
                _c(
                  "div",
                  { staticClass: "marT15 marB15 padd15" },
                  [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary", size: "mini" },
                        on: { click: _vm.open },
                      },
                      [_vm._v("立即清除")]
                    ),
                    _c(
                      "el-checkbox",
                      {
                        staticClass: "marL30",
                        model: {
                          value: _vm.form.shop_VIP_CONFIG_INTEGRAL_CLEAN_42,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.form,
                              "shop_VIP_CONFIG_INTEGRAL_CLEAN_42",
                              $$v
                            )
                          },
                          expression: "form.shop_VIP_CONFIG_INTEGRAL_CLEAN_42",
                        },
                      },
                      [_vm._v("定时清除 ")]
                    ),
                    _vm.form.shop_VIP_CONFIG_INTEGRAL_CLEAN_42
                      ? _c("el-date-picker", {
                          staticClass: "marL10",
                          attrs: {
                            type: "date",
                            size: "mini",
                            "value-format": "yyyy-MM-dd",
                          },
                          on: { change: _vm.handleDateTimeChange },
                          model: {
                            value: _vm.dateTimeValue,
                            callback: function ($$v) {
                              _vm.dateTimeValue = $$v
                            },
                            expression: "dateTimeValue",
                          },
                        })
                      : _vm._e(),
                    _c(
                      "el-dialog",
                      {
                        attrs: {
                          title: "请输入设置密码",
                          visible: _vm.showDialog,
                          width: "30%",
                        },
                        on: {
                          "update:visible": function ($event) {
                            _vm.showDialog = $event
                          },
                        },
                      },
                      [
                        _c("el-input", {
                          staticClass: "input-with-select",
                          attrs: {
                            placeholder: "请输入内容",
                            autocomplete: "new-password",
                            type: "password",
                            "show-password": "",
                          },
                          model: {
                            value: _vm.password,
                            callback: function ($$v) {
                              _vm.password = $$v
                            },
                            expression: "password",
                          },
                        }),
                        _c(
                          "span",
                          {
                            staticClass: "dialog-footer",
                            attrs: { slot: "footer" },
                            slot: "footer",
                          },
                          [
                            _c(
                              "el-button",
                              { on: { click: _vm.cancelPasswordInput } },
                              [_vm._v("取消")]
                            ),
                            _c(
                              "el-button",
                              {
                                attrs: { type: "primary" },
                                on: { click: _vm.confirmPasswordInput },
                              },
                              [_vm._v("确定")]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c("cardTitleCom", {
        staticClass: "bg-Color marT10 marB10",
        attrs: { cardTitle: "会员卡提醒设置" },
        scopedSlots: _vm._u([
          {
            key: "cardContent",
            fn: function () {
              return [
                _c("div", { staticClass: "marB15 marL15 marT10" }, [
                  _c(
                    "div",
                    [
                      _c(
                        "el-checkbox",
                        {
                          attrs: { "true-label": "1", "false-label": "" },
                          model: {
                            value: _vm.form.shop_VIP_CONFIG_REMIND_51.ruleType,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.form.shop_VIP_CONFIG_REMIND_51,
                                "ruleType",
                                $$v
                              )
                            },
                            expression:
                              "form.shop_VIP_CONFIG_REMIND_51.ruleType",
                          },
                        },
                        [_vm._v("会员卡生日提前")]
                      ),
                      _c("el-input", {
                        staticClass: "input marL5 marR5",
                        staticStyle: { width: "60px" },
                        attrs: {
                          size: "mini",
                          type: "number",
                          min: 1,
                          disabled:
                            _vm.form.shop_VIP_CONFIG_REMIND_51.ruleType != "1",
                        },
                        model: {
                          value: _vm.form.shop_VIP_CONFIG_REMIND_51.ruleValue,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.form.shop_VIP_CONFIG_REMIND_51,
                              "ruleValue",
                              $$v
                            )
                          },
                          expression:
                            "form.shop_VIP_CONFIG_REMIND_51.ruleValue",
                        },
                      }),
                      _vm._v(" 天提醒 "),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    [
                      _c(
                        "el-checkbox",
                        {
                          attrs: {
                            checked: _vm.isCheckboxChecked2,
                            "true-label": "1",
                            "false-label": "",
                          },
                          model: {
                            value: _vm.form.shop_VIP_CONFIG_REMIND_52.ruleType,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.form.shop_VIP_CONFIG_REMIND_52,
                                "ruleType",
                                $$v
                              )
                            },
                            expression:
                              "form.shop_VIP_CONFIG_REMIND_52.ruleType",
                          },
                        },
                        [_vm._v("会员卡到期提前")]
                      ),
                      _c("el-input", {
                        staticClass: "input marT5 marL5 marR5",
                        staticStyle: { width: "60px" },
                        attrs: {
                          size: "mini",
                          type: "number",
                          min: 1,
                          disabled:
                            _vm.form.shop_VIP_CONFIG_REMIND_52.ruleType != "1",
                        },
                        model: {
                          value: _vm.form.shop_VIP_CONFIG_REMIND_52.ruleValue,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.form.shop_VIP_CONFIG_REMIND_52,
                              "ruleValue",
                              $$v
                            )
                          },
                          expression:
                            "form.shop_VIP_CONFIG_REMIND_52.ruleValue",
                        },
                      }),
                      _vm._v(" 天提醒 "),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    [
                      _c(
                        "el-checkbox",
                        {
                          attrs: {
                            "true-label": "1",
                            "false-label": "",
                            checked: _vm.isCheckboxChecked3,
                          },
                          model: {
                            value: _vm.form.shop_VIP_REMIND_53.ruleType,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.form.shop_VIP_REMIND_53,
                                "ruleType",
                                $$v
                              )
                            },
                            expression: "form.shop_VIP_REMIND_53.ruleType",
                          },
                        },
                        [_vm._v("会员卡余额不足")]
                      ),
                      _c("el-input", {
                        staticClass: "input marT5 marL5 marR5",
                        staticStyle: { width: "60px" },
                        attrs: {
                          size: "mini",
                          type: "number",
                          min: 1,
                          disabled: _vm.form.shop_VIP_REMIND_53.ruleType != "1",
                        },
                        model: {
                          value: _vm.form.shop_VIP_REMIND_53.ruleValue,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.form.shop_VIP_REMIND_53,
                              "ruleValue",
                              $$v
                            )
                          },
                          expression: "form.shop_VIP_REMIND_53.ruleValue",
                        },
                      }),
                      _vm._v("元提醒 "),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "marT10" }, [
                    _vm._v("会员卡异常设置"),
                  ]),
                  _c(
                    "div",
                    [
                      _c(
                        "el-checkbox",
                        {
                          attrs: {
                            "true-label": "1",
                            "false-label": "",
                            checked: _vm.isVal,
                          },
                          model: {
                            value: _vm.isCheckboxChecked4,
                            callback: function ($$v) {
                              _vm.isCheckboxChecked4 = $$v
                            },
                            expression: "isCheckboxChecked4",
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "80px" },
                              attrs: {
                                placeholder: "每天",
                                size: "mini",
                                disabled: !_vm.isCheckboxChecked4,
                              },
                              model: {
                                value: _vm.form.shop_VIP_CONFIG_ERROR_61,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.form,
                                    "shop_VIP_CONFIG_ERROR_61",
                                    $$v
                                  )
                                },
                                expression: "form.shop_VIP_CONFIG_ERROR_61",
                              },
                            },
                            [
                              _c("el-option", {
                                attrs: { label: "每天", value: "1" },
                              }),
                              _c("el-option", {
                                attrs: { label: "每月", value: "3" },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" 会员卡消费次数超过 "),
                          _c("el-input", {
                            staticClass: "input marT5 marR5",
                            staticStyle: { width: "60px" },
                            attrs: {
                              disabled: !_vm.isCheckboxChecked4,
                              size: "mini",
                              min: 1,
                              type: "number",
                            },
                            model: {
                              value: _vm.form.shop_VIP_CONFIG_ERROR_62,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.form,
                                  "shop_VIP_CONFIG_ERROR_62",
                                  $$v
                                )
                              },
                              expression: "form.shop_VIP_CONFIG_ERROR_62",
                            },
                          }),
                          _vm._v("次 "),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    [
                      _c(
                        "el-checkbox",
                        {
                          attrs: { "true-label": "1", "false-label": "" },
                          model: {
                            value: _vm.isCheckboxChecked5,
                            callback: function ($$v) {
                              _vm.isCheckboxChecked5 = $$v
                            },
                            expression: "isCheckboxChecked5",
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "80px" },
                              attrs: {
                                placeholder: "每天",
                                size: "mini",
                                disabled: !_vm.isCheckboxChecked5,
                              },
                              model: {
                                value: _vm.form.shop_VIP_CONFIG_ERROR_63,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.form,
                                    "shop_VIP_CONFIG_ERROR_63",
                                    $$v
                                  )
                                },
                                expression: "form.shop_VIP_CONFIG_ERROR_63",
                              },
                            },
                            [
                              _c("el-option", {
                                attrs: { label: "每天", value: "1" },
                              }),
                              _c("el-option", {
                                attrs: { label: "每月", value: "3" },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" 会员卡消费金额超过 "),
                          _c("el-input", {
                            staticClass: "input marT5 marR5",
                            staticStyle: { width: "60px" },
                            attrs: {
                              disabled: !_vm.isCheckboxChecked5,
                              type: "number",
                              size: "mini",
                            },
                            model: {
                              value: _vm.form.shop_VIP_CONFIG_ERROR_64,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.form,
                                  "shop_VIP_CONFIG_ERROR_64",
                                  $$v
                                )
                              },
                              expression: "form.shop_VIP_CONFIG_ERROR_64",
                            },
                          }),
                          _vm._v("元 "),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    [
                      _c(
                        "el-checkbox",
                        {
                          attrs: { "true-label": "1", "false-label": "" },
                          model: {
                            value: _vm.isCheckboxChecked6,
                            callback: function ($$v) {
                              _vm.isCheckboxChecked6 = $$v
                            },
                            expression: "isCheckboxChecked6",
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "80px" },
                              attrs: {
                                disabled: !_vm.isCheckboxChecked6,
                                placeholder: "每天",
                                size: "mini",
                              },
                              model: {
                                value: _vm.form.shop_VIP_CONFIG_ERROR_65,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.form,
                                    "shop_VIP_CONFIG_ERROR_65",
                                    $$v
                                  )
                                },
                                expression: "form.shop_VIP_CONFIG_ERROR_65",
                              },
                            },
                            [
                              _c("el-option", {
                                attrs: { label: "每天", value: "1" },
                              }),
                              _c("el-option", {
                                attrs: { label: "每月", value: "3" },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" 会员卡积分次数超过 "),
                          _c("el-input", {
                            staticClass: "input marT5 marR5",
                            staticStyle: { width: "60px" },
                            attrs: {
                              type: "number",
                              size: "mini",
                              disabled: !_vm.isCheckboxChecked6,
                            },
                            model: {
                              value: _vm.form.shop_VIP_CONFIG_ERROR_66,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.form,
                                  "shop_VIP_CONFIG_ERROR_66",
                                  $$v
                                )
                              },
                              expression: "form.shop_VIP_CONFIG_ERROR_66",
                            },
                          }),
                          _vm._v("次 "),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }