var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "templateSet" },
    [
      _c(
        "cardTitleCom",
        { attrs: { cardTitle: "模板设置" } },
        [
          _c("template", { slot: "cardContent" }, [
            _c(
              "div",
              { staticClass: "setList-content" },
              [
                _c("div", { staticClass: "setList-header" }, [
                  _vm._v("• 小票模板"),
                ]),
                _vm._l(_vm.setList, function (item) {
                  return _c(
                    "div",
                    { key: item.type, staticClass: "setList-item x-bc" },
                    [
                      _c("div", { staticClass: "title" }, [
                        _vm._v(_vm._s(item.title)),
                      ]),
                      _c("div", { staticClass: "introduce" }, [
                        _vm._v(_vm._s(item.introduce)),
                      ]),
                      _c(
                        "div",
                        {
                          staticClass: "btn",
                          on: {
                            click: function ($event) {
                              return _vm.handleEditBtn(item.type, 0)
                            },
                          },
                        },
                        [_vm._v("编辑")]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "btn",
                          on: {
                            click: function ($event) {
                              return _vm.handleEditBtn(item.type, 1)
                            },
                          },
                        },
                        [_vm._v("高级编辑")]
                      ),
                    ]
                  )
                }),
              ],
              2
            ),
          ]),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }