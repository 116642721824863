var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "receiptFormat" }, [
    _c("div", { staticClass: "RF-header x-bc" }, [
      _c("div", { staticClass: "headerTitle x-f" }, [
        _c("div", {
          staticClass: "backBtn pointer el-icon-arrow-left",
          on: { click: _vm.handleBack },
        }),
        _c("div", { staticClass: "title" }, [_vm._v(_vm._s(_vm.title))]),
      ]),
      _c(
        "div",
        { staticClass: "btn" },
        [
          _c(
            "el-popover",
            {
              attrs: {
                placement: "top-end",
                width: "200",
                trigger: "hover",
                content: "预览效果弹窗可任意拖动",
              },
            },
            [
              _c(
                "el-button",
                {
                  attrs: { slot: "reference" },
                  on: { click: _vm.handlePreviewDialog },
                  slot: "reference",
                },
                [_vm._v("预览效果")]
              ),
            ],
            1
          ),
          _c(
            "el-button",
            {
              staticStyle: { "margin-left": "5px" },
              attrs: { type: "primary" },
              on: { click: _vm.submitBtn },
            },
            [_vm._v("保存模板")]
          ),
        ],
        1
      ),
    ]),
    _c(
      "div",
      {
        directives: [
          {
            name: "loading",
            rawName: "v-loading",
            value: _vm.loading,
            expression: "loading",
          },
        ],
        staticClass: "RF-main x-x",
      },
      [
        _c("LeftTemplate", {
          staticClass: "LeftTemplate",
          attrs: {
            setTemplateType: _vm.setTemplateType,
            receiptFormat: _vm.receiptFormat,
            receiptFormatList: _vm.receiptFormatList,
          },
          on: {
            addParameter: _vm.addParameter,
            handleTemplate: _vm.handleTemplate,
            addTemplate: _vm.addTemplate,
          },
        }),
        !_vm.receiptFormat.formatType
          ? _c("PreviewEait", {
              staticClass: "PreviewEait",
              attrs: { receiptFormat: _vm.receiptFormat },
            })
          : _c("PreviewEaitHighLevel", {
              ref: "PreviewEaitHighLevel",
              staticClass: "PreviewEaitHighLevel",
              attrs: {
                formatMachineType: _vm.receiptFormat.formatMachineType,
                activeIndex: _vm.activeIndex,
              },
              on: {
                "update:formatMachineType": function ($event) {
                  return _vm.$set(
                    _vm.receiptFormat,
                    "formatMachineType",
                    $event
                  )
                },
                "update:format-machine-type": function ($event) {
                  return _vm.$set(
                    _vm.receiptFormat,
                    "formatMachineType",
                    $event
                  )
                },
                "update:activeIndex": function ($event) {
                  _vm.activeIndex = $event
                },
                "update:active-index": function ($event) {
                  _vm.activeIndex = $event
                },
                setReceiptFormat: function ($event) {
                  _vm.receiptFormat = Object.assign(
                    {},
                    _vm.receiptFormat,
                    $event
                  )
                },
              },
              model: {
                value: _vm.list,
                callback: function ($$v) {
                  _vm.list = $$v
                },
                expression: "list",
              },
            }),
        _vm.receiptFormat.formatType === 0
          ? _c("RightDecorate", {
              staticClass: "RightDecorate",
              attrs: {
                receiptFormat: _vm.receiptFormat,
                setTemplateType: _vm.setTemplateType,
              },
            })
          : _c("RightDecorateHighLevel", {
              ref: "RightDecorateHighLevel",
              staticClass: "RightDecorateHighLevel",
              attrs: {
                form: _vm.list[_vm.activeIndex] || { style: {} },
                receiptFormat: _vm.receiptFormat,
                setTemplateType: _vm.setTemplateType,
              },
              on: {
                delParameter: function ($event) {
                  return _vm.delParameter()
                },
              },
            }),
        _c("TemplatePreviewDialog", {
          attrs: {
            receiptFormat: _vm.receiptFormat,
            openDialog: _vm.openPreviewDialog,
            setTemplateType: _vm.setTemplateType,
          },
          on: {
            "update:openDialog": function ($event) {
              _vm.openPreviewDialog = $event
            },
            "update:open-dialog": function ($event) {
              _vm.openPreviewDialog = $event
            },
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }