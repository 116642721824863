<template>
  <!-- 右边装饰小票区域 -->
  <div class="right-decorate">
    <div class="title">小票规格</div>
    <div class="spec">
      <div
        class="spec-item pointer"
        :class="{ active: receiptFormat.formatMachineType === item.type }"
        @click="receiptFormat.formatMachineType = item.type"
        v-for="item in receiptSpecList"
        :key="item.length"
      >
        <div>{{ item.length }}</div>
        <div class="tag">{{ item.tag }}</div>
      </div>
    </div>
    <template v-if="setTemplateType == 'cashier'">
      <div class="title">文本设置</div>
      <div class="desc">头部标题</div>
      <textarea
        autocomplete="off"
        rows="2"
        placeholder="请输入头部标题"
        class="el-textarea__inner"
        style="resize: none; min-height: 33px"
        v-model="receiptFormat.formatHeadCaption"
        :style="`font-size:${receiptFormat.headCaptionFontSize}px;font-weight:${
          receiptFormat.isHeadCaptionBold ? '700' : '400'
        }`"
      ></textarea>
      <div class="x-f">
        <div class="x-f desc">
          <span>字体大小:</span>
          <el-select
            v-model="receiptFormat.headCaptionFontSize"
            placeholder="请选择字体大小"
            size="mini"
          >
            <el-option
              v-for="item in typefaceList"
              :key="item"
              :label="item"
              :value="item"
            >
            </el-option>
          </el-select>
        </div>
        <div class="x-f desc">
          <span>字体加粗:</span>
          <el-switch v-model="receiptFormat.isHeadCaptionBold" size="mini"> </el-switch>
        </div>
      </div>
      <div class="desc">底部标语</div>
      <textarea
        autocomplete="off"
        rows="2"
        placeholder="请输入底部标题"
        class="el-textarea__inner"
        style="resize: none; min-height: 33px"
        :style="`font-size:${receiptFormat.bottomCaptionFontSize}px;font-weight:${
          receiptFormat.isBottomCaptionBold ? '700' : '400'
        }`"
        v-model="receiptFormat.formatBottomCaption"
      ></textarea>
      <div class="x-f">
        <div class="x-f desc">
          <span>字体大小:</span>
          <el-select
            v-model="receiptFormat.bottomCaptionFontSize"
            placeholder="请选择字体大小"
            size="mini"
          >
            <el-option
              v-for="item in typefaceList"
              :key="item"
              :label="item"
              :value="item"
            >
            </el-option>
          </el-select>
        </div>
        <div class="x-f desc">
          <span>字体加粗:</span>
          <el-switch v-model="receiptFormat.isBottomCaptionBold" size="mini"> </el-switch>
        </div>
      </div>
      <div class="title">
        上传图片
        <span class="tag">请使用黑白图片(LOGO 300*200,二维码 300*300)</span>
        <!-- <span class="setupTutorial">设置教程</span> -->
      </div>
      <!-- 上传图片 -->
      <div class="x-f upload">
        <div class="uploadLogo">
          <SingleImageUpload
            @getImageUrl="handleImageUrl($event, 'LOGO')"
            @delImage="handleImageUrl(undefined, 'LOGO')"
          />
          <div class="name">LOGO</div>
        </div>
        <div class="uploadQRcode">
          <SingleImageUpload
            @getImageUrl="handleImageUrl($event, 'QRcode')"
            @delImage="handleImageUrl(undefined, 'QRcode')"
          />
          <div class="name">二维码</div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import SingleImageUpload from "@/views/components/singleImageUpload"; //单图片上传组件
export default {
  name: "RightDecorate",
  props: {
    //小票模板
    receiptFormat: {
      type: Object,
    },
    //设置模板类型
    setTemplateType: {
      type: String,
    },
  },
  components: { SingleImageUpload },
  data() {
    return {
      //小票规格列表
      receiptSpecList: [
        { type: 58, length: "58mm", tag: "每行16个汉字" },
        { type: 80, length: "80mm", tag: "每行24个汉字" },
        { type: 110, length: "110mm", tag: "每行32个汉字" },
      ],
      //字体大小
      typefaceList: [
        12,
        13,
        14,
        15,
        16,
        17,
        18,
        19,
        20,
        21,
        22,
        23,
        24,
        25,
        26,
        27,
        28,
        29,
        30,
      ],
    };
  },
  methods: {
    //操作图片
    handleImageUrl(url, type) {
      if (type === "LOGO") {
        this.receiptFormat.formatLogoUrl = url;
        if (url) this.receiptFormat.formatContent += "\n#IMG{图片1}\n";
        else
          this.receiptFormat.formatContent = this.receiptFormat.formatContent.replace(
            "#IMG{图片1}\n",
            ""
          );
      } else {
        this.receiptFormat.formatQrCodeUrl = url;
        if (url) this.receiptFormat.formatContent += "\n#IMG{图片2}\n";
        else
          this.receiptFormat.formatContent = this.receiptFormat.formatContent.replace(
            "#IMG{图片2}\n",
            ""
          );
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.right-decorate {
  padding: 5px 20px;
  .title {
    padding: 15px 0;
    text-align: left;
    //设置教程
    .setupTutorial {
      font-size: 12px;
      color: #409eff;
    }
    //提醒
    .tag {
      font-size: 12px;
      color: #999999;
      margin-right: 3px;
    }
  }
  .desc {
    font-size: 14px;
    color: #999999;
    padding: 5px 0;
  }
  .spec {
    display: flex;
    .spec-item {
      height: 56px;
      width: 132px;
      margin-right: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      border: 1px solid #eee;
      border-radius: 5px;
      .tag {
        color: #b2b3ae;
        font-size: 14px;
      }
      &.active {
        background-color: #edf4ff;
        border: 1px solid #1890ff;
      }
    }
  }
  .el-select {
    width: 80px;
    margin: 0 5px;
  }
  //上传图片
  .upload {
    .uploadLogo,
    .uploadQRcode {
      margin-right: 10px;
      text-align: center;
      .name {
        margin-top: 10px;
      }
    }
  }
  ::v-deep .el-upload-list--picture-card .el-upload-list__item {
    margin: 0 !important;
  }
}
</style>
