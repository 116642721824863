var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "left-template x-x" }, [
    _c("div", { staticClass: "menus" }, [
      _c(
        "div",
        {
          staticClass: "menu pointer",
          class: { active: _vm.curPage === "模板" },
          on: {
            click: function ($event) {
              _vm.curPage = "模板"
            },
          },
        },
        [_vm._m(0), _c("div", { staticClass: "name" }, [_vm._v("模板")])]
      ),
      _c(
        "div",
        {
          staticClass: "menu pointer",
          class: { active: _vm.curPage === "参数" },
          on: {
            click: function ($event) {
              _vm.curPage = "参数"
            },
          },
        },
        [_vm._m(1), _c("div", { staticClass: "name" }, [_vm._v("参数")])]
      ),
    ]),
    _c("div", { staticClass: "content" }, [
      _vm.curPage === "模板"
        ? _c("div", { staticClass: "template" }, [
            _c(
              "div",
              { staticClass: "btn pointer", on: { click: _vm.addTemplate } },
              [_vm._v("新增模板")]
            ),
            _c(
              "div",
              { staticClass: "template-list" },
              [
                _c(
                  "el-scrollbar",
                  _vm._l(_vm.receiptFormatList, function (item) {
                    return _c(
                      "div",
                      {
                        key: item.formatId,
                        staticClass: "template-item",
                        class: { active: item.isCurrentUseFormat },
                      },
                      [
                        _c("div", { staticClass: "serialNum" }, [
                          _vm._v(_vm._s(item.serialNum)),
                        ]),
                        _c("div", { staticClass: "template" }, [
                          item.formatType !== 1
                            ? _c("textarea", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: item.formatHeadCaption,
                                    expression: "item.formatHeadCaption",
                                  },
                                ],
                                staticClass: "el-textarea__inner",
                                staticStyle: { resize: "none" },
                                style:
                                  "font-size:" +
                                  item.headCaptionFontSize +
                                  "px;font-weight:" +
                                  (item.isHeadCaptionBold ? "700" : "400"),
                                attrs: {
                                  autocomplete: "off",
                                  rows: "2",
                                  placeholder: "",
                                },
                                domProps: { value: item.formatHeadCaption },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      item,
                                      "formatHeadCaption",
                                      $event.target.value
                                    )
                                  },
                                },
                              })
                            : _vm._e(),
                          _c("div", { staticClass: "formatContent" }, [
                            _c("pre", {
                              staticStyle: { "white-space": "pre-wrap" },
                              domProps: {
                                innerHTML: _vm._s(item.repFormatContent),
                              },
                            }),
                          ]),
                          item.formatType !== 1
                            ? _c("textarea", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: item.formatBottomCaption,
                                    expression: "item.formatBottomCaption",
                                  },
                                ],
                                staticClass: "el-textarea__inner",
                                staticStyle: { resize: "none" },
                                style:
                                  "font-size:" +
                                  item.bottomCaptionFontSize +
                                  "px;font-weight:" +
                                  (item.isBottomCaptionBold ? "700" : "400"),
                                attrs: {
                                  autocomplete: "off",
                                  rows: "2",
                                  placeholder: "请输入底部标题",
                                },
                                domProps: { value: item.formatBottomCaption },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      item,
                                      "formatBottomCaption",
                                      $event.target.value
                                    )
                                  },
                                },
                              })
                            : _vm._e(),
                        ]),
                        _c("div", { staticClass: "templateBtns" }, [
                          _c(
                            "span",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: !item.isCurrentUseFormat,
                                  expression: "!item.isCurrentUseFormat",
                                },
                              ],
                              staticClass: "use-btn x-fc",
                              on: {
                                click: function ($event) {
                                  return _vm.handleTemplate(
                                    "use",
                                    item.formatId
                                  )
                                },
                              },
                            },
                            [_vm._v("使用此模板")]
                          ),
                          _c(
                            "span",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: item.isCurrentUseFormat,
                                  expression: "item.isCurrentUseFormat",
                                },
                              ],
                              staticClass: "use-btn x-fc",
                            },
                            [_vm._v("模板使用中")]
                          ),
                          _c("div", { staticClass: "line" }),
                          !item.isSysDefaultFormat
                            ? _c(
                                "span",
                                {
                                  staticClass: "edit-btn x-fc",
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleTemplate(
                                        "edit",
                                        item.formatId
                                      )
                                    },
                                  },
                                },
                                [_vm._v("编辑")]
                              )
                            : _c(
                                "span",
                                { staticClass: "edit-btn disabled x-fc" },
                                [_vm._v("编辑")]
                              ),
                          _c("div", { staticClass: "line" }),
                          _c(
                            "span",
                            {
                              staticClass: "copy-btn x-fc",
                              on: {
                                click: function ($event) {
                                  return _vm.handleTemplate(
                                    "copy",
                                    item.formatId
                                  )
                                },
                              },
                            },
                            [_vm._v("复制")]
                          ),
                          _c("div", { staticClass: "line" }),
                          !item.isSysDefaultFormat
                            ? _c(
                                "span",
                                {
                                  staticClass: "del-btn x-fc",
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleTemplate(
                                        "del",
                                        item.formatId
                                      )
                                    },
                                  },
                                },
                                [_vm._v("删除")]
                              )
                            : _vm._e(),
                        ]),
                      ]
                    )
                  }),
                  0
                ),
              ],
              1
            ),
          ])
        : _c(
            "div",
            { staticClass: "parameter" },
            [
              _c("el-input", {
                attrs: {
                  "prefix-icon": "el-icon-search",
                  placeholder: "搜索参数字段",
                },
                model: {
                  value: _vm.searchParameter,
                  callback: function ($$v) {
                    _vm.searchParameter = $$v
                  },
                  expression: "searchParameter",
                },
              }),
              _c(
                "div",
                { staticClass: "parameter-list" },
                [
                  _c(
                    "el-scrollbar",
                    _vm._l(_vm.searchParameterList, function (item) {
                      return _c(
                        "div",
                        {
                          key: item.text,
                          staticClass: "parameter-item x-bc pointer",
                          on: {
                            click: function ($event) {
                              return _vm.addParameter(item)
                            },
                          },
                        },
                        [
                          _c("div", { staticClass: "name" }, [
                            _vm._v(_vm._s(item.name)),
                          ]),
                          _c("div", {
                            staticClass: "icon el-icon-circle-plus-outline",
                          }),
                        ]
                      )
                    }),
                    0
                  ),
                ],
                1
              ),
              _c("div", { staticClass: "moreParameterBtn pointer" }, [
                _vm._v("查看详细参数"),
              ]),
            ],
            1
          ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "icon" }, [
      _c("img", {
        attrs: {
          src: require("@/assets/images/template-icon.png"),
          alt: "加载失败",
        },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "icon" }, [
      _c("img", {
        attrs: {
          src: require("@/assets/images/parameter-icon.png"),
          alt: "加载失败",
        },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }