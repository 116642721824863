<template>
  <!-- 中间预览编辑小票区域 -->
  <div class="preview-edit">
    <el-scrollbar>
      <div
        class="content"
        :class="{
          w58: receiptFormat.formatMachineType == '58',
          w80: receiptFormat.formatMachineType == '80',
          w110: receiptFormat.formatMachineType == '110',
        }"
      >
        <!-- <div class="edit-header">{{ receiptFormat.formatHeadCaption }}</div> -->
        <textarea
          autocomplete="off"
          rows="2"
          placeholder="请输入头部标题"
          class="el-textarea__inner"
          style="resize: none"
          :style="`font-size:${receiptFormat.headCaptionFontSize}px;font-weight:${
            receiptFormat.isHeadCaptionBold ? '700' : '400'
          }`"
          v-model="receiptFormat.formatHeadCaption"
        >
        </textarea>
        <div class="edit-textarea">
          <el-input
            type="textarea"
            autosize
            placeholder="请输入内容"
            v-model="receiptFormat.formatContent"
          >
          </el-input>
        </div>
        <!-- <div class="edit-footer">{{ receiptFormat.formatBottomCaption }}</div> -->
        <textarea
          autocomplete="off"
          rows="2"
          placeholder="请输入底部标题"
          class="el-textarea__inner"
          style="resize: none"
          v-model="receiptFormat.formatBottomCaption"
          :style="`font-size:${receiptFormat.bottomCaptionFontSize}px;font-weight:${
            receiptFormat.isBottomCaptionBold ? '700' : '400'
          }`"
        ></textarea>
      </div>
    </el-scrollbar>
  </div>
</template>

<script>
export default {
  name: "PreviewEdit",
  props: {
    //小票模板
    receiptFormat: {
      type: Object,
    },
  },
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.preview-edit {
  padding: 80px 0;
  display: flex;
  justify-content: center;
  height: 100%;
  position: relative;
  //滚动条
  .el-scrollbar {
    height: 100%;
    width: 100%;
    overflow: hidden;
    ::v-deep .el-scrollbar__wrap {
      overflow-x: hidden;
    }
    ::v-deep .el-scrollbar__view {
      height: 100%;
      display: flex;
      justify-content: center;
    }
  }
  .el-textarea__inner {
    border: none;
  }
  .content {
    margin: 0 20px;
    height: 100%;
    background-color: #fff;
    font-size: 13px;
    .edit-header {
      height: 52px;
      background-color: #fff;
      padding: 32px 24px 0 !important;
    }
    .edit-textarea {
      background-color: #fff;
      min-height: 85px;
      ::v-deep .el-textarea__inner {
        overflow: hidden;
        padding: 32px 24px !important;
        border: none;
        resize: none;
        color: #000 !important;
        font-size: 14px;
      }
    }
    .edit-footer {
      height: 52px;
      background-color: #fff;
      padding: 0 24px 32px !important;
    }
    &.w58 {
      width: 272px;
    }
    &.w80 {
      width: 384px;
    }
    &.w110 {
      width: 496px;
    }
  }
}
</style>
