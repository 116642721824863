var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "wrap",
    },
    [
      _c("topOperatingButton", {
        attrs: { isSubmitAddBtn: false, isAuditBillBtn: false },
        on: { submitForm: _vm.submitForm, getQuit: _vm.getQuit },
      }),
      _c(
        "el-form",
        {
          ref: "form",
          attrs: {
            model: _vm.posImageForm,
            rules: _vm.rules,
            "label-width": "100px",
          },
        },
        [
          _c(
            "cardTitleCom",
            { attrs: { cardTitle: "基本信息" } },
            [
              _c("template", { slot: "cardContent" }, [
                _c("div", { staticClass: "tableContent" }, [
                  _c(
                    "div",
                    { staticClass: "x-f" },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "方案编号", prop: "posImageNo" } },
                        [
                          _c("el-input", {
                            staticClass: "inputWidth",
                            attrs: { disabled: "", placeholder: "方案编号" },
                            model: {
                              value: _vm.posImageForm.posImageNo,
                              callback: function ($$v) {
                                _vm.$set(_vm.posImageForm, "posImageNo", $$v)
                              },
                              expression: "posImageForm.posImageNo",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "方案名称", prop: "posImageName" } },
                        [
                          _c("el-input", {
                            staticClass: "inputWidth",
                            attrs: { placeholder: "方案名称" },
                            model: {
                              value: _vm.posImageForm.posImageName,
                              callback: function ($$v) {
                                _vm.$set(_vm.posImageForm, "posImageName", $$v)
                              },
                              expression: "posImageForm.posImageName",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "备注" } },
                        [
                          _c("el-input", {
                            staticClass: "inputRemark",
                            attrs: {
                              type: "textarea",
                              placeholder: "备注长度介于 1 和 80 字符之间",
                              maxlength: "80",
                              autosize: { minRows: 1, maxRows: 5 },
                            },
                            model: {
                              value: _vm.posImageForm.posImageRemark,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.posImageForm,
                                  "posImageRemark",
                                  $$v
                                )
                              },
                              expression: "posImageForm.posImageRemark",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
              ]),
            ],
            2
          ),
          _c(
            "cardTitleCom",
            { attrs: { cardTitle: "图片管理" } },
            [
              _c("template", { slot: "cardContent" }, [
                _c(
                  "div",
                  { staticClass: "tableContentImg y-start" },
                  [
                    _c("span", { staticClass: "fontS14 marB20" }, [
                      _vm._v("PC POS主屏logo"),
                    ]),
                    _c("accessory-upload", {
                      staticClass: "marB20",
                      attrs: {
                        listType: "picture-card",
                        limit: 1,
                        title: "",
                        fileList: _vm.fileListLogo,
                      },
                      on: {
                        getFileItems: _vm.getFileItemsDataLogo,
                        delFileItems: _vm.delFileItemsLogo,
                      },
                    }),
                    _c("span", { staticClass: "fontS14 marB20" }, [
                      _vm._v("PC POS副屏推广轮播图"),
                    ]),
                    _c("accessory-upload", {
                      staticClass: "marB20",
                      attrs: {
                        listType: "picture-card",
                        limit: 10,
                        title: "",
                        fileList: _vm.fileListCarousel,
                      },
                      on: {
                        getFileItems: _vm.getFileItemsData,
                        delFileItems: _vm.delFileItems,
                      },
                    }),
                    _c(
                      "div",
                      { staticClass: "x-f marB20" },
                      [
                        _c("span", { staticClass: "fontS14 marR20" }, [
                          _vm._v("轮播间隔时长"),
                        ]),
                        _c("el-input", {
                          staticClass: "inputWidth marR10",
                          attrs: { type: "number" },
                          model: {
                            value: _vm.posImageForm.carouselSecond,
                            callback: function ($$v) {
                              _vm.$set(_vm.posImageForm, "carouselSecond", $$v)
                            },
                            expression: "posImageForm.carouselSecond",
                          },
                        }),
                        _c("span", { staticClass: "fontS14" }, [_vm._v("秒")]),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "marB10 x-f" },
                      [
                        _c("span", { staticClass: "fontS14 marR10" }, [
                          _vm._v("购物车占屏幕的百分比"),
                        ]),
                        _c(
                          "span",
                          { staticClass: "fontS14 annotateGrey marR20" },
                          [_vm._v("（不选，默认值为40%）")]
                        ),
                        _c(
                          "el-select",
                          {
                            staticClass: "inputRemark",
                            attrs: {
                              placeholder: "全部",
                              filterable: "",
                              clearable: "",
                              size: "mini",
                            },
                            model: {
                              value: _vm.posImageForm.carouselSizeType,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.posImageForm,
                                  "carouselSizeType",
                                  $$v
                                )
                              },
                              expression: "posImageForm.carouselSizeType",
                            },
                          },
                          _vm._l(_vm.resolutionList, function (item) {
                            return _c("el-option", {
                              key: item.resolutionId,
                              attrs: {
                                label: item.resolutionName,
                                value: item.resolutionId,
                              },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                    _c("div", { staticClass: "fontS14 annotateGrey" }, [
                      _vm._v(
                        " 推荐： 轮播图高度：屏幕分辨率的高度；轮播图宽度：屏幕分辨率的宽度 - 屏幕分辨率的宽度 * 购物车占屏幕的百分比 "
                      ),
                    ]),
                  ],
                  1
                ),
              ]),
            ],
            2
          ),
          _c(
            "cardTitleCom",
            { attrs: { cardTitle: "适用门店" } },
            [
              _c("template", { slot: "cardContent" }, [
                _c("div", { staticClass: "tableContent" }, [
                  _c(
                    "div",
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "适用门店", prop: "shopRangeType" } },
                        [
                          _c(
                            "el-radio-group",
                            {
                              model: {
                                value: _vm.posImageForm.shopRangeType,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.posImageForm,
                                    "shopRangeType",
                                    $$v
                                  )
                                },
                                expression: "posImageForm.shopRangeType",
                              },
                            },
                            _vm._l(
                              _vm.dict.type.fill_shop_range_type,
                              function (dict) {
                                return _c(
                                  "el-radio",
                                  {
                                    key: dict.value,
                                    attrs: { label: parseInt(dict.value) },
                                  },
                                  [_vm._v(_vm._s(dict.label))]
                                )
                              }
                            ),
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value:
                                _vm.posImageForm.shopRangeType == 1 ||
                                _vm.posImageForm.shopRangeType == 2,
                              expression:
                                "\n                posImageForm.shopRangeType == 1 ||\n                posImageForm.shopRangeType == 2\n              ",
                            },
                          ],
                          staticClass: "marL20",
                        },
                        [
                          _c(
                            "el-button",
                            {
                              staticClass: "marB10",
                              attrs: { size: "mini" },
                              on: {
                                click: function ($event) {
                                  _vm.openShopInfo = true
                                },
                              },
                            },
                            [_vm._v("选择门店")]
                          ),
                          _c(
                            "el-table",
                            {
                              staticStyle: { width: "100%" },
                              attrs: {
                                "tooltip-effect": "dark",
                                data: _vm.posImageForm.shopIds,
                                border: "",
                                "max-height": "500",
                              },
                            },
                            [
                              _c("el-table-column", {
                                attrs: {
                                  label: "序号",
                                  align: "center",
                                  prop: "userId",
                                  width: "80",
                                  type: "index",
                                },
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  align: "center",
                                  label: "操作",
                                  width: "120",
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c(
                                          "el-button",
                                          {
                                            attrs: {
                                              type: "text",
                                              size: "mini",
                                            },
                                            nativeOn: {
                                              click: function ($event) {
                                                $event.preventDefault()
                                                return _vm.deleteRow(
                                                  scope.$index,
                                                  _vm.posImageForm.shopIds
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c("i", {
                                              staticClass:
                                                "el-icon-remove operateDel",
                                            }),
                                          ]
                                        ),
                                      ]
                                    },
                                  },
                                ]),
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  label: "门店编号",
                                  width: "200",
                                  align: "center",
                                  prop: "shopNo",
                                },
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  label: "门店名称",
                                  align: "center",
                                  width: "200",
                                  prop: "shopName",
                                },
                              }),
                              _c("el-table-column"),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
              ]),
            ],
            2
          ),
        ],
        1
      ),
      _c("shopInfo", {
        attrs: { OpenShopInfo: _vm.openShopInfo },
        on: {
          "update:OpenShopInfo": function ($event) {
            _vm.openShopInfo = $event
          },
          "update:open-shop-info": function ($event) {
            _vm.openShopInfo = $event
          },
          shopInfoList: _vm.shopInfoListCom,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }