<template>
  <!-- 右边装饰小票区域 -->
  <div class="right-decorate">
    <div class="title">
      <span v-if="newForm.type">
        {{ `[${newForm.name}]` }}
      </span>
      内容编辑
    </div>
    <el-scrollbar v-if="newForm.type">
      <template v-if="newForm.type === 'text'">
        <div class="row">
          <div class="label">文字内容:</div>
          <el-input v-model="newForm.label"></el-input>
        </div>
        <div class="row">
          <div class="label">整行:</div>
          <el-switch v-model="newForm.style.width" active-value="100" inactive-value="50">
          </el-switch>
        </div>
        <div class="row">
          <div class="label">字体:</div>
          <el-select v-model="newForm.style.fontFamily" placeholder="请选择">
            <el-option
              v-for="item in options.fontFamily"
              :key="item"
              :label="item"
              :value="item"
            >
            </el-option>
          </el-select>
        </div>
        <div class="row">
          <div class="label">参数名字号:</div>
          <el-input type="number" v-model="newForm.labelStyle.fontSize"></el-input>
        </div>
        <div class="row">
          <div class="label">参数值字号:</div>
          <el-input type="number" v-model="newForm.style.fontSize"></el-input>
        </div>
        <div class="row">
          <div class="label">行高:</div>
          <el-input type="number" v-model="newForm.style.lineHeight"></el-input>
        </div>
        <div class="row">
          <div class="label">字体粗细:</div>
          <el-select v-model="newForm.style.fontWeight" placeholder="请选择">
            <el-option
              v-for="item in options.fontWeight"
              :key="item.label"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
        <div class="row">
          <div class="label">字体风格:</div>
          <el-select v-model="newForm.style.fontStyle" placeholder="请选择">
            <el-option
              v-for="item in options.fontStyle"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
        <div class="row pad">
          <div class="label">内边距:</div>
          <div class="pads">
            <div class="line">
              左内边距<el-input
                type="number"
                v-model="newForm.style.paddingLeft"
              ></el-input>
            </div>
            <div class="line">
              上内边距<el-input
                type="number"
                v-model="newForm.style.paddingTop"
              ></el-input>
            </div>
            <div class="line">
              右内边距<el-input
                type="number"
                v-model="newForm.style.paddingRight"
              ></el-input>
            </div>
            <div class="line">
              下内边距<el-input
                type="number"
                v-model="newForm.style.paddingBottom"
              ></el-input>
            </div>
          </div>
        </div>
        <div class="row mar">
          <div class="label">外边距:</div>
          <div class="mars">
            <div class="line">
              左外边距<el-input
                type="number"
                v-model="newForm.style.marginLeft"
              ></el-input>
            </div>
            <div class="line">
              上外边距<el-input
                type="number"
                v-model="newForm.style.marginTop"
              ></el-input>
            </div>
            <div class="line">
              右外边距<el-input
                type="number"
                v-model="newForm.style.marginRight"
              ></el-input>
            </div>
            <div class="line">
              下外边距<el-input
                type="number"
                v-model="newForm.style.marginBottom"
              ></el-input>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="label">位置:</div>
          <el-radio-group v-model="newForm.style.textAlign">
            <el-radio
              :label="item.value"
              v-for="item in options.textAlign"
              :key="item.value"
              >{{ item.label }}</el-radio
            >
          </el-radio-group>
        </div>
      </template>
      <template
        v-else-if="['goods', 'goods2'].includes(newForm.type) && newForm.children"
      >
        <div class="row" v-for="(item, index) in newForm.children" :key="item.name">
          <div class="label">{{ item.label }}:</div>
          <el-switch v-model="item.show"></el-switch>
          <el-input
            v-if="newForm.type === 'goods'"
            type="number"
            v-model="item.style.width"
            placeholder="占比"
            style="width: 100px"
          ></el-input>
          <el-input style="width: 150px" v-model="item.label"></el-input>
        </div>
        <div class="row">
          <div class="label">字体:</div>
          <el-select v-model="newForm.style.fontFamily" placeholder="请选择">
            <el-option
              v-for="item in options.fontFamily"
              :key="item"
              :label="item"
              :value="item"
            >
            </el-option>
          </el-select>
        </div>
        <div class="row">
          <div class="label">参数名字号:</div>
          <el-input type="number" v-model="newForm.labelStyle.fontSize"></el-input>
        </div>
        <div class="row">
          <div class="label">参数值字号:</div>
          <el-input type="number" v-model="newForm.style.fontSize"></el-input>
        </div>
        <div class="row">
          <div class="label">行高:</div>
          <el-input type="number" v-model="newForm.style.lineHeight"></el-input>
        </div>
        <div class="row">
          <div class="label">字体粗细:</div>
          <el-select v-model="newForm.style.fontWeight" placeholder="请选择">
            <el-option
              v-for="item in options.fontWeight"
              :key="item.label"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
        <div class="row">
          <div class="label">字体风格:</div>
          <el-select v-model="newForm.style.fontStyle" placeholder="请选择">
            <el-option
              v-for="item in options.fontStyle"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
        <div class="row">
          <div class="label">位置:</div>
          <el-radio-group v-model="newForm.style.textAlign">
            <el-radio
              :label="item.value"
              v-for="item in options.textAlign"
              :key="item.value"
              >{{ item.label }}</el-radio
            >
          </el-radio-group>
        </div>
      </template>
      <template v-else-if="newForm.type === 'img'">
        <div class="row" style="height: 180px" v-if="!newForm.disable">
          <div class="label">{{ newForm.label }}</div>
          <SingleImageUpload
            @getImageUrl="handleImageUrl($event)"
            @delImage="handleImageUrl()"
            :fileList="newForm.value ? [{ url: newForm.value }] : []"
          />
        </div>
      </template>
      <div class="row" v-if="newForm.type">
        <div class="label">操作:</div>
        <span style="color: #1890ff; cursor: pointer" @click="$emit('delParameter')"
          >删除参数</span
        >
      </div>
    </el-scrollbar>
    <div v-else class="tip">无编辑的内容</div>
  </div>
</template>

<script>
import SingleImageUpload from "@/views/components/singleImageUpload"; //单图片上传组件
export default {
  name: "RightDecorate",
  props: {
    //小票模板
    receiptFormat: {
      type: Object,
    },
    //设置模板类型
    setTemplateType: {
      type: String,
    },
    form: {
      type: Object,
      default: () => {},
    },
  },
  components: { SingleImageUpload },
  data() {
    return {
      //小票规格列表
      receiptSpecList: [
        { type: 58, length: "58mm", tag: "每行16个汉字" },
        { type: 80, length: "80mm", tag: "每行24个汉字" },
        { type: 110, length: "110mm", tag: "每行32个汉字" },
      ],
      //配置项
      options: {
        fontFamily: ["微软雅黑", "宋体", "黑体", "楷体"],
        fontStyle: [
          { label: "正常", value: "normal" },
          { label: "斜体", value: "italic" },
          { label: "倾斜", value: "oblique" },
        ],
        fontWeight: [
          { label: "极细", value: 100 },
          { label: "非常细", value: 200 },
          { label: "细", value: 300 },
          { label: "标准", value: 400 },
          { label: "加粗", value: 600 },
          { label: "半粗", value: 600 },
          { label: "粗", value: 600 },
          { label: "非常粗", value: 700 },
          { label: "特黑", value: 800 },
          { label: "极黑", value: 900 },
        ],
        textAlign: [
          { label: "居左", value: "left" },
          { label: "居中", value: "center" },
          { label: "居右", value: "right" },
        ],
      },
    };
  },
  computed: {
    newForm: {
      get() {
        return this.form;
      },
      set(e) {
        this.$emit("update:form", e);
      },
    },
  },
  methods: {
    //操作图片
    handleImageUrl(url) {
      this.newForm.value = url ? url : undefined;
    },
  },
};
</script>

<style lang="scss" scoped>
.right-decorate {
  padding: 20px;
  .title {
    font-size: 18px;
    margin-bottom: 10px;
  }
  .el-scrollbar {
    height: 100%;
    overflow: hidden;
    ::v-deep .el-scrollbar__wrap {
      overflow-x: hidden;
    }
  }
  .row {
    display: flex;
    font-size: 14px;
    border: 1px solid #ccc;
    color: #555;
    margin: 10px 0;
    height: 50px;
    display: flex;
    align-items: center;
    padding: 0 12px;
    .label {
      width: 100px;
    }
    &.pad,
    &.mar {
      height: 60px;
      .pads,
      .mars {
        display: flex;
        flex-wrap: wrap;
        font-size: 12px;
        width: calc(100% - 100px);
      }
      .line {
        width: 50%;
        display: flex;
      }
      ::v-deep .el-input--medium {
        width: 100px !important;
      }
      ::v-deep .el-input__inner {
        padding: 0 !important;
        height: 20px !important;
        width: 100px !important;
      }
    }
  }
  .tip {
    color: #ccc;
  }
}
::v-deep .el-input__inner {
  border: none !important;
  box-shadow: none !important;
}
</style>
