var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "wrap",
      attrs: { id: "wrap" },
    },
    [
      _c(
        "div",
        [
          _c(
            "cardTitleCom",
            { attrs: { cardTitle: "查询", id: "search-card" } },
            [
              _c("template", { slot: "cardContent" }, [
                _c(
                  "div",
                  { staticClass: "navSearch" },
                  [
                    _c("seniorSearch", {
                      attrs: {
                        getList: _vm.getList,
                        dropDownList: _vm.dropDownList,
                        isBillDate: false,
                        isSearchInput: true,
                        isSearchInputTitle: "输入导购员编号、名称",
                      },
                      on: { isShowHighCom: _vm.getIsShowHigh },
                      model: {
                        value: _vm.queryParams,
                        callback: function ($$v) {
                          _vm.queryParams = $$v
                        },
                        expression: "queryParams",
                      },
                    }),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.showHigh,
                            expression: "showHigh",
                          },
                        ],
                        staticClass: "marT10",
                      },
                      [
                        _c(
                          "div",
                          [
                            _c("span", { staticClass: "fS14MR10" }, [
                              _vm._v("提成方式"),
                            ]),
                            _c(
                              "el-select",
                              {
                                staticStyle: { width: "170px" },
                                attrs: {
                                  size: "mini",
                                  placeholder: "全部",
                                  filterable: "",
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.queryParams.commissionType,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.queryParams,
                                      "commissionType",
                                      $$v
                                    )
                                  },
                                  expression: "queryParams.commissionType",
                                },
                              },
                              [
                                _c("el-option", {
                                  attrs: { label: "按导购员", value: 1 },
                                }),
                                _c("el-option", {
                                  attrs: { label: "按提成方案", value: 2 },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ]),
            ],
            2
          ),
          _c(
            "cardTitleCom",
            {
              staticClass: "headGoodsTable",
              attrs: { cardTitle: "导购员信息" },
            },
            [
              _c("template", { slot: "cardContent" }, [
                _c(
                  "div",
                  { staticClass: "tableContent" },
                  [
                    _c(
                      "operatingButton",
                      {
                        attrs: {
                          getList: _vm.getList,
                          isRecycleBtn: true,
                          isDeleteBtn: false,
                          multiple: _vm.multiple,
                        },
                        on: {
                          handleAdd: _vm.handleAdd,
                          handleDelete: _vm.handleDelete,
                          handleRedoDelete: function ($event) {
                            _vm.dialogOptions.show = true
                          },
                        },
                      },
                      [
                        _c(
                          "template",
                          { slot: "specialDiyBtn" },
                          [
                            _c(
                              "el-button",
                              {
                                staticClass: "iconfont icon-jiesuan",
                                attrs: { type: "primary", size: "mini" },
                                on: { click: _vm.getSettlement },
                              },
                              [_vm._v("结算 ")]
                            ),
                          ],
                          1
                        ),
                      ],
                      2
                    ),
                    _c(
                      "el-table",
                      {
                        directives: [
                          {
                            name: "loading",
                            rawName: "v-loading",
                            value: _vm.loadingTable,
                            expression: "loadingTable",
                          },
                        ],
                        ref: "multipleTable",
                        attrs: {
                          "tooltip-effect": "dark",
                          data: _vm.tableData,
                          border: "",
                          "max-height": _vm.tableHeight,
                          height: _vm.tableHeight,
                        },
                        on: {
                          "selection-change": _vm.handleSelectionChange,
                          "row-click": _vm.handleRowClick,
                        },
                      },
                      [
                        _c("el-table-column", {
                          attrs: {
                            type: "selection",
                            width: "50",
                            align: "center",
                          },
                        }),
                        _c("el-table-column", {
                          attrs: {
                            label: "序号",
                            align: "center",
                            prop: "userId",
                            width: "80",
                            type: "index",
                          },
                        }),
                        _c("el-table-column", {
                          attrs: {
                            "show-overflow-tooltip": "",
                            label: "导购员编号",
                            align: "center",
                            prop: "guideManNo",
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c(
                                    "el-link",
                                    {
                                      attrs: {
                                        type: "primary",
                                        target: "_blank",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.handleDetail(scope.row)
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " + _vm._s(scope.row.guideManNo) + " "
                                      ),
                                    ]
                                  ),
                                ]
                              },
                            },
                          ]),
                        }),
                        _c("el-table-column", {
                          attrs: {
                            label: "导购员名称",
                            align: "center",
                            prop: "guideManName",
                          },
                        }),
                        _c("el-table-column", {
                          attrs: {
                            label: "提成方式",
                            align: "center",
                            prop: "commissionType",
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        scope.row.commissionType == 1
                                          ? "按导购员"
                                          : "按提成方案"
                                      )
                                    ),
                                  ]),
                                ]
                              },
                            },
                          ]),
                        }),
                        _c("el-table-column", {
                          attrs: {
                            label: "提成方案名",
                            align: "center",
                            prop: "commissionBillName",
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        scope.row.commissionType == 1
                                          ? ""
                                          : scope.row.commissionBillName
                                      )
                                    ),
                                  ]),
                                ]
                              },
                            },
                          ]),
                        }),
                        _c("el-table-column", {
                          attrs: {
                            label: "销售提成率%",
                            align: "center",
                            prop: "commissionSaleRate",
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        scope.row.commissionType == 2
                                          ? ""
                                          : scope.row.commissionSaleRate
                                      )
                                    ),
                                  ]),
                                ]
                              },
                            },
                          ]),
                        }),
                        _c("el-table-column", {
                          attrs: {
                            label: "充值提成率%",
                            align: "center",
                            prop: "commissionFillRate",
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        scope.row.commissionType == 2
                                          ? ""
                                          : scope.row.commissionFillRate
                                      )
                                    ),
                                  ]),
                                ]
                              },
                            },
                          ]),
                        }),
                        _c("el-table-column", {
                          attrs: {
                            label: "未结算提成",
                            align: "center",
                            prop: "totalUsableCommission",
                          },
                        }),
                        _c("el-table-column", {
                          attrs: {
                            label: "累计结算提成",
                            align: "center",
                            prop: "totalSettlementCommission",
                          },
                        }),
                      ],
                      1
                    ),
                    _c("bottomPage", {
                      attrs: {
                        getList: _vm.getList,
                        idsCom: _vm.ids,
                        totalCom: _vm.total,
                      },
                      on: { selectAllCom: _vm.selectAll },
                      model: {
                        value: _vm.queryParams,
                        callback: function ($$v) {
                          _vm.queryParams = $$v
                        },
                        expression: "queryParams",
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ],
            2
          ),
        ],
        1
      ),
      _c("c-dialog", {
        attrs: {
          title: _vm.guideManTitle,
          width: 900,
          showDialog: _vm.dialogVisible,
        },
        on: {
          "update:showDialog": function ($event) {
            _vm.dialogVisible = $event
          },
          "update:show-dialog": function ($event) {
            _vm.dialogVisible = $event
          },
        },
        scopedSlots: _vm._u([
          {
            key: "content",
            fn: function () {
              return [
                _c(
                  "el-form",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.loadingGuideManDia,
                        expression: "loadingGuideManDia",
                      },
                    ],
                    ref: "form",
                    attrs: {
                      model: _vm.guideManForm,
                      rules: _vm.rules,
                      "label-width": "130px",
                    },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "x-x marT30" },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "导购员",
                              prop: "guideManId",
                              "label-width": "80px",
                            },
                          },
                          [
                            _c("SelectRemote", {
                              staticStyle: { width: "170px" },
                              attrs: {
                                option: _vm.$select({ key: "listEmployee" })
                                  .option,
                              },
                              on: { selectChange: _vm.guideManChange },
                              model: {
                                value: _vm.guideManForm.guideManId,
                                callback: function ($$v) {
                                  _vm.$set(_vm.guideManForm, "guideManId", $$v)
                                },
                                expression: "guideManForm.guideManId",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          { attrs: { label: "导购员编号" } },
                          [
                            _c("el-input", {
                              staticStyle: { width: "170px" },
                              attrs: { disabled: "" },
                              model: {
                                value: _vm.guideManForm.guideManNo,
                                callback: function ($$v) {
                                  _vm.$set(_vm.guideManForm, "guideManNo", $$v)
                                },
                                expression: "guideManForm.guideManNo",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "导购员名称",
                              "label-width": "130px",
                            },
                          },
                          [
                            _c("el-input", {
                              staticStyle: { width: "170px" },
                              attrs: { disabled: "" },
                              model: {
                                value: _vm.guideManForm.guideManName,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.guideManForm,
                                    "guideManName",
                                    $$v
                                  )
                                },
                                expression: "guideManForm.guideManName",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "x-x" },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "提成方式",
                              prop: "commissionType",
                              "label-width": "80px",
                            },
                          },
                          [
                            _c(
                              "el-select",
                              {
                                staticStyle: { width: "170px" },
                                attrs: {
                                  placeholder: "全部",
                                  filterable: "",
                                  clearable: "",
                                },
                                on: { change: _vm.commissionTypeChange },
                                model: {
                                  value: _vm.guideManForm.commissionType,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.guideManForm,
                                      "commissionType",
                                      $$v
                                    )
                                  },
                                  expression: "guideManForm.commissionType",
                                },
                              },
                              [
                                _c("el-option", {
                                  attrs: { label: "按导购员", value: 1 },
                                }),
                                _c("el-option", {
                                  attrs: { label: "按提成方案", value: 2 },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm.guideManForm.commissionType == 2
                          ? _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "提成方案名",
                                  prop: "commissionBillId",
                                },
                              },
                              [
                                _c(
                                  "el-select",
                                  {
                                    staticStyle: { width: "170px" },
                                    attrs: {
                                      placeholder: "全部",
                                      filterable: "",
                                      clearable: "",
                                    },
                                    model: {
                                      value: _vm.guideManForm.commissionBillId,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.guideManForm,
                                          "commissionBillId",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "guideManForm.commissionBillId",
                                    },
                                  },
                                  _vm._l(_vm.commissionList, function (item) {
                                    return _c("el-option", {
                                      key: item.billId,
                                      attrs: {
                                        label: item.billName,
                                        value: item.billId,
                                      },
                                    })
                                  }),
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.guideManForm.commissionType == 1
                          ? _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "销售提成率(%)",
                                  prop: "commissionSaleRate",
                                },
                              },
                              [
                                _c("el-input", {
                                  staticStyle: { width: "170px" },
                                  attrs: { type: "number" },
                                  model: {
                                    value: _vm.guideManForm.commissionSaleRate,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.guideManForm,
                                        "commissionSaleRate",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "guideManForm.commissionSaleRate",
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.guideManForm.commissionType == 1
                          ? _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "充值提成率(%)",
                                  prop: "commissionFillRate",
                                },
                              },
                              [
                                _c("el-input", {
                                  staticStyle: { width: "170px" },
                                  attrs: { type: "number" },
                                  model: {
                                    value: _vm.guideManForm.commissionFillRate,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.guideManForm,
                                        "commissionFillRate",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "guideManForm.commissionFillRate",
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "x-x" },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "备注", "label-width": "80px" } },
                          [
                            _c("el-input", {
                              staticClass: "inputWidthM",
                              attrs: { type: "textarea", rows: 2 },
                              model: {
                                value: _vm.guideManForm.remark,
                                callback: function ($$v) {
                                  _vm.$set(_vm.guideManForm, "remark", $$v)
                                },
                                expression: "guideManForm.remark",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "buttonList",
            fn: function () {
              return [
                _c(
                  "el-button",
                  { attrs: { size: "mini" }, on: { click: _vm.cancel } },
                  [_vm._v("取消")]
                ),
                _c(
                  "el-button",
                  {
                    attrs: { size: "mini", type: "primary" },
                    on: { click: _vm.getConfirm },
                  },
                  [_vm._v("确定")]
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c("c-dialog", {
        attrs: {
          title: "导购员结算",
          width: 700,
          showDialog: _vm.dialogSettlement,
        },
        on: {
          "update:showDialog": function ($event) {
            _vm.dialogSettlement = $event
          },
          "update:show-dialog": function ($event) {
            _vm.dialogSettlement = $event
          },
        },
        scopedSlots: _vm._u([
          {
            key: "content",
            fn: function () {
              return [
                _c(
                  "el-form",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.loadingSettlementDia,
                        expression: "loadingSettlementDia",
                      },
                    ],
                    ref: "settlementForm",
                    attrs: {
                      model: _vm.settlementForm,
                      rules: _vm.settlementRules,
                      "label-width": "120px",
                    },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "x-x" },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "导购员" } },
                          [
                            _c("el-input", {
                              staticStyle: { width: "170px" },
                              attrs: { disabled: "", placeholder: "导购员" },
                              model: {
                                value: _vm.settlementForm.guideManName,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.settlementForm,
                                    "guideManName",
                                    $$v
                                  )
                                },
                                expression: "settlementForm.guideManName",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          { attrs: { label: "累计结算提成" } },
                          [
                            _c("el-input", {
                              staticStyle: { width: "170px" },
                              attrs: {
                                disabled: "",
                                placeholder: "累计结算提成",
                              },
                              model: {
                                value:
                                  _vm.settlementForm.totalSettlementCommission,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.settlementForm,
                                    "totalSettlementCommission",
                                    $$v
                                  )
                                },
                                expression:
                                  "settlementForm.totalSettlementCommission",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "x-x" },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "本次销售金额" } },
                          [
                            _c("el-input", {
                              staticStyle: { width: "170px" },
                              attrs: {
                                disabled: "",
                                placeholder: "本次销售金额",
                              },
                              model: {
                                value: _vm.settlementForm.nowSaleMoney,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.settlementForm,
                                    "nowSaleMoney",
                                    $$v
                                  )
                                },
                                expression: "settlementForm.nowSaleMoney",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          { attrs: { label: "本次销售提成" } },
                          [
                            _c("el-input", {
                              staticStyle: { width: "170px" },
                              attrs: {
                                disabled: "",
                                placeholder: "本次销售提成",
                              },
                              model: {
                                value: _vm.settlementForm.nowSaleCommission,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.settlementForm,
                                    "nowSaleCommission",
                                    $$v
                                  )
                                },
                                expression: "settlementForm.nowSaleCommission",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "x-x" },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "本次充值金额" } },
                          [
                            _c("el-input", {
                              staticStyle: { width: "170px" },
                              attrs: {
                                disabled: "",
                                placeholder: "本次充值金额",
                              },
                              model: {
                                value: _vm.settlementForm.nowFillMoney,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.settlementForm,
                                    "nowFillMoney",
                                    $$v
                                  )
                                },
                                expression: "settlementForm.nowFillMoney",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          { attrs: { label: "本次充值提成" } },
                          [
                            _c("el-input", {
                              staticStyle: { width: "170px" },
                              attrs: {
                                disabled: "",
                                placeholder: "本次充值提成",
                              },
                              model: {
                                value: _vm.settlementForm.nowFillCommission,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.settlementForm,
                                    "nowFillCommission",
                                    $$v
                                  )
                                },
                                expression: "settlementForm.nowFillCommission",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "x-x" },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "未结算提成" } },
                          [
                            _c("el-input", {
                              staticStyle: { width: "170px" },
                              attrs: {
                                disabled: "",
                                placeholder: "未结算提成",
                              },
                              model: {
                                value: _vm.settlementForm.totalUsableCommission,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.settlementForm,
                                    "totalUsableCommission",
                                    $$v
                                  )
                                },
                                expression:
                                  "settlementForm.totalUsableCommission",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "本次结算",
                              prop: "nowSettlementMoney",
                            },
                          },
                          [
                            _c("el-input", {
                              staticStyle: { width: "170px" },
                              attrs: { placeholder: "本次结算" },
                              model: {
                                value: _vm.settlementForm.nowSettlementMoney,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.settlementForm,
                                    "nowSettlementMoney",
                                    $$v
                                  )
                                },
                                expression: "settlementForm.nowSettlementMoney",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "buttonList",
            fn: function () {
              return [
                _c(
                  "el-button",
                  {
                    attrs: { type: "primary" },
                    on: { click: _vm.settlementConfirm },
                  },
                  [_vm._v("确定")]
                ),
                _c("el-button", { on: { click: _vm.settlementCancel } }, [
                  _vm._v("取消"),
                ]),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c("Dialog2", {
        attrs: { options: _vm.dialogOptions },
        on: {
          "update:options": function ($event) {
            _vm.dialogOptions = $event
          },
          handleEvent: _vm.handleEvent,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }