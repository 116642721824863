<template>
  <div class="wrap" v-loading="loading" id="wrap">
    <!-- 表格和搜索右边盒子 -->
    <div>
      <cardTitleCom cardTitle="查询" id="search-card">
        <template slot="cardContent">
          <div class="navSearch">
            <!-- 高级搜索按钮 -->
            <seniorSearch v-model="queryParams" :getList="getList" :dropDownList="dropDownList" :isBillDate="false"
              @isShowHighCom="getIsShowHigh" :isSearchInput="true" isSearchInputTitle="输入导购员编号、名称" />
            <!-- 条件 -->
            <div class="marT10" v-show="showHigh">
              <div>
                <span class="fS14MR10">提成方式</span>
                <el-select size="mini" style="width: 170px" v-model="queryParams.commissionType" placeholder="全部"
                  filterable clearable>
                  <el-option label="按导购员" :value="1"></el-option>
                  <el-option label="按提成方案" :value="2"></el-option>
                </el-select>
              </div>
            </div>
          </div>
        </template>
      </cardTitleCom>
      <!-- 表格商品信息卡片 -->
      <cardTitleCom cardTitle="导购员信息" class="headGoodsTable">
        <template slot="cardContent">
          <!-- 商品信息按钮和表格整体盒子 -->
          <div class="tableContent">
            <!-- 操作按钮 -->
            <operatingButton :getList="getList" :isRecycleBtn="true" :isDeleteBtn="false" :multiple="multiple"
              @handleAdd="handleAdd" @handleDelete="handleDelete" @handleRedoDelete="dialogOptions.show = true">
              <template slot="specialDiyBtn">
                <el-button type="primary" class="iconfont icon-jiesuan" size="mini" @click="getSettlement">结算
                </el-button>
              </template>
            </operatingButton>
            <el-table v-loading="loadingTable" tooltip-effect="dark" :data="tableData"
              @selection-change="handleSelectionChange" border ref="multipleTable" @row-click="handleRowClick"
              :max-height="tableHeight" :height="tableHeight">
              <el-table-column type="selection" width="50" align="center" />
              <el-table-column label="序号" align="center" prop="userId" width="80" type="index" />
              <el-table-column show-overflow-tooltip label="导购员编号" align="center" prop="guideManNo">
                <template slot-scope="scope">
                  <el-link type="primary" @click="handleDetail(scope.row)" target="_blank">
                    {{ scope.row.guideManNo }}
                  </el-link>
                </template>
              </el-table-column>
              <el-table-column label="导购员名称" align="center" prop="guideManName" />
              <el-table-column label="提成方式" align="center" prop="commissionType">
                <template slot-scope="scope">
                  <span>{{
                    scope.row.commissionType == 1 ? '按导购员' : '按提成方案'
                  }}</span>
                </template>
              </el-table-column>

              <el-table-column label="提成方案名" align="center" prop="commissionBillName"><template slot-scope="scope">
                  <span>{{
                    scope.row.commissionType == 1
                      ? ''
                      : scope.row.commissionBillName
                  }}</span>
                </template>
              </el-table-column>

              <el-table-column label="销售提成率%" align="center" prop="commissionSaleRate">
                <template slot-scope="scope">
                  <span>{{
                    scope.row.commissionType == 2
                      ? ''
                      : scope.row.commissionSaleRate
                  }}</span>
                </template>
              </el-table-column>

              <el-table-column label="充值提成率%" align="center" prop="commissionFillRate"><template slot-scope="scope">
                  <span>{{
                    scope.row.commissionType == 2
                      ? ''
                      : scope.row.commissionFillRate
                  }}</span>
                </template>
              </el-table-column>
              <el-table-column label="未结算提成" align="center" prop="totalUsableCommission" />
              <el-table-column label="累计结算提成" align="center" prop="totalSettlementCommission" />
            </el-table>
            <bottomPage v-model="queryParams" :getList="getList" :idsCom="ids" :totalCom="total"
              @selectAllCom="selectAll" />
          </div>
        </template>
      </cardTitleCom>
    </div>
    <!-- 导购 -->

    <!-- 导购员结算 -->
    <c-dialog :title="guideManTitle" :width="900" :showDialog.sync="dialogVisible">
      <template #content>
        <el-form :model="guideManForm" ref="form" :rules="rules" label-width="130px" v-loading="loadingGuideManDia">
          <div class="x-x marT30">
            <el-form-item label="导购员" prop="guideManId" label-width="80px">
              <SelectRemote @selectChange="guideManChange" v-model="guideManForm.guideManId" style="width: 170px"
                :option="$select({ key: 'listEmployee' }).option" />
            </el-form-item>
            <el-form-item label="导购员编号">
              <el-input v-model="guideManForm.guideManNo" style="width: 170px" disabled></el-input>
            </el-form-item>
            <el-form-item label="导购员名称" label-width="130px">
              <el-input v-model="guideManForm.guideManName" style="width: 170px" disabled></el-input>
            </el-form-item>
          </div>
          <div class="x-x">
            <el-form-item label="提成方式" prop="commissionType" label-width="80px">
              <el-select style="width: 170px" v-model="guideManForm.commissionType" @change="commissionTypeChange"
                placeholder="全部" filterable clearable>
                <el-option label="按导购员" :value="1"></el-option>
                <el-option label="按提成方案" :value="2"></el-option>
              </el-select>
            </el-form-item>

            <el-form-item label="提成方案名" prop="commissionBillId" v-if="guideManForm.commissionType == 2">
              <el-select style="width: 170px" v-model="guideManForm.commissionBillId" placeholder="全部" filterable
                clearable>
                <el-option v-for="item in commissionList" :key="item.billId" :label="item.billName"
                  :value="item.billId" />
              </el-select>
            </el-form-item>
            <el-form-item v-if="guideManForm.commissionType == 1" label="销售提成率(%)" prop="commissionSaleRate">
              <el-input type="number" v-model="guideManForm.commissionSaleRate" style="width: 170px"></el-input>
            </el-form-item>
            <el-form-item v-if="guideManForm.commissionType == 1" label="充值提成率(%)" prop="commissionFillRate">
              <el-input type="number" v-model="guideManForm.commissionFillRate" style="width: 170px"></el-input>
            </el-form-item>
          </div>
          <div class="x-x">
            <el-form-item label="备注" label-width="80px">
              <el-input v-model="guideManForm.remark" class="inputWidthM" type="textarea" :rows="2"></el-input>
            </el-form-item>
          </div>
        </el-form>
      </template>
      <template #buttonList>
        <el-button size="mini" @click="cancel">取消</el-button>
        <el-button size="mini" type="primary" @click="getConfirm">确定</el-button>
      </template>
    </c-dialog>

    <!-- 导购员结算 -->
    <c-dialog title="导购员结算" :width="700" :showDialog.sync="dialogSettlement">
      <template #content>
        <el-form :model="settlementForm" ref="settlementForm" :rules="settlementRules" label-width="120px"
          v-loading="loadingSettlementDia">
          <div class="x-x">
            <el-form-item label="导购员">
              <el-input disabled style="width: 170px" placeholder="导购员" v-model="settlementForm.guideManName" />
            </el-form-item>
            <el-form-item label="累计结算提成">
              <el-input disabled style="width: 170px" placeholder="累计结算提成"
                v-model="settlementForm.totalSettlementCommission" />
            </el-form-item>
          </div>
          <div class="x-x">
            <el-form-item label="本次销售金额">
              <el-input disabled style="width: 170px" placeholder="本次销售金额" v-model="settlementForm.nowSaleMoney" />
            </el-form-item>
            <el-form-item label="本次销售提成">
              <el-input disabled style="width: 170px" placeholder="本次销售提成" v-model="settlementForm.nowSaleCommission" />
            </el-form-item>
          </div>
          <div class="x-x">
            <el-form-item label="本次充值金额">
              <el-input disabled style="width: 170px" placeholder="本次充值金额" v-model="settlementForm.nowFillMoney" />
            </el-form-item>
            <el-form-item label="本次充值提成">
              <el-input disabled style="width: 170px" placeholder="本次充值提成" v-model="settlementForm.nowFillCommission" />
            </el-form-item>
          </div>
          <div class="x-x">
            <el-form-item label="未结算提成">
              <el-input disabled style="width: 170px" placeholder="未结算提成"
                v-model="settlementForm.totalUsableCommission" />
            </el-form-item>
            <el-form-item label="本次结算" prop="nowSettlementMoney">
              <el-input style="width: 170px" placeholder="本次结算" v-model="settlementForm.nowSettlementMoney" />
            </el-form-item>
          </div>
        </el-form>
      </template>
      <template #buttonList>
        <el-button type="primary" @click="settlementConfirm">确定</el-button>
        <el-button @click="settlementCancel">取消</el-button>
      </template>
    </c-dialog>
    <Dialog2 :options.sync="dialogOptions" @handleEvent="handleEvent" />
  </div>
</template>

<script>
import cardTitleCom from '@/views/components/cardTitleCom' //卡片标题
import bottomPage from '@/views/components/bottomPage' //底部分页
import seniorSearch from '@/views/components/seniorSearch' //高级搜索
import { listEmployee } from '@/api/system/employee' //员工
import { commissionListAPI } from '@/api/shop/saleGuide/commission' //导购员提成设置
import {
  guideManListAPI,
  guideManAddAPI,
  guideManDetailAPI,
  guideManUpdateAPI,
  guideManDeleteAPI,
  guideManSettlementAPI,
  restoreShopGuideMan
} from '@/api/shop/saleGuide/guideMan' //导购员
import operatingButton from '@/views/components/operatingButton' //操作按钮
import CDialog from '@/components/dialogTemplate/dialog/index.vue'
import Dialog2 from '@/components/Dialog' //回收站
import SelectRemote from '@/components/tablePage/select/select-remote/index.vue'
export default {
  name: 'guideMan',
  components: {
    bottomPage,
    cardTitleCom,
    seniorSearch,
    CDialog,
    operatingButton,
    Dialog2,
    SelectRemote
  },
  data() {
    return {
      dialogOptions: {
        type: 'ShopInfo',
        show: false,
        title: '回收站',
        width: '75vw',
        data: {
          getListApi: guideManListAPI,
          restoreListApi: restoreShopGuideMan,
          id: 'guideManId',
          columns: [
            {
              prop: 'guideManNo',
              label: '导购员编号',
              minWidth: 180
            },
            {
              prop: 'guideManName',
              label: '导购员名称',
              minWidth: 180
            },
            {
              prop: 'commissionTypeName',
              label: '提成方式',
              minWidth: 100
            },
            {
              prop: 'commissionBillName',
              label: '提成方案名',
              minWidth: 120
            },
            {
              prop: 'commissionSaleRate',
              label: '销售提成率%',
              minWidth: 120
            },
            {
              prop: 'commissionFillRate',
              label: '充值提成率%',
              minWidth: 120
            },
            {
              prop: 'totalUsableCommission',
              label: '未结算提成%',
              minWidth: 120
            },
            {
              prop: 'totalSettlementCommission',
              label: '累计结算提成%',
              minWidth: 120
            }
          ]
        }
      },
      //导购员提成
      commissionList: [],
      //员工
      employeeList: [],
      //搜索框搜索的条件
      dropDownList: [
        { parameter: 'query', name: '全部' },
        { parameter: 'guideManNos', name: '导购员编号' },
        { parameter: 'guideManNames', name: '导购员名称' }
      ],
      //导购员的弹窗标题
      guideManTitle: '导购员信息',
      //导购员结算的弹窗
      dialogSettlement: false,
      //导购员的弹窗
      dialogVisible: false,
      //是否显示高级搜索
      showHigh: false,
      //遮罩
      loading: false,
      //表格遮罩
      loadingTable: false,
      //导购员弹窗的遮罩
      loadingGuideManDia: false,
      //结算弹窗的遮罩
      loadingSettlementDia: false,
      ids: [], // 选中数组
      //表格
      tableData: [],
      //按钮单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      // 总条数
      total: 0,
      // 查询参数
      queryParams: {
        pageNum: 1,
        pageSize: 15
      },
      //结算表单
      settlementForm: {},
      //导购员的表单
      guideManForm: { commissionType: 1 },
      //结算校验规则
      settlementRules: {},
      //校验规则
      rules: {
        guideManId: [
          {
            required: true,
            message: '导购员不能为空',
            trigger: ['blur', 'change']
          }
        ],
        commissionType: [
          {
            required: true,
            message: '提成方式不能为空',
            trigger: ['blur', 'change']
          }
        ],
        commissionBillId: [
          {
            required: true,
            message: '提成方案名不能为空',
            trigger: ['blur', 'change']
          }
        ],
        commissionSaleRate: [
          {
            required: true,
            message: '销售提成率不能为空',
            trigger: ['blur', 'change']
          }
        ],
        commissionFillRate: [
          {
            required: true,
            message: '充值提成率不能为空',
            trigger: ['blur', 'change']
          }
        ]
      },
      //结算校验规则
      settlementRules: {
        nowSettlementMoney: [
          {
            required: true,
            message: '本次结算提成不能为空',
            trigger: ['blur', 'change']
          }
        ]
      },
      vivwH: 0, //页面高度
      searchH: 0, //搜索框高度
      paginationH: 40
    }
  },

  created() {
    /////初始化页面数据
    this.getInitializeData()
  },
  computed: {
    tableHeight() {
      return this.vivwH - this.paginationH - this.searchH - 130
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.vivwH = document.getElementById('wrap').clientHeight
      this.searchH = document.getElementById('search-card').clientHeight
    })
    window.addEventListener('resize', this.handleResize)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize)
  },
  methods: {
    //还原刷新页面
    handleEvent() {
      this.loading = true
      this.getList()
      this.loading = false
    },
    handleResize() {
      this.vivwH = document.getElementById('wrap').clientHeight
      this.searchH = document.getElementById('search-card').clientHeight
    },
    //切换提成方式
    commissionTypeChange() {
      if (this.guideManForm.commissionType == 1) {
        //清空提成方案
        this.guideManForm.commissionBillId = undefined
      } else {
        //清空充值、销售 提成率
        this.guideManForm.commissionFillRate = undefined
        this.guideManForm.commissionSaleRate = undefined
      }
    },
    //结算确定按钮
    async settlementConfirm() {
      if (parseFloat(this.settlementForm.nowSettlementMoney) <= 0) {
        this.$message.error('本次结算提成不能小于等于0')
        return
      }
      if (
        parseFloat(this.settlementForm.totalUsableCommission) <
        parseFloat(this.settlementForm.nowSettlementMoney)
      ) {
        this.$message.error('本次结算提成不能大于未结算提成')
        return
      }

      const res = await guideManSettlementAPI({
        guideManId: this.settlementForm.guideManId,
        nowSettlementMoney: this.settlementForm.nowSettlementMoney
      })
      this.$message({
        message: '结算成功',
        type: 'success'
      })
      this.dialogSettlement = false
      await this.getList()
      console.log(res)
    },
    //结算取消按钮
    settlementCancel() {
      this.dialogSettlement = false
      this.settlementForm = {}
      this.resetForm('settlementForm')
    },
    // 结算
    async getSettlement() {
      if (this.single) return this.$message.warning('请选择单个导购员进行结算')

      this.settlementForm = {}
      this.resetForm('settlementForm')
      this.dialogSettlement = true
      this.loadingSettlementDia = true
      const res = await guideManDetailAPI(this.ids[0])
      this.settlementForm = res.data
      this.loadingSettlementDia = false
    },
    //删除
    async handleDelete() {
      this.$modal
        .confirm('是否确认删除选中的方案吗？')
        .then(() => {
          return guideManDeleteAPI(this.ids) //删除请求
        })
        .then(() => {
          this.getList() //渲染列表
          this.$modal.msgSuccess('删除成功')
        })
        .catch(error => {
          console.log(error)
        })
    },
    //弹窗取消按钮
    cancel() {
      this.guideManReset()
      this.dialogVisible = false
    },
    //弹窗确认按钮  导购员
    async getConfirm() {
      this.$refs['form'].validate(async valid => {
        if (valid) {
          if (this.guideManTitle == '修改导购员信息') {
            await guideManUpdateAPI(this.guideManForm)
            this.$message({
              message: '修改成功',
              type: 'success'
            })
          } else {
            await guideManAddAPI(this.guideManForm)
            this.$message({
              message: '新增成功',
              type: 'success'
            })
          }

          this.dialogVisible = false
          await this.getList()
        }
      })
    },
    //重置 导购员
    guideManReset() {
      // 表单重置
      this.guideManForm = { commissionType: 1 }
      this.resetForm('form')
    },
    //弹窗切换导购员
    guideManChange(val) {
      if (this.guideManForm.guideManId) {
        this.employeeList.forEach(item => {
          if (item.employeeId == this.guideManForm.guideManId) {
            this.guideManForm.guideManNo = item.employeeNo
            this.guideManForm.guideManName = item.employeeName
          }
        })
      } else {
        this.guideManForm.guideManNo = undefined
        this.guideManForm.guideManName = undefined
      }
    },
    //初始化页面数据
    async getInitializeData() {
      this.loading = true
      //员工
      const res1 = await listEmployee()
      this.employeeList = res1.rows
      //导购员提成
      const res2 = await commissionListAPI({
        pageNum: 1,
        pageSize: 999
      })
      this.commissionList = res2.rows
      //导购员表格数据
      await this.getList()
      this.loading = false
    },
    // 表单重置
    reset() {
      this.guideManForm = {}
      this.resetForm('form')
    },

    //点击全选所有数据
    async selectAll(value) {
      if (value) {
        this.loadingTable = true
        const res = await guideManListAPI({
          ...this.queryParams,
          pageNum: 1,
          pageSize: this.total
        })
        //重新渲染表格
        this.tableData = res.rows
        //表格多选
        this.tableData.map(row => {
          this.$nextTick(() => {
            this.$refs.multipleTable.toggleRowSelection(row, value)
          })
        })
        this.loadingTable = false
      } else {
        //取消全选
        this.$nextTick(() => {
          this.$refs.multipleTable.clearSelection()
        })
        this.getList()
      }
    },
    /** 查询表格列表 */
    async getList() {
      this.loadingTable = true
      const { rows, total } = await guideManListAPI(this.queryParams)
      this.tableData = rows
      this.total = total
      this.loadingTable = false
    },

    //是否显示高级搜索
    getIsShowHigh(value) {
      this.showHigh = value
      this.$nextTick(() => {
        this.handleResize()
      })
    },
    //点击行选中
    handleRowClick(row) {
      // 获取表格对象
      // 切换行选中状态
      this.$refs.multipleTable.toggleRowSelection(row)
    },
    //新增单据事件
    handleAdd() {
      this.guideManReset()
      //判断是不是修改
      this.guideManTitle = '新增导购员信息'
      this.dialogVisible = true
    },
    //跳转详情
    async handleDetail(row) {
      this.guideManReset()
      //判断是不是修改
      this.guideManTitle = '修改导购员信息'
      this.dialogVisible = true
      this.loadingGuideManDia = true
      const res = await guideManDetailAPI(row.guideManId)
      this.guideManForm = res.data
      this.loadingGuideManDia = false
    },
    // 多选框选中数据
    handleSelectionChange(selection) {
      this.ids = selection.map(item => item.guideManId) //导购员id
      //按钮单个禁用
      if (selection.length != 1) {
        this.single = true
      } else {
        this.single = false
      }
      //按钮多个禁用

      this.multiple = !selection.length
    }
  }
}
</script>
<style scoped lang="scss">
//整体盒子
.wrap {
  padding: 10px 10px 0 10px;
  background-color: #eaeaea;
  height: calc(100vh - 84px);
  //阿里图标
  .icon-jiesuan {
    font-family: "iconfont" !important;
    font-size: 14px;
    font-style: normal;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    ::v-deep span {
      margin-left: 5px;
      font-size: 12px;
    }
    height: 28px;
    padding-top: 6px;
  }
  // 搜索
  .navSearch {
    padding: 10px 10px 10px 20px;
  }

  //商品表格卡片
  .headGoodsTable {
    height: calc(100% - 94px);
    .tableContent {
      margin: 6px 10px 10px 10px;
    }
  }
  //输入框原生属性
  /**
 * 解决el-input设置类型为number时，中文输入法光标上移问题
 **/
  ::v-deep .el-input__inner {
    line-height: 1px !important;
  }

  /**
 * 解决el-input设置类型为number时，去掉输入框后面上下箭头
 **/
  ::v-deep input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}
</style>
