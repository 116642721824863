<template>
  <div class="templateSet">
    <cardTitleCom cardTitle="模板设置">
      <template slot="cardContent">
        <div class="setList-content">
          <div class="setList-header">• 小票模板</div>
          <div class="setList-item x-bc" v-for="item in setList" :key="item.type">
            <div class="title">{{ item.title }}</div>
            <div class="introduce">{{ item.introduce }}</div>
            <div class="btn" @click="handleEditBtn(item.type, 0)">编辑</div>
            <div class="btn" @click="handleEditBtn(item.type, 1)">高级编辑</div>
          </div>
        </div>
      </template>
    </cardTitleCom>
  </div>
</template>

<script>
import cardTitleCom from "@/views/components/cardTitleCom"; //卡片标题
export default {
  name: "ReceiptFormat",
  components: { cardTitleCom },
  data() {
    return {
      //模板列表
      setList: [
        {
          type: "cashier",
          title: "收银小票",
          introduce: "收银台给顾客的小票，根据实际小票机类型，选择相应宽度的小票模板",
        },
        {
          type: "kitchenPrint",
          title: "厨打小票",
          introduce: "通知厨房做菜小票，支持不同种类菜品通知到相应厨房",
        },
        {
          type: "delivery",
          title: "配送小票",
          introduce:
            "外卖订单发货时打印的单据，便于送货员配货和发货，根据实际小票机类型，选择对应宽度的模板",
        },
        {
          type: "orderMake",
          title: "订单打印",
          introduce: "主要用于订单中心的制作打印,通知后厨、裱花间制作内容",
        },
      ],
    };
  },
  methods: {
    //编辑模板按钮
    handleEditBtn(type, formatType) {
      this.$router.push({
        path: "/shop/setup/receiptFormat/templateSet",
        query: {
          type,
          formatType,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.templateSet {
  background-color: #eaeaea;
  width: 100%;
  height: calc(100vh - 85px);
  padding: 20px;
  ::v-deep .cardCom {
    width: 1100px;
    margin: 0 auto;
  }
  .setList-content {
    height: 80vh;
    padding: 0 24px;
    .setList-header,
    .setList-item {
      height: 50px;
      line-height: 50px;
      border-bottom: 1px solid #ededed;
      font-size: 14px;
      .title {
        width: 110px;
        color: #1596fd;
        margin: 0;
      }
      .introduce {
        color: #666666;
        width: calc(100% - 300px);
      }
      .btn {
        height: 24px;
        line-height: 24px;
        background-color: #fff;
        border: 1px solid #cccccc;
        border-radius: 2px;
        color: #666666;
        padding: 0 23px;
        &:hover {
          color: #1596fd;
          border: 1px solid #1596fd;
          cursor: pointer;
        }
      }
    }
  }
}
</style>
