var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "x-fsa wrap" },
    _vm._l(_vm.urlList, function (item, index) {
      return _c(
        "div",
        { key: index, staticClass: "y-c", staticStyle: { height: "100%" } },
        [
          _c("el-image", {
            staticClass: "marB10",
            attrs: { src: item.imgUrl },
          }),
          _c("span", { staticClass: "fontS14" }, [_vm._v(_vm._s(item.name))]),
          _c("span", { staticClass: "fontS14 marB20" }, [
            _vm._v(_vm._s(item.equipment)),
          ]),
          _c(
            "el-button",
            {
              staticClass: "downloadBtn",
              attrs: {
                disabled: item.downloadUrl == undefined || !item.downloadUrl,
              },
              on: {
                click: function ($event) {
                  return _vm.downloadFile(item.downloadUrl)
                },
              },
            },
            [_vm._v(" 立即下载 ")]
          ),
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }