<template>
  <div class="wrap" id="wrap" v-loading="loading">
    <cardTitleCom cardTitle="查询" id="search-card">
      <template slot="cardContent">
        <div class="navSearch">
          <!-- 高级搜索按钮 -->
          <seniorSearch
            v-model="queryParams"
            :getList="getList"
            :isAdvancedSearch="false"
            :isBillDate="false"
            :dropDownList="dropDownList"
            :isSearchInput="true"
            isSearchInputTitle="输入方案编号、名称"
          />
        </div>
      </template>
    </cardTitleCom>
    <cardTitleCom cardTitle="POS方案信息" class="optionInfo">
      <template slot="cardContent">
        <div class="tableContent">
          <!-- 操作按钮 -->
          <operatingButton
            :getList="getList"
            :isRecycleBtn="true"
            :isDeleteBtn="false"
            :isEnableBtn="true"
            :multiple="multiple"
            @handleAdd="handleAdd"
            @handleDelete="handleDelete"
            @handleEnable="handleEnable"
            @handleRedoDelete="dialogOptions.show = true"
          />
          <el-table
            v-loading="loadingTable"
            ref="multipleTable"
            @row-click="handleRowClick"
            tooltip-effect="dark"
            :data="tableData"
            @selection-change="handleSelectionChange"
            border
            :max-height="tableHeight"
            :height="tableHeight"
          >
            <el-table-column type="selection" width="50" align="center" />
            <el-table-column
              label="序号"
              align="center"
              width="80"
              type="index"
            />
            <el-table-column
              show-overflow-tooltip
              label="图片方案编号"
              align="center"
              prop="posImageNo"
              width="180"
            >
              <template slot-scope="scope">
                <el-link
                  type="primary"
                  @click="handleDetail(scope.row)"
                  target="_blank"
                >
                  {{ scope.row.posImageNo }}
                </el-link>
              </template>
            </el-table-column>
            <el-table-column
              show-overflow-tooltip
              label="图片方案名称"
              align="center"
              prop="posImageName"
              width="160"
            />
            <el-table-column
              label="启用状态"
              align="center"
              prop="isStop"
              width="100"
            >
              <template slot-scope="scope">
                <span>{{ scope.row.isStop ? '禁用' : '启用' }}</span>
              </template>
            </el-table-column>
            <el-table-column
              label="适用门店"
              align="center"
              prop="shopRangeType"
              width="120"
            >
              <template slot-scope="scope">
                <dict-tag
                  :options="dict.type.fill_shop_range_type"
                  :value="scope.row.shopRangeType"
                />
              </template>
            </el-table-column>
            <el-table-column
              label="POS主屏"
              align="center"
              prop="posLogoUrl"
              width="80"
            >
              <template slot-scope="scope">
                <el-image
                  style="width: 30px; height: 32px"
                  :src="scope.row.posLogoUrl"
                  :preview-src-list="[scope.row.posLogoUrl]"
                />
              </template>
            </el-table-column>
            <el-table-column
              label="POS副屏"
              align="center"
              prop="posLogoUrl"
              width="80"
              ><template slot-scope="scope">
                <el-image
                  style="width: 30px; height: 32px"
                  :src="
                    scope.row.carouselUrls && scope.row.carouselUrls.length > 0
                      ? scope.row.carouselUrls[0].attachFileUrl
                      : ''
                  "
                  :preview-src-list="scope.row.carouselUrlsList"
                />
              </template>
            </el-table-column>
            <el-table-column
              label="购物车占屏幕的百分比"
              align="center"
              prop="carouselSizeType"
              width="160"
            >
              <template slot-scope="scope">
                <span>{{ scope.row.carouselSizeType==1 ? '15%' :  scope.row.carouselSizeType==2 ?'20%': scope.row.carouselSizeType==3 ?'30%':'40' }}</span>
              </template>
            </el-table-column>
            <el-table-column
              label="备注"
              align="center"
              prop="posImageRemark"
              width="155"
            />

            <el-table-column label="修改人" align="center" prop="updateBy" />
            <el-table-column
              label="修改时间"
              align="center"
              prop="updateTime"
              width="155"
            />
            <el-table-column label="创建人" align="center" prop="createBy" />
            <el-table-column
              label="创建时间"
              align="center"
              prop="createTime"
              width="155"
            />
          </el-table>
          <bottomPage
            v-model="queryParams"
            :getList="getList"
            :idsCom="ids"
            :totalCom="total"
            @selectAllCom="selectAll"
          />
        </div>
      </template>
    </cardTitleCom>
    <Dialog2 :options.sync="dialogOptions" @handleEvent="handleEvent" />
  </div>
</template>

<script>
import cardTitleCom from '@/views/components/cardTitleCom' //卡片标题
import seniorSearch from '@/views/components/seniorSearch' //高级搜索
import bottomPage from '@/views/components/bottomPage' //底部分页
import {
  posImageListAPI,
  posImageIsStopAPI,
  posImageRemoveAPI,
  restoreShopPosImage
} from '@/api/shop/setup/posImage' //  门店pos图片
import operatingButton from '@/views/components/operatingButton' //操作按钮
import Dialog2 from '@/components/Dialog'
export default {
  name: 'posImage',
  dicts: ['fill_shop_range_type'],
  components: {
    bottomPage,
    cardTitleCom,
    seniorSearch,
    operatingButton,
    Dialog2
  },
  data () {
    return {
      dialogOptions: {
        type: 'ShopInfo',
        show: false,
        title: '回收站',
        width: '75vw',
        data: {
          getListApi: posImageListAPI,
          restoreListApi: restoreShopPosImage,
          id: 'posImageId',
          search: [
            {
              type: 'filters',
              isRestore: true,
              model: '',
              filters: [
                { filter: 'query', label: '全部' },
                { filter: 'posImageNos', label: '方案编号' },
                { filter: 'posImageNames', label: '方案名称' }
              ]
            },
            {
              type: 'button',
              tip: '查询',
              btnType: 'primary',
              click: 'search',
              isRestore: true
            }
          ],
          columns: [
            {
              prop: 'posImageNo',
              label: '图片方案编号',
              minWidth: 180
            },
            {
              prop: 'posImageName',
              label: '图片方案名称',
              minWidth: 180
            },
            {
              prop: 'shopRangeTypeName',
              label: '适用门店',
              minWidth: 120
            },
            {
              prop: 'updateBy',
              label: '修改人',
              minWidth: 120
            },
            {
              prop: 'updateTime',
              label: '修改时间',
              minWidth: 155
            },
            {
              prop: 'createBy',
              label: '创建人',
              minWidth: 120
            },
            {
              prop: 'createBy',
              label: '创建时间',
              minWidth: 155
            }
          ]
        }
      },
      //搜索框搜索的条件
      dropDownList: [
        { parameter: 'query', name: '全部' },
        { parameter: 'posImageNos', name: '方案编号' },
        { parameter: 'posImageNames', name: '方案名称' }
      ],
      loading: false,
      loadingTable: false,
      multiple: true, // 非单个禁用
      billStatusIsStopNoId: [], //选中数组的禁用的状态
      billStatusIsStopId: [], //选中数组的启用的状态
      ids: [], // 选中数组
      total: 0, // 总条数
      tableData: [], //表格数据
      //查询
      queryParams: {
        pageNum: 1,
        pageSize: 15
      },
      vivwH: 0, //页面高度
      searchH: 0, //搜索框高度
      paginationH: 40
    }
  },

  async created () {
    //初始化表格数据
    await this.getList()
  },
  computed: {
    tableHeight () {
      return this.vivwH - this.paginationH - this.searchH - 140
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.vivwH = document.getElementById('wrap').clientHeight
      this.searchH = document.getElementById('search-card').clientHeight
    })
    window.addEventListener('resize', this.handleResize)
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.handleResize)
  },
  methods: {
    //还原刷新页面
    handleEvent () {
      this.loading = true
      this.getList()
      this.loading = false
    },
    handleResize () {
      this.vivwH = document.getElementById('wrap').clientHeight
      this.searchH = document.getElementById('search-card').clientHeight
    },
    //删除
    async handleDelete () {
      try {
        await this.$confirm(`确定删除所选订单, 是否继续?`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'success'
        })
        this.loading = true
        if (this.billStatusIsStopNoId.length == 0) {
          this.$message.error('只能删除：已禁用的状态！')
          this.loading = false
          return
        }
        //发送删除api
        await posImageRemoveAPI(this.billStatusIsStopNoId)
        // 弹出提示
        this.$message.success('删除成功')
        //刷新列表
        await this.getList()
      } catch {
        this.loading = false
      }
      this.loading = false
    },
    //启用停用
    async handleEnable (value) {
      this.loading = true
      if (!value) {
        if (this.billStatusIsStopNoId.length === 0) {
          this.$message.error('只能启用：已禁用状态！')
          this.loading = false
          return
        }
        const obj = {
          posImageIds: this.billStatusIsStopNoId,
          isStop: value
        }
        await posImageIsStopAPI(obj)
        this.$message({
          message: `启用成功`,
          type: 'success'
        })
      } else {
        if (this.billStatusIsStopId.length === 0) {
          this.$message.error('只能禁用：已启用的状态')
          this.loading = false
          return
        }
        const obj = {
          posImageIds: this.billStatusIsStopId,
          isStop: value
        }
        await posImageIsStopAPI(obj)
        this.$message({
          message: `禁用成功`,
          type: 'success'
        })
      }
      await this.getList()
      this.loading = false
    },
    // 多选框选中数据
    handleSelectionChange (selection) {
      this.ids = selection.map(item => item.posImageId) //单据id
      //筛选出来禁用的单据状态
      this.billStatusIsStopNoId = selection
        .map(item => {
          if (item.isStop) {
            return item.posImageId
          }
        })
        .filter(ite => typeof ite !== 'undefined')
      //筛选出来启用的单据状态
      this.billStatusIsStopId = selection
        .map(item => {
          if (!item.isStop) {
            return item.posImageId
          }
        })
        .filter(ite => typeof ite !== 'undefined')
      this.multiple = !selection.length
    },

    /** 查询表格列表 */
    async getList () {
      this.loadingTable = true
      const { rows, total } = await posImageListAPI(this.queryParams)
      this.tableData = rows
      this.tableData.forEach(item => {
        if (item.carouselUrls && item.carouselUrls.length > 0) {
          let arrUrl = []
          item.carouselUrls.forEach(v => {
            arrUrl.push(v.attachFileUrl)
          })
          this.$set(item, 'carouselUrlsList', arrUrl)
        }
      })
      this.total = total
      this.loadingTable = false
    },

    //新增单据事件
    handleAdd () {
      this.$router.push({
        name: 'posImageDetail'
      })
    },
    //跳转详情
    handleDetail (row) {
      const posImageId = row.posImageId
      this.$router.push({
        name: 'posImageDetail',
        query: {
          posImageId: posImageId,
          type: 'Update'
        }
      })
    },
    //点击行选中
    handleRowClick (row) {
      // 获取表格对象
      // 切换行选中状态
      this.$refs.multipleTable.toggleRowSelection(row)
    },
    //点击全选所有数据
    async selectAll (value) {
      if (value) {
        this.loadingTable = true
        const res = await posImageListAPI({
          ...this.queryParams,
          pageNum: 1,
          pageSize: this.total
        })
        //重新渲染表格
        this.tableData = res.rows
        //表格多选
        this.tableData.map(row => {
          this.$nextTick(() => {
            this.$refs.multipleTable.toggleRowSelection(row, value)
          })
        })
        this.loadingTable = false
      } else {
        //取消全选
        this.$nextTick(() => {
          this.$refs.multipleTable.clearSelection()
        })
        this.getList()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
//外面盒子
.wrap {
  padding: 10px 10px 0 10px;
  background-color: #eaeaea;
  height: calc(100vh - 84px);
  // 搜索
  .navSearch {
    padding: 10px 10px 10px 20px;
  }
  .optionInfo {
    height: calc(100% - 94px);
    //源单信息表格
    .tableContent {
      margin: 6px 10px 10px 10px;
    }
  }
  //阿里图标
  ::v-deep .iconfont {
    font-size: 12px;
  }
}
</style>
